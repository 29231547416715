import React, { useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalFooter,
  MDBModalHeader,
  MDBBtn,
  MDBModalBody,
} from "mdb-react-ui-kit";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { CircularProgress } from "@mui/material";
import { Snackbar, Alert } from "@mui/material";
import { MDBIcon } from "mdb-react-ui-kit";
import { app } from "../../FirebaseConfig";
import axios from "axios";

export default function IDDocModal(props) {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const [toggleShow, setToggleShow] = useState(true);
  const [doc, setDoc] = useState(props.object.id_doc);
  console.log(doc);
  const [file, setFile] = useState();
  const [upload, setload] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const handleImageSelected = (e) => {
    setFile(e);
    setDoc([...doc, URL.createObjectURL(e)]);
  };

  const parentNoHandler = () => {
    props.onClose();
  };

  const UploadFile = () => {
    setUploaded(true);
    if (doc.length === 0) {
      setload(true);
      setUploaded(false);
    }else{
      let storage = getStorage(app);
      let docMetaData = { contentType: file.type };
      const IdDocRef = ref(storage, "Guests/" + file.name);
      const uploadIdDocTask = uploadBytesResumable(
        IdDocRef,
        file,
        docMetaData
      );
      uploadIdDocTask.on(
        "state_changed",
        (snapshot) => {
          console.log("Upload");
        },
        (error) => {
          console.log(error);
        },
        () => {
            console.log("Uploaded")
          getDownloadURL(uploadIdDocTask.snapshot.ref).then(
            (downloadURL) => {
              axios
                .post(
                  BASE_URL + "/guests/updateDocID",
                  {
                    guest_id: props.object.id,
                    doc_link: downloadURL,
                  },
                  {
                    headers: {
                      FirebaseToken: sessionStorage.getItem("Auth_Token"),
                    },
                  }
                )
                .then((res) => {
                  props.onClose();
                  setToggleShow(!toggleShow);
                  alert("DOC ID Updated");
                })
                .catch((e) => {
                  props.onClose();
                  setToggleShow(!toggleShow);
                });
            }
          );
        }
      );
    }
  };
  return (
    <MDBModal show={toggleShow} setShow={setToggleShow} tabIndex="-1" 
    onHide={() => {
      parentNoHandler();
    }}>
      <MDBModalDialog size="xl">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Upload / Update ID Document</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => {
                props.onClose();
                setToggleShow(!toggleShow);
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="row g-0">
              <div className="col col-6 ">
              {doc.length===0?
                <div className="doc_not_found mx-4">
              <MDBIcon className='ms-5 my-2' far icon="file-alt" size='6x'/>
                <h6>No document found</h6>
              </div>
              :
              <div className="id_doc_image">
                  <img
                    src={doc[0]}
                    alt="No ID Document"
                  />
                </div>
              }
              
                
              </div>
              <div className="col col-6">
                <div className="image_uploader_div">
                  <div className="upload-div">
                    <input
                      type="file"
                      className="form-control uploadFileSize select-file-input"
                      onChange={(e) => {
                        handleImageSelected(e.target.files[0]);
                      }}
                      accept="image/png, image/gif, image/jpeg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            {uploaded ? (
              <CircularProgress />
            ) : (
              <button
                className="application_base_btn table_btn_padding"
                onClick={UploadFile}
              >
                Upload Document
              </button>
            )}
          </MDBModalFooter>
        </MDBModalContent>
        <Snackbar
          open={upload}
          autoHideDuration={4000}
          onClose={() => {
            setload(false);
          }}
        >
          <Alert
            onClose={() => {
              setload(false);
            }}
            severity="warning"
            sx={{ width: "100%" }}
          >
            No Document Selected
          </Alert>
        </Snackbar>
      </MDBModalDialog>
    </MDBModal>
  )
}