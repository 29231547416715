import React from "react";
import { useState } from "react";
import { app } from "../../FirebaseConfig";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import axios from "axios";
import { CircularProgress } from "@mui/material";

import "./GuestDesc.css";
import "./SingleUpload.css";
import "../LOPages/AddLos.css";
import { useNavigate } from "react-router-dom";

const SingleUpload = (props) => {
  const [docUpload, setDocUpload] = useState(false);
  const [imgUpload, setImgUpload] = useState(false);
  const [addLoader, setAddLoader] = useState(false);

  const [docUploadComplete, setDocUploadComplete] = useState(false);
  const [imgUploadComplete, setImgUploadComplete] = useState(false);

  const BASE_URL = process.env["REACT_APP_BASE_URL"];
  const navigate = useNavigate();
  const [contactErr, setContactErr] = useState('');
  const [contact1, setContact1] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const [guest, setGuest] = useState({
    name: "",
    contact2: "",
    check_in_exp: "",
    check_out_exp: "",
    constituency: "",
    // constituency2: "",
    // constituency3: "",
    designation: "",
    category: "",
    image: "",
    party: "",
    eventId: "",
  });

  const [idDocs, setIdDocs] = useState([]);
  const [idDocUrls, setIdDocUrls] = useState([]);

  // Guest form onChange functions
  const onnameChange = (e) => {
    setGuest((previousData) => {
      return { ...previousData, name: e.target.value };
    });
  };

  const oncheckInChange = (e) => {
    setGuest((previousData) => {
      return { ...previousData, check_in_exp: e.target.value };
    });
  };

  const oncheckOutChange = (e) => {
    setGuest((previousData) => {
      return { ...previousData, check_out_exp: e.target.value };
    });
  };

  const onidentification_docChange = (e) => {
    setIdDocs(e.target.files);
  };

  const onEventIdChange = (e) => {
    setGuest((previousData) => {
      return { ...previousData, eventId: e.target.value };
    });
  };

  const onidentification_docChange_back = (e) => {
    setGuest((previousData) => {
      if (e.target.files.length === 0) {
        return previousData;
      }
      return {
        ...previousData,
        identification_doc_backside: e.target.files[0],
      };
    });
  };

  const onImageChange = (e) => {
    setGuest((previousData) => {
      return { ...previousData, image: e.target.files[0] };
    });
  };

  const onconstituencyChange = (e) => {
    setGuest((previousData) => {
      return { ...previousData, constituency: e.target.value };
    });
  };

  const ondesignationChange = (e) => {
    setGuest((previousData) => {
      return { ...previousData, designation: e.target.value };
    });
  };

  const oncontact2Change = (e) => {
    setGuest((previousData) => {
      return { ...previousData, contact2: e.target.value };
    });
  };

  const oncategoryChange = (e) => {
    setGuest((previousData) => {
      return { ...previousData, category: e.target.value };
    });
  };

  const onPartyChange = (e) => {
    setGuest((previousData) => {
      return { ...previousData, party: e.target.value };
    });
  };

  const uploadIdentificationDoc = () => {
    if (idDocs.length !== 0) {
      setDocUpload(true);
      let storage = getStorage(app);
      const identificationDocMetaData = {
        contentType: idDocs[0].type,
      };

      let filesList = idDocs;
      let urls = [];

      for (let i = 0; i < filesList.length; i++) {
        // Guest Identification Document URL generation and upload to firebase storage
        const guestIdDocRef = ref(storage, "Guests/" + filesList[i].name);
        const uploadIdDocTask = uploadBytesResumable(
          guestIdDocRef,
          filesList[i],
          identificationDocMetaData,
        );
        uploadIdDocTask.on(
          "state_changed",
          (snapshot) => {
            console.log("Uploading Identification State...");
          },
          (error) => {
            console.log(error);
          },
          () => {
            getDownloadURL(uploadIdDocTask.snapshot.ref).then((downloadURL) => {
              urls[i] = downloadURL;
              setDocUpload(false);
              console.log("File available at", downloadURL);
            });
          }
        );
      }
      setIdDocUrls(urls);
      setDocUpload(true);
    } else {
      alert("Please choose a file before uploading");
    }
  };

  const uploadImage = () => {
    if (guest.image !== "") {
      setImgUpload(true);
      let storage = getStorage(app);
      // Guest Image URL generation and upload to firebase storage
      const imageMetaData = {
        contentType: guest.image.type,
      };
      const guestImageRef = ref(storage, "Guests/" + guest.image.name);
      const uploadImageTask = uploadBytesResumable(
        guestImageRef,
        guest.image,
        imageMetaData
      );
      uploadImageTask.on(
        "state_changed",
        (snapshot) => {
          console.log("Uploading Image State...");
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadImageTask.snapshot.ref).then((downloadURL) => {
            setGuest((prev) => {
              return { ...prev, image: downloadURL };
            });
            console.log(guest);
            setImgUpload(false);
            console.log("File available at", downloadURL);
          });
        }
      );

      setImgUpload(true);
    } else {
      alert("Please choose a file before uploading");
    }
  };
  const handleContactBlur = () => {
    if (contact1.length !== 10) {
      setContactErr('Contact length should be equal to 10.');
    } else {
      setContactErr('');
    }
  };

  const handleEmailBlur = () => {
    if (guest.contact2.includes("@") === false) {
      setEmailErr("Invalid email. It should contain an '@'");
    } else {
      setEmailErr('');
    }
  }
  const onSubmit = (e) => {
    e.preventDefault();
    setAddLoader(true);
    if (contact1.length !== 10) {
      alert("Please enter a 10 digit contact number");
      setAddLoader(false);
    } else if (guest.contact2.includes("@") === false) {
      alert("Invalid email. It should contain an '@'");
      setAddLoader(false);
    }
    // TODO: Not confirmed
    // else if(guest.constituency.trim().split(",").length !== 3) {
    //   alert("Invalid constituency format");
    //   setAddLoader(false);
    // }
    axios
      .post(
        `${BASE_URL}/guests/addGuest`,
        { ...guest, contact1: "+91"+contact1, identification_doc: idDocUrls },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((res) => {
        setAddLoader(false);
        props.parentHandler(false);
        console.log(res.data);
        alert("Guest Added Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let userCheck;
  let check = false;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    check =
    ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
    ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true)
      ? true
      : false;
  } catch (error) {
    navigate("/");
  }
  

  return (
    <div>
      <div className="my-4">
        <form onSubmit={onSubmit}>
          <div className="form-floating my-4">
            <input
              type="text"
              placeholder="Guest ID"
              className="form-control"
              label="Name"
              id="formControlLg"
              value={guest.name}
              onChange={onnameChange}
              required
            />
            <label className="form-label ">
              <div className="d-flex flex-row ">
                <p className="">Name</p>
              </div>
            </label>
          </div>
          <div className="my-4 d-flex">
            <input
              className="country-code-readOnly"
              placeholder="+91"
              readOnly
            />
            <input
              wrapperClass="mb-4"
              style={{width: '100%'}}
              className="contactInput"
              label="Contact"
              type="number"
              placeholder="Contact"
              onBlur={handleContactBlur}
              value={contact1}
              onChange={(e) => setContact1(e.target.value)}
              required
            />{contactErr!=='' ?
            <label style={{color:"#FF3333", fontSize:14}}>{contactErr}</label>
            : <></>
            }
          </div>
          
          <div className="form-floating my-4">
            <input
              type="text"
              placeholder="contact"
              className="form-control"
              label="Contact"
              id="formControlLg"
              onBlur={handleEmailBlur}
              value={guest.contact2}
              onChange={oncontact2Change}
              required
            />
            <label className="form-label ">
              <div className="d-flex flex-row ">
                <p className="">Email</p>
              </div>
            </label>
            {emailErr!=='' ?
            <div>
            <label style={{color:"#FF3333", fontSize:14}}>{emailErr}</label></div>
            : <></>
            }
          </div>
          <div className="form-floating my-4">
            <input
              type="datetime-local"
              placeholder="Expected Check In"
              className="form-control"
              label="Contact"
              id="formControlLg"
              value={guest.check_in_exp}
              onChange={oncheckInChange}
              required
            />
            <label className="form-label ">
              <div className="d-flex flex-row ">
                <p className="">Expected Check In</p>
              </div>
            </label>
          </div>
          <div className="form-floating my-4">
            <input
              type="datetime-local"
              placeholder="Expected Check Out"
              className="form-control"
              label="Contact"
              id="formControlLg"
              value={guest.check_out_exp}
              onChange={oncheckOutChange}
              required
            />
            <label className="form-label ">
              <div className="d-flex flex-row ">
                <p className="">Expected Check Out</p>
              </div>
            </label>
          </div>
          <div className="">
            <p className=" " style={{ paddingLeft: "2px" }}>
              Identification Document
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "2rem",
              }}
            >
              <input
                style={{ width: "95%" }}
                type="file"
                multiple
                className=" form-control p-2"
                label="Identification Doc"
                id="formControlLg"
                onChange={onidentification_docChange}
                required
              />
              {docUpload ? (
                <div className="d-flex justify-content-center">
                  {" "}
                  <CircularProgress />{" "}
                </div>
              ) : (
                <>
                  {docUploadComplete ? (
                    <i className="far fa-circle-check"></i>
                  ) : (
                    <button
                      onClick={uploadIdentificationDoc}
                      className="uploadBtn"
                    >
                      <i style={{ width: "27px" }} className="fa fa-upload" aria-hidden="true"></i>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          {/* <div className=" ">
          <p className=" " style={{ paddingLeft: "2px" }}>
            Identification Document Backside
          </p>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "2rem" }}>
          <input
            style={{ width: "50%" }}
            type="file"
            className=" form-control p-2"
            label="Identification Doc"
            id="formControlLg"
            onChange={onidentification_docChange_back}
            required
          />
          <button onClick={uploadIdentificationDocBack} style={{ padding: "5px" }} className="btn btn-success">Upload File</button>
          </div>
        </div> */}
          <div className=" ">
            <p className=" " style={{ paddingLeft: "2px" }}>
              Image
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "2rem",
              }}
            >
              <input
                style={{ width: "95%" }}
                type="file"
                className=" form-control p-2"
                label="Identification Doc"
                id="formControlLg"
                onChange={onImageChange}
                required
              />
              {imgUpload ? (
                <div className="d-flex justify-content-center">
                  {" "}
                  <CircularProgress />{" "}
                </div>
              ) : (
                <>
                  {imgUploadComplete ? (
                    <i className="far fa-circle-check"></i>
                  ) : (
                    <button onClick={uploadImage} className="uploadBtn">
                      <i style={{ width: "27px" }} className="fa fa-upload" aria-hidden="true"></i>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="form-floating my-4">
            <input
              type="text"
              placeholder="Constituency"
              className="form-control"
              label="Constituency"
              id="formControlLg"
              value={guest.constituency}
              onChange={onconstituencyChange}
              required
            />
            <label className="form-label ">
              <div className="d-flex flex-row ">
                <p className="">Constituency,District,State</p>
              </div>
            </label>
          </div>
          {/* <div className="form-floating my-4">
          <input
            type="text"
            placeholder="District"
            className="form-control"
            label="Constituency"
            id="formControlLg"
            value={guest.constituency2}
            onChange={onconstituency2Change}
            required
          />
          <label className="form-label ">
            <div className="d-flex flex-row ">
              <p className="">District</p>
            </div>
          </label>
        </div>
        <div className="form-floating my-4">
          <input
            type="text"
            placeholder="State"
            className="form-control"
            label="Constituency"
            id="formControlLg"
            value={guest.constituency3}
            onChange={onconstituency3Change}
            required
          />
          <label className="form-label ">
            <div className="d-flex flex-row ">
              <p className="">State</p>
            </div>
          </label>
        </div> */}
          <div className="form-floating my-4">
            <input
              type="text"
              placeholder="Designation"
              className="form-control"
              label="Designation"
              id="formControlLg"
              value={guest.designation}
              onChange={ondesignationChange}
              required
            />
            <label className="form-label ">
              <div className="d-flex flex-row ">
                <p className="">Designation</p>
              </div>
            </label>
          </div>
          <div className="form-floating my-4">
            <input
              type="text"
              placeholder="Category"
              className="form-control"
              label="Category"
              id="formControlLg"
              value={guest.category}
              onChange={oncategoryChange}
              required
            />
            <label className="form-label ">
              <div className="d-flex flex-row ">
                <p className="">Category</p>
              </div>
            </label>
          </div>
          <div className="form-floating my-4">
            <input
              type="text"
              placeholder="Party"
              className="form-control"
              label="Party"
              id="formControlLg"
              value={guest.party}
              onChange={onPartyChange}
              required
            />
            <label className="form-label ">
              <div className="d-flex flex-row ">
                <p className="">Party</p>
              </div>
            </label>
          </div>
          <div className="form-floating my-4">
            {addLoader ? (
              <div className="d-flex justify-content-center">
                {" "}
                <CircularProgress />{" "}
              </div>
            ) : (
              <input
                type="submit"
                value="Add Guest"
                disabled={((guest.image.length > 0) && (idDocUrls.length > 0) && (check === true))? false : true}
                id="formControlLg"
                style={{
                  float: "right",
                }}
                className=" application_base_btn max_media_width"
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SingleUpload;
