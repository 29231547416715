import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import { Context } from "../../MainPage";

import "./Media.css";
import axios from "axios";
import CustomToolBar from "../../Components/MuiToolBar/CustomToolBar";
import { mediaGuestsStyle } from "../../Static/Styles";

const MediaForGuests = ({ sendToValue, url, mediaFile }) => {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const { guestapi } = useContext(Context);
  const [guests, setGuests] = useState(guestapi);

  const [guestsMediaArray, setGuestsMediaArray] = useState([]);

  const addMediaForGuests = () => {
    let allSelected
    if(guestsMediaArray.length === guests.length){
      allSelected = true
    }
    axios
      .post(
        `${BASE_URL}/media/uploadMedia`,
        {
          uuid: guestsMediaArray,
          url: url,
          state: [],
          sessionId: "",
          subjectID: "",
          type: "multiple",
          file_type: mediaFile.type !== "video/mp4" && mediaFile.type !== "application/pdf" ? "file" : mediaFile.type,
          allSelected: allSelected
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((response) => {
        alert("Media Uploaded Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setGuests(guestapi);
  }, [guestapi]);

  return (
    <div style={{ marginTop: "5rem"}}>
      <Box sx={{ height: 400, width: 600 }}>
        <DataGrid
          checkboxSelection={true}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setGuestsMediaArray(newRowSelectionModel);
          }}
          rowSelection={guestsMediaArray}
          columns={[
            {
              ...GRID_CHECKBOX_SELECTION_COL_DEF,
              width: 55,
              headerClassName: "prime_table_header_tab",
              headerAlign: "center",
            },
            {
              field: "guest_name",
              headerClassName: "super-app-theme--header",
              headerName: "Guest Name",
              headerClassName: "super-app-theme--header",
              headerAlign: "center",
              width: 150,
              flex: 1,
            },
            {
              field: "guest_party",
              headerClassName: "super-app-theme--header",
              headerName: "Pol. Party",
              headerAlign: "center",
              width: 150,
              flex: 1,
            },
            {
              field: "guest_id",
              headerClassName: "super-app-theme--header",
              headerName: "Guest ID",
              headerAlign: "center",
              width: 200,
              flex: 1.5,
            },
          ]}
          rows={guests?.map((guest) => {
            return {
              guest_id: guest.id,
              guest_name: guest.name,
              guest_party: guest.party,
            };
          })}
          getRowId={(row) => row.guest_id}
          slots={{ toolbar: CustomToolBar }}
          getRowClassName={(params) => {
            return params.indexRelativeToCurrentPage % 2 === 0
              ? "mui_grid_table_even_row"
              : "mui_grid_table_odd_row";
          }}
          sx={mediaGuestsStyle}
        />
      </Box>
      <div>
        {guestsMediaArray?.map((guest) => {
          return <p>{guest.name}</p>;
        })}
      </div>
      <div style={{ float: "left", marginTop: "10px" }}>
        <p>Selected {guestsMediaArray.length} guests</p>
      </div>
      <button
        style={{
          paddingLeft: "25px",
          paddingRight: "25px",
          marginTop: "50px",
          float: "right",
        }}
        className="btn btn-dark"
        onClick={addMediaForGuests}
      >
        Export
      </button>
    </div>
  );
};

export default MediaForGuests;
