import React, {useState} from 'react'
import './contact.css'
import axios from 'axios';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
export default function AddContact(props) {

  const BASE_URL = process.env["REACT_APP_BASE_URL"]

  const [modalStatus, setStatus] = useState(true);

  const parentNoHandler = () => {
    props.parentHandler(false)
    setStatus(false)
  }
    const [number, setNumber] = useState("+91");
    const [name, setName] = useState("");
    const [section, setSection] = useState("");

    const addContact = (e) => {
        
        
        e.preventDefault();
        if (number.length !== 13) {
            alert('Contact number must be 10 digits.');
            return;
          }
          if (!name.trim() || !section.trim()) {
            alert('Name and section fields cannot be empty.');
            return;
          }
        axios.post(BASE_URL + "/misc/addContact",
        {
            object : {
                contact: String(number),
                name: name,
                section: section
            }
        }, {
            headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
        }
        )
        .then((response) => {
            console.log(response.data);
            alert("Contact Added Successfully");
        })
        .catch((err) => {
            console.log(err);
        })
       
        
    }
  return (
    <div>
    <MDBModal show={modalStatus} tabIndex='-1' onHide={() => { parentNoHandler() }}>
    <MDBModalDialog>
    <MDBModalContent>
    <MDBModalBody>
      <div className='d-flex justify-content-end'>
        <button className='justify-self-end border-0 bg-transparent' onClick={props.parentHandler}> <i className="fa-solid fa-close fs-5"></i></button>
      </div>
    <form className="add-contact-form" onSubmit={addContact} style={{margin: 0}}>
            <label>Contact Number</label>
            <input type="text" placeholder="Contact"  value={number} onChange={(e) => setNumber(e.target.value)} />
            <label>Name</label>
            <input type="text" placeholder="Name"   value={name} onChange={(e) => setName(e.target.value)}/>
            <label>Section</label>
            <input type="text" placeholder="Section"  value={section} onChange={(e) => setSection(e.target.value)} />
            <input id="add-subject-button" type="submit" value="Add Contact" />
            
        </form>
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
      </MDBModal>
      

     
    </div>
  )
}
