/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRowModes,
} from "@mui/x-data-grid";
import CustomToolBar from "../../Components/MuiToolBar/CustomToolBar";
import VehicleAssignGuest from "../../Components/UpdatesComponents/VehicleAssignGuest";
import { Context } from "../../MainPage";
import axios from "axios";
import Editicon from "../../Assets/edit.svg";
import Doneicon from "../../Assets/done.svg";
import ConfirmationModal from "../ConfirmationModal";
import { tableStyle } from "../../Static/Styles";

export default function VehicleMui(props) {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const { guestapi, vehicleapi, childCaller } = useContext(Context);
  const [guest_Name, setGuestName] = useState("");
  const [vehicleList, setVehicleList] = useState(props.vehicles);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    props.setVehicle(props.vehicles);
  }, [vehicleapi])

  const [guestId, setGuestId] = useState("");
  const [ids, setIDs] = useState({
    display: false,
    data: { vehicleID: null, guestID: null },
  });

  // Delete Button
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState({
    data: { id: null, message: null },
  });

  const handleDeleteRowModal = (editValue) => {
    setDeleteData({
      data: {
        id: editValue,
        message: "Are you sure you want to delete this item?",
      },
    });
    setDeleteModal(true);
  };

  const handleDeleteRowCloseModal = () => {
    setDeleteModal(false);
  };

  const DeleteRowApi = (id) => {
    const guest_ = vehicleapi.find((vehicle) => vehicle.id === id).guest;
    console.log(id, guest_);
    axios
      .delete(`${BASE_URL}/vehicles/deleteVehicle/${id}/${guest_}`, {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
      })
      .then((res) => {
        console.log(res.data);
        alert("Vehicle Deleted Successfully");
        childCaller("Vehicles");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setGuestId("");
  };
  const handleShowModal = (bool, itemId, itemName) => {
    setIDs({
      display: false,
      data: { vehicleID: { id: itemId, name: itemName }, guestId: null },
    });
    setShowModal(true);
  };
  const guestName = (searchID) => {
    guestapi?.map((guest) => {
      if (guest.id === searchID) {
        setGuestName(guest.name);
      }
    });
  };

  const userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
  const checkDelete =
    ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
    ("VehicleAdmin" in userCheck && userCheck.VehicleAdmin === true)
      ? true
      : false;
  const checkGuestVehicle =
    ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
    ("VehicleAdmin" in userCheck && userCheck.VehicleAdmin === true) ||
    ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true)
      ? true
      : false;
  // console.log(check);

  //TABLE CRUD STATES AND FUNCTIONS
  const [editedRow, setEditRow] = useState(false);
  const [editModeModel, setEditMode] = useState({});

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEdit = (ID) => {
    setEditMode({ ...editModeModel, [ID]: { mode: GridRowModes.Edit } });
  };
  const handleDone = (ID) => {
    setEditMode({ ...editModeModel, [ID]: { mode: GridRowModes.View } });
  };

  const handleCancelEdit = (ID) => {
    setEditMode({
      ...editModeModel,
      [ID]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    setEditRow({});
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setEditMode(newRowModesModel);
  };

  const processRowUpdate = (newRow) => {
    // const updatedRow = { ...newRow, isNew: false };
    const updatedRow = {
      id: newRow.id,
      driver_name: newRow.driver_name,
        guest: newRow.guest,
        name: newRow.name,
        no: newRow.no,
        driver_contacts: newRow.driver_contacts,
        description: newRow.description,
    }
    axios
      .post(`${BASE_URL}/vehicles/updateSoloVehicle`, updatedRow, {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
      })
      .then((res) => {
        alert("Vehicle Updated Successfully");
        childCaller("Vehicles");
      })
      .catch((err) => {
        console.log(err);
      });
    setEditRow(newRow);
    return updatedRow;
  };

  const guestNameString = (guestID) =>{
    var guest = guestapi.find((guest)=>{if(guest.id === guestID){return guest}})
    if (typeof guest === "undefined" || guest === null){
      return " ";
    }
    return guest.name;
  }

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 55,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
    },
    {
      field: "button",
      headerName: "",
      sortable: false,
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      hideable: false,
      filterable: false,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      disableExport: true,
      renderCell: (params) => {
        const editCondition =
          editModeModel[params.id]?.mode === GridRowModes.Edit;
        return (
          <>
            {!editCondition ? (
              <button
                style={{
                  border: "none",
                  padding: "0",
                  backgroundColor: "transparent",
                  margin: "10px",
                }}
                disabled={checkDelete ? false : true}
              >
                <i
                  class="trashIcon fa-sharp fa-solid fa-trash"
                  onClick={() => handleDeleteRowModal(params.row.id)}
                ></i>
              </button>
            ) : (
              <button
                style={{
                  border: "none",
                  padding: "0",
                  backgroundColor: "transparent",
                  margin: "10px",
                }}
                disabled={checkDelete ? false : true}
              >
                <i
                  className="trashIcon fas fa-times fa-lg"
                  onClick={() => {
                    handleCancelEdit(params.id);
                  }}
                ></i>
              </button>
            )}
            {!editCondition ? (
              <img
                src={Editicon}
                alt=""
                onClick={() => {
                  if (checkDelete) handleEdit(params.id);
                }}
              />
            ) : (
              <img
                src={Doneicon}
                alt=""
                onClick={() => {
                  handleDone(params.id);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      field: "no",
      headerAlign: "center",
      headerName: "Vehicle No.",
      headerClassName: "prime_table_header_tab",
      minWidth: 150,
      flex: 1,
      editable: true,
    },
    {
      field: "name",
      headerName: "Vehicle Name",
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      width: "150",
      editable: true,
    },
    {
      field: "driver_name",
      headerName: "Driver Name",
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      minWidth: 150,
      flex: 1,
      editable: true,
    },
    {
      field: "driver_contacts",
      headerName: "Driver Contact",
      minWidth: 150,
      flex: 1,
      editable: true,
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      renderCell: (params) => {
        const item = params.row;
        return <>{item.driver_contacts[0]}</>;
      },
    },
    {
      field: "guest",
      headerName: "Guest Name",
      minWidth: 150,
      flex: 1,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      valueGetter: (params) =>{
        if(params.row.guest === null){return null }
      else{return guestNameString(params.row.guest)}},
      renderCell: (params) => {
        const item = params.row;
        return (
          <>
            {item.guest ? (
              <React.Fragment>
                <div onClick={handleShowModal}>
                  {guestapi.map((guest) => {
                    if (guest.id === item.guest) {
                      return guest.name;
                    }
                  })}
                </div>
              </React.Fragment>
            ) : (
              <button
                onClick={() => {
                  handleShowModal(true, item.id, item.name);
                }}
                className="application_base_btn table_btn_padding"
                disabled={checkGuestVehicle ? false : true}
              >
                Add Guest
              </button>
            )}
          </>
        );
      },
    },
    {
      field: "description",
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      headerName: "Type",
      minWidth: 150,
      flex: 1,
      editable: true,
    },
    {
      field: "vendor",
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      headerName: "Vendor",
      minWidth: 150,
      flex: 1,
      editable: true,
    },
  ];

  return (
    <>
      <Box sx={{ height: 520, width: "79.8vw", margin: "auto", marginTop :"0px" }}>
        <DataGrid
          rows={props.vehicles.map((vehicle) => {
            return {
              id: vehicle.id,
              edit: vehicle,
              no: vehicle.no,
              name: vehicle.name,
              driver_name: vehicle.driver_name,
              driver_contacts: vehicle.driver_contacts,
              guest: vehicle.guest,
              description: vehicle.description,
              vendor: vehicle.vendor,
              // delete: vehicle.id,
            };
          })}
          sx={tableStyle}
          editMode="row"
          onRowModesModelChange={handleRowModesModelChange}
          rowModesModel={editModeModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          getRowClassName={(params) => {
            return params.indexRelativeToCurrentPage % 2 === 0
              ? "mui_grid_table_even_row"
              : "mui_grid_table_odd_row";
          }}
          checkboxSelection
          columns={columns}
          slots={{
            toolbar: CustomToolBar,
          }}
        />

        {showModal && (
          <VehicleAssignGuest
            parentHandler={handleCloseModal}
            changesRet={() => {
              console.log("");
            }}
            setIDs={ids.data}
          />
        )}
        {deleteModal && (
          <ConfirmationModal
            parentHandler={handleDeleteRowCloseModal}
            data={deleteData}
            onConfirm={DeleteRowApi}
            type="Vehicle"
          />
        )}
      </Box>
    </>
  );
}
