import React from 'react';
import { MDBSpinner } from 'mdb-react-ui-kit';

const ScreenLoader = () => {
  return (
    <div className='text-primary'>
        <MDBSpinner grow>
        <span className='visually-hidden'>Loading...</span>
        </MDBSpinner>
    </div>
  );
}

export default ScreenLoader