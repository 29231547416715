import 'bootstrap/dist/css/bootstrap.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "bootstrap";
import './App.css';

import { BrowserRouter} from "react-router-dom";
import MainPage from './MainPage';
// import HotelsDesc from './Pages/HotelsDesc';

function App() {
  return (
    <BrowserRouter>
      <MainPage />
    </BrowserRouter>
  );
}

export default App;
