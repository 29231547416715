import React, { useState } from 'react';
import { Popover } from '@mui/material';
import axios from 'axios';

const MediaForBroadcastMessage = ({ url, mediaFile }) => {

  const BASE_URL = process.env["REACT_APP_BASE_URL"]

  const [file, setFile] = useState("");

  const addMediaAsBroadcast = () => {
    if(mediaFile !== "") {
      setFile("file");
    }

    axios.post(`${BASE_URL}/media/uploadMedia`, {
      uuid: [],
      url: url,
      state: [],
      sessionId: "",
      subjectID: "",
      type: "broadcast",
      file_type: mediaFile.type !== "video/mp4" ? "file" : mediaFile.type,
    }, {
      headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
    })
    .then((response) => {
      console.log(response.data);
      alert("Media Uploaded Successfully");
    })
    .catch((err) => {
      console.log(err);
    })
  }

    return (
    <div style={{ position: "absolute", top: "50%", left: "65%", border: "1px solid gray", borderRadius: "5px", boxShadow: "1px 1px 1px 1px gray", paddingLeft: "30px", paddingRight: "30px", paddingTop: "20px", paddingBottom: "20px" }}>
        <p>Are you sure you want to send this media to all?</p>
        <button style={{ paddingLeft: "25px", paddingRight: "25px", marginTop: "20px", float: "right"}} className="btn btn-dark" onClick={addMediaAsBroadcast}>SEND</button>
    </div>
  )
}

export default MediaForBroadcastMessage
