import React, { useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput
} from 'mdb-react-ui-kit';

import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// import { useState } from 'react';
import axios from 'axios';
import { maxWidth } from '@mui/system';
import SingleUpload from './SingleUpload';
import BulkUpload from '../BulkUpload';

const AddGuests = () => {
  const [centredModal, setCentredModal] = useState(true);
  const [InputErr, setInputErr] = useState('');
  const [singleUpload,setSingleUpload] = useState(true)

  // Guest States
  const [guest, setGuest] = useState({
    name: "",
    contact: [],
    identification_doc: null,
    lo_id: "",
    constituency: [],
    destination: "",
    category: "",
    stay_info: "",
    vehicle: ""
  })

  const navigate = useNavigate()

  const toggleShow = () => {
    setCentredModal(false);
  }

  const [isShown, setIsShown] = useState(false);

  const [isShown1, setIsShown1] = useState(false);
  const handleClick1 = event => {

    setIsShown1(current => !current);

  }

  useEffect(() => {
    if (!centredModal) {
      navigate(-1)
    }
  })

  const onAdd = (e) => {
    e.preventDefault();
  }


  return (
    <>
      <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered size="xl" >
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle> Add Guests</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody style={{ height: "70vh", overflow: 'scroll'}} >
              {/* <form onSubmit={onAdd}> */}
                  <div className='d-flex'>
                    <div className={`w-50 text-center p-2`} style={{backgroundColor: `${singleUpload ? '#3555A1' : ''}`}}>
                      <button className='text-center border-0 bg-transparent' onClick={()=>setSingleUpload(true)}>
                        <h4 className="m-0" style={{color: `${singleUpload ? 'white' : ''}`}}>Single Upload</h4>
                      </button>
                    </div>
                    <div className='w-50 text-center p-2' style={{backgroundColor: `${!singleUpload ? '#3555A1' : ''}`}}>
                      <button className='text-center border-0 bg-transparent' onClick={()=>setSingleUpload(false)}>
                        <h4 className="m-0" style={{color: `${!singleUpload ? 'white' : ''}`}}>Bulk Upload</h4>
                      </button>
                    </div>
                  </div>
                {singleUpload ? (
                  <SingleUpload parentHandler={setCentredModal}/>
                ) : (
                  <BulkUpload api={"guests"} onClose={toggleShow}/>
                )}
              {/* </form> */}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default AddGuests