import React, { useState, useContext } from "react";
import { Context } from "../../MainPage.js";
import "./Pending.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal, Input, Box, Typography, Button } from "@mui/material";
import { MDBTextArea } from "mdb-react-ui-kit";
import { event } from "jquery";
import { MDBIcon } from "mdb-react-ui-kit";
import Chip from "@mui/material/Chip";

export default function Pending(props) {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const { loapi, guestapi, childCaller } = useContext(Context);
  const [guestList, setGuestList] = useState(guestapi);
  const [loList, setLoList] = useState(loapi);

  const [visible, setVisible] = useState(true);

  const [rejectedModal, setRejectModal] = useState(false);
  const [textEmpty, setTextEmpty] = useState(false);
  const [rejectComment, setRejectComment] = useState("");
  const [textLength, setTextLength] = useState(0);
  const maxLength = 250;

  const handleChange = (event) => {
    const { value } = event.target;
    setRejectComment(value);
    setTextLength(value.length);
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "#E5E4E2",
    color: "var(--application-base-theme-secondary-color)",
    boxShadow: 24,
    px: 4,
    py: 3,
    borderRadius: "5px",
  };

  const getTimeString = (e) => {
    let date = new Date(e._seconds * 1000 + e._nanoseconds / 1000000);

    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday =
      dd + "/" + mm + "/" + yyyy + "  " + date.toTimeString().slice(0, 9);
    return formattedToday;
  };

  const getSenderName = (e) => {
    let name = loList.find((e) => {
      return e.id === props.item.sender;
    });
    if (typeof name != "undefined") return name.data.name;
    return "LoName";
  };
  const getGuestName = (e) => {
    let obj = guestapi.find((e) => e.id === props.item.guest);
    if (typeof obj != "undefined") return obj.name;
    return "Not Assigned";
  };

  const getGuestId = (e) => {
    let obj = guestapi.find((e) => e.id === props.item.guest);
    if (typeof obj != "undefined") {
      console.log(obj.id);
      return obj.id;
    }

    return "Not Assigned";
  };
  const handleDoneCard = (e) => {
    let obj = JSON.parse(sessionStorage.getItem("Auth_Token"));

    axios
      .post(
        `${BASE_URL}/requests/acceptPendingReq`,
        {
          reqId: e,
          id: obj.claims.user_id,
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((res) => {
        setVisible(false);
        alert("Request Accepted Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRejectCard = (e) => {
    if (rejectComment.trim().length === 0) {
      setTextEmpty(true);
      return;
    }
    let token = JSON.parse(sessionStorage.getItem("Auth_Token"));
    console.log(token.claims);
    axios
      .post(
        `${BASE_URL}/requests/markRequest`,
        {
          id: props.item.id,
          admin: token.claims.user_id,
          val: "Rejected",
          comment: rejectComment,
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((res) => {
        childCaller("Guest");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let userCheck;
  let check = false;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    check =
    ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
    ("RequestAdmin" in userCheck && userCheck.RequestAdmin === true)
      ? true
      : false;
  } catch (error) {
    navigate("/");
  }

  return (
    <>
      <div
        className="Card_Holder"
        style={{ display: visible ? "block" : "none" }}
      >
        <div className="Card_Wrapper">
          <div className="Content_Wrapper">
            <div className="col-md-8">
              <div className="Header_Div">
                <div className="description_title">{props.item.title}</div>
              </div>
              <div className="timestamp">
                {getTimeString(props.item.timestamp)}
              </div>
              <div className="description">
                <div className="description_content_wrapper">
                  {props.item.description}
                </div>
              </div>
            </div>
            <div class="sender-target">
              <div className="Description_Sender_wrapper">
                <div
                  style={{
                    marginInline: "2px",
                    marginTop: "2px",
                    fontSize: "15px",
                  }}
                >
                  Request Sender
                </div>
                <div className="box_name">
                  <div className="Sender_block" style={{ cursor: "default" }}>
                    {getSenderName()}
                    <MDBIcon className="" fas icon="angle-right" />
                  </div>
                </div>
              </div>
              <div className="Guest_wrapper">
                <div style={{ marginInline: "2px", marginTop: "2px" }}>
                  Target Guest
                </div>
                <div
                  style={{
                    marginLeft: "25px",
                    marginTop: "2px",
                    paddingInline: "5px",
                  }}
                >
                  <div
                    className="Sender_block"
                    onClick={(e) => {
                      const id = getGuestId();
                      if (id !== "Not Assigned") {
                        navigate("/guestDesc/" + id);
                      }
                    }}
                  >
                    {getGuestName()}
                    <MDBIcon fas icon="angle-right" />
                  </div>
                </div>
              </div>
              <div>
                <button
                style={{cursor:"default", backgroundColor: `var(${props.requestColour})`}}
                  className="chip_target mx-2 my-4"
                >{props.item.target}</button>
              </div>
              <button
                className="Main_Button"
                onClick={() => {
                  handleDoneCard(props.item.id);
                }}
                disabled = {check? false: true}
              >
                <MDBIcon far icon="check-circle" className="mx-2" />
                Accept Request
              </button>
            </div>
          </div>
          {/* <div className="Description_Wrapper">
            <div style={{ flexBasis: "100%" }}>
              <button
                className="Done_Btn"
                name="Done_Btn"
                id="Done_Btn"
                onClick={() => {
                  handleDoneCard(props.item.id);
                }}
              >
                Accept Request
              </button>
              <button
                className="Reject_Btn"
                name="Done_Btn"
                id="Done_Btn"
                onClick={() => {
                  setTextEmpty(false)
                  setRejectModal(true);
                  // handleRejectCard(props.item.id);
                }}
              >
                Reject Request
              </button>
            </div>
          </div> */}
        </div>

        {/* {rejectedModal && (
          <Modal
          keepMounted 
          open={rejectedModal} 
          onClose={()=>{setRejectModal(false)}}>

            <Box sx={style}>
              <MDBTextArea label="Add remarks for rejection" rows={5} col={10} style={{backgroundColor: "#D3D3D3"}} maxLength={maxLength} value={rejectComment} onChange={(e)=>handleChange(e)}/>
              <p className="text text-dark">{textLength}/{maxLength} characters</p>
              {textEmpty ? <div className="py-2 text-danger">Please add remarks</div> : null}
              <Button variant="text" style={{backgroundColor: "var(--application-base-theme-primary-color)", color: "white", margin: "1rem 1rem 0 0"}}
              onClick={handleRejectCard}>
                Reject Request
              </Button>
              <Button variant="text" style={{backgroundColor: "red", color: "white", margin: "1rem 1rem 0 1rem"}}
                onClick={()=>{setRejectModal(false)}} >
                Cancel
              </Button>
            </Box>

          </Modal>
        )} */}
      </div>
    </>
  );
}
