import React, { useEffect, useState, useContext } from "react";
import "../Dashboard.css";
import { app } from "../../FirebaseConfig";
import { Outlet, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import "firebase/firestore";
import ScreenLoader from "../ScreenLoader";
import GuestDesc from "./GuestDesc.js";
import GuestTable from "../../Components/GuestComponents/GuestTable";
import GuestSemiDesc from "./GuestSemiDesc";
import VehicleAssignGuest from "../../Components/UpdatesComponents/VehicleAssignGuest";
import { Context } from "../../MainPage";
import RoomAssignGuest from "../../Components/UpdatesComponents/RoomAssignGuest";
import LoAssignGuest from "../../Components/UpdatesComponents/LoAssignGuest";
import CustomToolTip from "../../Components/ToolTip/CustomToolTip";
import AlertModal from "./AlertModal";

const Guests = () => {
  // const test = useContext(UserContext);
  // console.log("this"+test)
  const auth = getAuth(app);
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(false);
  const [desc, setDesc] = useState(false);

  // Update Params & Modal Control
  const [vehicleModal, setVehicleModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [assignVehicle,setAssignVehicle] = useState(
    {vehicleID : {
      id : "",
      name : ""
    },guestId : 
      {
        id : "",
        name : "",
      }
  })
  const [roomModal, setRoomModal] = useState(false);

  const [assignRoom,setAssignRoom] = useState({hotelId: null, guestId: null, roomId: null})
  const [hotelModal, setHotelModal] = useState(false);
  const [assignHotel,setAssignHotel] = useState({hotelId: null, guestId: null, roomId: null})
  const [loModal, setLoModal] = useState(false);
  const [assignLo,setAssignLo] = useState({loId: null, guestId: null})

  // Guest State
  const {guestapi, childCaller} = useContext((Context));
  const [guest, setGuest] = useState(guestapi);
  
  // Advanced Search and Static Search



  useEffect(() => {

    if(user){
        user
          .getIdTokenResult()
          .then(async (e) => {
            await sessionStorage.setItem("Auth_Token", JSON.stringify(e));
            await sessionStorage.setItem("User_Permission", JSON.stringify(e.claims));
          })
          .catch((e) => console.log(e));
    } else {
      navigate("/");
    }

    let uuid = sessionStorage.getItem("UUID");
    if(uuid === null){
      let token = JSON.parse(sessionStorage.getItem("Auth_Token"));
      if(token !== null){
        uuid = token.claims.user_id;
      }
    }
    
    if (loading) {
      return;
    }
    if (!user) navigate("/");
    if (error) alert(error);
    setGuest(guestapi);
  }, [user, loading, navigate, guestapi, guest]);

  // Search functionality
  const filterList = (searchParam) => {
    if (searchParam.trim().length === 0) {
      setGuest(guestapi);
    } else {
      let tempList = guestapi;
      let filteration = tempList.filter((e) => {
      return (e.name.toLowerCase().includes(searchParam.toLowerCase()) 
      || e.contacts[0].toLowerCase().includes(searchParam.toLowerCase()) 
      || e.contacts[1].toLowerCase().includes(searchParam.toLowerCase())
      || e.designation.toLowerCase().includes(searchParam.toLowerCase())
      || e.constituency.toLowerCase().includes(searchParam.toLowerCase())
      || e.party.toLowerCase().includes(searchParam.toLowerCase()))
      });
      setGuest(filteration);
    }
  };

  const onAdd = () => {
    const urlArr = window.location.pathname.split("/");
    const category = urlArr[urlArr.length - 1];
    navigate(`add_${category}`);
  };

  let check;
  let userCheck;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    check = (("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) || ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true))? true : false;
  } catch (error) {
    navigate("/")
  }

  return (
    <>
      {desc ? (
        <GuestDesc />
      ) : (
        <>
          {/* <div className="dashboard d-flex dashboardmain"> */}
            <Outlet />
            <div
              style={{
                flex: "1 1 auto",
                display: "flex",
                flexFlow: "column",
                height: "100vh",
                overflowY: "auto"
              }}
            >
              <div className="container-fluid">
                <div className="w-100 ">
                  <div className="d-flex justify-content-between">
                    <h3
                      className="fw-bold my-3 page_header_title_h3"
                    >
                      Guests
                    </h3>
                    <div className="d-flex justify-content-around align-items-center">
                      <CustomToolTip title="Send Alert">
                          <button
                            className="mx-2"
                            style={{
                              border: "none",
                              backgroundColor: "#222",
                              color: "white",
                              padding: "0.5rem 1rem",
                              borderRadius: "5px"
                            }}
                            onClick={()=>setAlertModal(true)}
                          >Send Alert</button>
                        </CustomToolTip>
                      <CustomToolTip title="Assign Guest and Lo">
                        <button
                          className="mx-2"
                          style={{
                            border: "none",
                            backgroundColor: "#222",
                            color: "white",
                            padding: "0.5rem 1rem",
                            borderRadius: "5px"
                          }}
                          onClick={()=>setLoModal(true)}
                        >Assign LO</button>
                      </CustomToolTip>
                      <CustomToolTip title="Assign Room to Guest">
                      <button
                        className="mx-2"
                        style={{
                          border: "none",
                          backgroundColor: "#222",
                          color: "white",
                          padding: "0.5rem 1rem",
                          borderRadius: "5px"
                        }}
                        onClick={()=>setRoomModal(true)}
                      >Assign Hotel and Room</button>
                      </CustomToolTip>
                      <CustomToolTip title="Assign Vehicle to Guest">
                      <button
                        className="mx-2"
                        style={{
                          border: "none",
                          backgroundColor: "#222",
                          color: "white",
                          padding: "0.5rem 1rem",
                          borderRadius: "5px"
                        }}
                        onClick={()=>setVehicleModal(true)}
                      >Assign Vehicle</button>
                      </CustomToolTip>
                      <CustomToolTip title="Add a Guest">
                        <button
                          className="mx-2"
                          style={{
                            border: "none",
                            background: "white",
                            color: "#222",
                          }}
                          onClick={onAdd}
                          disabled={check? false: true}
                        >
                          <i class="fas fa-plus-circle fa-2x"></i>
                        </button>
                      </CustomToolTip>
                    </div>
                  </div>
                  <GuestSemiDesc  guestArray={guest}/>
                        
                </div>
              </div>
              {loader ? (
                <div className="min-w-100 min-h-100 d-flex justify-content-center align-items-center m-auto">
                  <ScreenLoader />
                </div>
              ) : (
                <>
                      <div className="ms-3 me-3 pb-3" style={{ overflowY: "auto", overflowX: "auto", display: "flex", flexDirection: "column", flexGrow: "1"}}>
                        <GuestTable guestData={guest}/>
                      </div>
                </>
              )}
              {vehicleModal && <VehicleAssignGuest key={"VehicleAssignModalView"}
              parentHandler={setVehicleModal} changesRet={setAssignVehicle} setIDs={assignVehicle}/>}
              {roomModal && <RoomAssignGuest key={"RoomAssignModalView"}
              parentHandler={setRoomModal} changesRet={setAssignRoom} setIDs={assignRoom}/>}
              {hotelModal && <RoomAssignGuest key={"RoomAssignModalViewGhost"}
              parentHandler={setHotelModal} changesRet={setAssignHotel} setIDs={assignHotel}/>}
              {loModal && <LoAssignGuest key={"LOAssignGuestModalView"}
              parentHandler={setLoModal} changesRet={setAssignLo} setIDs={assignLo}/>}
              {alertModal && <AlertModal key={"AlertModalView"}
              parentHandler={setAlertModal}/>}
            </div>
        </>
      )}
    </>
  );
};

export default Guests;
