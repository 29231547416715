import React, { useEffect, useState, useContext } from 'react';
import { Context } from "../../MainPage";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput
} from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import BulkUpload from '../BulkUpload';
import axios from 'axios';
const AddVehicles = () => {

  const BASE_URL = process.env["REACT_APP_BASE_URL"]

  const [centredModal, setCentredModal] = useState(true);
  const [InputErr, setInputErr] = useState('');
  const [singleUpload,setSingleUpload] = useState(true)
  const navigate = useNavigate();

  const { childCaller } = useContext(Context);

  // Vehicle States
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverContact1, setDriverContact1] = useState("");
  const [driverContact2, setDriverContact2] = useState("");
  const [description, setDescription] = useState("");
  const [vendor, setVendor] = useState("");

  const toggleShow = () => {
    setCentredModal(!centredModal);
  }

  useEffect(()=>{
    if(!centredModal){
      navigate(-1)
    }
  })

  const onSubmit = (e) => {
    e.preventDefault();
    
    if (driverContact1.toString().length !== 10) {
      // Add alert message
      setInputErr("Invalid contact information");
      return;
    }
    else{
    
    // TODO : Localhost To Deployed
    axios.post(`${BASE_URL}/vehicles/addVehicle`, 
    {
      no: vehicleNumber,
      name: vehicleName,
      driver_name: driverName,
      contact1:"+91"+ driverContact1,
      contact2: driverContact2,
      description: description,
      vendor: vendor
    },{
      headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
    }
  )
    .then((res) => {
      alert("Vehicle Added Successfully");
      childCaller("Vehicles");
    })
    .catch((err) => {
      console.log(err);
    })
  }
    
  }

  let userCheck;
  let check;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    check = [(("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) || ("VehicleAdmin" in userCheck && userCheck.VehicleAdmin === true))]
    && (vehicleNumber!=="" && vehicleName!=="" && driverName!=="" && driverContact1!=="" && driverContact2!=="" && description!=="" && vendor!=="")
    ? true : false;
  } catch (error) {
    
  }
  // console.log(check);

  return (
    <>
      <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add Vehicle</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody style={{height: "70vh",overflowY: 'auto'}}>
            <div className='d-flex'>
                <div className={`w-50 text-center p-2`} style={{backgroundColor: `${singleUpload ? '#3555A1' : ''}`}}>
                      <button className='text-center border-0 bg-transparent' onClick={()=>setSingleUpload(true)}>
                        <h4 className="m-0" style={{color: `${singleUpload ? 'white' : ''}`}}>Single Upload</h4>
                      </button>
                    </div>
                    <div className='w-50 text-center p-2' style={{backgroundColor: `${!singleUpload ? '#3555A1' : ''}`}}>
                      <button className='text-center border-0 bg-transparent' onClick={()=>setSingleUpload(false)}>
                        <h4 className="m-0" style={{color: `${!singleUpload ? 'white' : ''}`}}>Bulk Upload</h4>
                      </button>
                    </div>
                  </div>
                  {
                  singleUpload ? (
                    <div className="p-3 my-4">
                      {/* <form style={{ padding: "5px" }}> */}
                      <MDBInput wrapperClass='mb-4 mt-4' label='Vehicle Number' id='formControlLg' type='text' value={vehicleNumber} onChange={(e) => setVehicleNumber(e.target.value)} size="lg" />
                      <MDBInput wrapperClass='mb-4' label='Vehicle Name' id='formControlLg' type='text' value={vehicleName} onChange={(e) => setVehicleName(e.target.value)} size="lg" />
                      <MDBInput wrapperClass='mb-4' label='Driver Name' id='formControlLg' type='text' value={driverName} onChange={(e) => setDriverName(e.target.value)} size="lg" />
                      <div className='mb-4 d-flex'>
                        <input
                          className="country-code-readOnly"
                          placeholder="+91"
                          readOnly
                        />
                        <MDBInput wrapperClass='h-full flex-grow-1' label='Driver Contact Number' id='formControlLg' type='number' value={driverContact1} onChange={(e) => setDriverContact1(e.target.value)} size="lg" />
                      </div>
                      <MDBInput wrapperClass='mb-4' label='Driver Email' id='formControlLg' type='email' value={driverContact2} onChange={(e) => setDriverContact2(e.target.value)} size="lg" />
                      <MDBInput wrapperClass='mb-4' label='Description' id='formControlLg' type='text' value={description} onChange={(e) => setDescription(e.target.value)} size="lg" />
                      <MDBInput wrapperClass='mb-4' label='Vendor' id='formControlLg' type='text' value={vendor} onChange={(e) => setVendor(e.target.value)} size="lg" />

                      {/* <input className="mb-4" style={{ backgroundColor: "rgb(66, 83, 151)", color: "#fff", padding: "7px", border: "none", borderRadius: "5px", float: "right" }} type="submit" value="Add Vehicle" disabled={check? false: true} /> */}
                      <div>
                      <button
                className="application_base_btn max_media_width"
                style={{ float: "right"}}
                disabled={check ? false : true}
                onClick={onSubmit}
              >
                Add Vehicle
              </button>
              </div>
                      {/* </form> */}
                      </div>
                  ) : (
                    <BulkUpload api={"vehicles"} onClose={toggleShow}/>
                  )
                }
              {/* <MDBModalFooter>
                      <MDBBtn style={{width: '100%'}}>Add</MDBBtn>
                      </MDBModalFooter> */}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default AddVehicles