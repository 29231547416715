import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { MDBInput,MDBModal,MDBModalDialog,MDBModalContent,MDBModalHeader,MDBModalTitle,MDBBtn,MDBModalBody,MDBRow,MDBCol } from 'mdb-react-ui-kit';
import { Input } from '@mui/material';
import './AlertModal.css'
import Modal from '@mui/material/Modal';
import { MDBTextArea } from 'mdb-react-ui-kit';
import axios from 'axios';

const AlertModal = (props) => {

    const BASE_URL = process.env["REACT_APP_BASE_URL"]

    const navigate = useNavigate();

    const [descText,setTextDesc] = useState('')
    const [guestSelected,setGuestSelected] = useState(false)
    const [losSelected,setLosSelected] = useState(false)
    const [from,setFrom] = useState('')
    const [to,setTo] = useState('')
    const [isEvent,setIsEvent] = useState(false)
    const [venue,setVenue] = useState('')
    const [title,setTitle] = useState('')

    const sendAlert = async () => {
        let target = ''
        if(guestSelected && losSelected){
            target="All"
        }else if(guestSelected){
            target="Guests"
        }else{
            target="LOs"
        }

        // TODO: axios call
        axios.post(BASE_URL + '/broadcast/addBroadcast',{
            target: target,
            timings: [from,to],
            title: title,
            venue: venue,
            event: isEvent
        },
        {headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }})
        .then((res)=>{
            console.log(res);
            props.parentHandler(false)
            alert("Alert sent");
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    let userCheck;
    let check;
    try {
        userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
        check =
        ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
        ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true) ||
        ("LoAdmin" in userCheck && userCheck.LoAdmin === true)
        ? true
        : false;
    } catch (error) {
        navigate("/");
    }
    

  return (
    <MDBModal show={true}  tabIndex='-1' onHide={() =>{props.parentHandler(false)}}>
        <MDBModalDialog size='lg'>
          <MDBModalContent>
            <MDBModalHeader>
              {/* <MDBModalTitle><h2 className="fw-bold">Send Alert</h2></MDBModalTitle> */}
              <MDBBtn className='btn-close' color='none' onClick={()=>{props.parentHandler(false)}}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className='px-4'>
                <div className='div1'>
                    <div>
                        <h4>Send To</h4>
                        <div className='checkbox-div'>
                            <input type="checkbox" id='guests' value="Guests" onChange={(e)=>setGuestSelected(e.target.checked)}/>
                            <label htmlFor="guests" id='guests'>Guests</label>
                        </div>
                        <div className='checkbox-div'>
                            <input type="checkbox" id='los' value="LOs" onChange={(e)=>setLosSelected(e.target.checked)}/>
                            <label htmlFor="los" id='los'>LOs</label>
                        </div>

                        <div className="form-floating my-2">
                            {/* <input
                                type="datetime-local"
                                placeholder="From"
                                className="form-control date-input"
                                label="from"
                                id="formControlLg"
                                onChange={(e)=>setFrom(e.target.value)}
                                required
                            /> */}
                            <div>
                                <p className="">From</p>
                            </div>
                            <input style={{ border: 'none' }} pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}" type='datetime-local' placeholder='From'
                            label="to" id='formControlLg' onChange={(e)=>setFrom(e.target.value)} required />
                        </div>

                        <div className="form-floating my-2">
                            {/* <input
                                type="datetime-local"
                                placeholder="To"
                                className="form-control date-input"
                                label="to"
                                id="formControlLg"
                                onChange={(e)=>setTo(e.target.value)}
                                required
                            /> */}
                            <div>
                                <p className="">To</p>
                            </div>
                            <input style={{ border: 'none' }} pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}" type='datetime-local' placeholder='To'
                            label="to" id='formControlLg' onChange={(e)=>setTo(e.target.value)} required />
                        
                        </div>
                    </div>

                    <div>
                        {/* <textarea name="alert_description" id="alert_description" cols="30" rows="6" placeholder='Enter description'></textarea> */}
                        <MDBTextArea label={`${guestSelected && !losSelected  ? 'Enter Title' : 'Enter Description'}`} id='description' rows={6} cols={30} className="textarea" value={title} onChange={(e)=>setTitle(e.target.value)} required/>
                        {
                            guestSelected && !losSelected ? 
                            <div className='checkbox-div'>
                                <input type="checkbox" id='isEvent' value="isEvent" checked={isEvent} onChange={()=>setIsEvent(!isEvent)}/>
                                <label htmlFor="isEvent">Is this an event ?</label>
                            </div>
                            : null
                        }
                        <div className="form-floating my-2">
                            <input
                                type="text"
                                placeholder="Venue"
                                className="form-control"
                                label="Venue"
                                id="formControlLg"
                                value={venue}
                                onChange={(e)=>setVenue(e.target.value)}
                                required
                            />
                            <label className="form-label ">
                                <div className="d-flex flex-row ">
                                <p className="">Venue</p>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='div2'>
                    <button className="application_base_btn"
                    style={{padding: "0.2rem 0.4rem"}}
                    onClick={sendAlert}
                    disabled={check? false:true}
                    >
                        Send Alert
                    </button>
                    <button className="cancel-alert-btn"
                    style={{
                        backgroundColor: 'transparent',
                        border: "1px solid black",
                        color: "#fff",
                        padding: "0.5rem 2rem",
                        borderRadius: "5px",
                        marginLeft: "1rem",
                        color:"red"
                    }}
                    onClick={()=>{props.parentHandler(false)}}
                    >
                        Cancel
                    </button>
                </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
  )
}

export default AlertModal
