import React, { useState, useContext, useEffect } from 'react'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { app } from "../../FirebaseConfig";
import { Context } from '../../MainPage';
import './GuestDesc.css'
import '../Dashboard.css'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from "@mui/material";

// import { useLocation, useNavigate } from 'react-router-dom'


const GuestDesc = () => {

  const BASE_URL = process.env["REACT_APP_BASE_URL"]

  const { guestapi, loapi, vehicleapi, hotelapi } = useContext(Context);

  const [guestList, setGuestList] = useState(guestapi);
  const [loList, setLoList] = useState(loapi);
  const [hotelList, setHotelList] = useState(hotelapi);
  const [vehicleList, setVehicleList] = useState(vehicleapi);

  const [departure, setDeparture] = useState("")
  const [destination, setDestination] = useState("")
  const [transportMode, setTransportMode] = useState("Select mode")
  const [departureTime, setDepartureTime] = useState('')
  const [arrivalTime, setArrivalTime] = useState('')
  const [timings, setTimings] = useState([])
  const [file, setFile] = useState('')
  const [travel, setTravel] = useState([])
  const [hasTravel, setHasTravel] = useState([])
  const [uploadLoader, setUploadLoader] = useState(false);
  const [addLoader, setAddLoader] = useState(false);

  const [guest, setGuest] = useState({});
  const [loAdded, setLoAdded] = useState()
  const [conveyanceAdded, setConveyanceAdded] = useState({})
  const [stayDetailsAdded, setStayDetailsAdded] = useState({})
  const [rooms, setRooms] = useState({})
  const [arrivalAdded, setArrivalAdded] = useState()
  const [departureAdded, setDepartureAdded] = useState()

  const [loader, setLoader] = useState(true);
  const [loadItenary, setLoadItenary] = useState(true);
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [uploadError,setUploadError] = useState('')
  const [refNo,setRefNo] = useState('')

  const location = useLocation();
  const navigate = useNavigate();

  let userCheck;
  let check;
  try {
      userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
      check =
      ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
      ("TravelAdmin" in userCheck && userCheck.TravelAdmin === true) 
      ? true
      : false;
  } catch (error) {
      navigate("/");
  }

  const getItenary = async () => {
    let urlArr = window.location.href.split("/");
    urlArr = urlArr[urlArr.length - 1];
    await axios.get(BASE_URL+'/travel/getTravel/' + urlArr,
      {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
      })
      .then((res) => {
        console.log(res.data);
        setLoadItenary(false)
        if (res.data) {
          setHasTravel(true)
          console.log(res.data);
          setTravel(res.data)
        } else {
          setHasTravel(false)
          setTravel([])
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }


  const addItenary = async () => {
    if(file === undefined || file?.trim().length === 0){
      setUploadError('Please upload the ticket')
      return
    }
    setAddLoader(true)
    setUploadError('')
    //setTimings([departureTime, arrivalTime])
    await axios.post(BASE_URL+'/travel/addItenary',
      {
        id: guest.id,
        from: departure,
        to: destination,
        mode: transportMode,
        ticket: file,
        timings: [departureTime, arrivalTime],
        hasTravel: hasTravel,
        previousItenary: travel,
        pickUpPoint: pickUp,
        dropOffPoint: dropOff,
        ref_no: refNo
      }
      , {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
      })
      .then((res) => {
        setAddLoader(false)
        setTravel([...travel, res.data])
      })
      .catch((err) => {
        alert(err);
        setAddLoader(false);
        console.log(err);
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps 
  const getRoom = async (guest) => {
    // TODO : Localhost To Deployed
    axios.get(`${BASE_URL}/rooms/getSingleRoom/${guest.hotel}/${guest.room}`, {
      headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
    })
      .then((res) => {
        console.log(res.data);
        // setRooms(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const dateToFormatedDate = (dateObj) => {
    const yyyy = dateObj.getFullYear();
    let mm = dateObj.getMonth() + 1; // Months start at 0!
    let dd = dateObj.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy + "    " + dateObj.toTimeString().slice(0, 9)
    return formattedToday;
  }

  const uploadTicketDoc = () => {
    setUploadLoader(true)
    let storage = getStorage(app);
    const ticketMetaData = {
      contentType: file.type,
    };
    let ticketsURL = []

    // Used For Loop because map does not work on FileList type
    // for (let i = 0; i < file.length; i++) {
      // Ticket URL generation and upload to firebase storage
      const travelIdDocRef = ref(storage, "Travels/" + file.name);
      const uploadTravelIdDocTask = uploadBytesResumable(travelIdDocRef, file, ticketMetaData);
      uploadTravelIdDocTask.on("state_changed", (snapshot) => {
        console.log("Uploading Identification State...");
      }, (error) => {
        console.log(error);
      }, () => {
        getDownloadURL(uploadTravelIdDocTask.snapshot.ref).then((downloadURL) => {
          // setGuest((prev) => {
          //   return {...prev, identification_doc: downloadURL};
          // })
          setUploadLoader(false)
          setFile(downloadURL)
          // ticketsURL.push(downloadURL)
          // if (i === file.length - 1) {
          //   setFile(ticketsURL)
          // }
        });
      })
    // }
  }

  const travelInfoCard = () => {
    if(travel.length === 0){
      return (
        <h3>No travel Information</h3>
      )
    }
    return travel.map((t) => {
      return (
        <div className="d-flex travel-info">
          <div>
            <div className="d-flex justify-content-start">
              <span className="d-flex  input-group-text bg-transparent  border border-0  p-0">
                <i
                  style={{ color: "#7A8FC1" }}
                  className="fa fa-location-arrow  "
                  aria-hidden="true"
                ></i>
              </span>
              <h5 className="my-1 px-2 fw-bold">From</h5>
            </div>
            <div>
              <p className="d-flex mx-4">
                <u>{t.from}</u>
              </p>
            </div>
          </div>
          <div className=" d-flex justify-content-center  align-items-center mx-2 ">
            <span className=" input-group-text bg-transparent border border-0 ">
              <i
                style={{ color: "#7A8FC1" }}
                className="fa fa-long-arrow-right fa-2x "
                aria-hidden="true"
              ></i>
            </span>
          </div>
          <div className="">
            <div className="d-flex justify-content-start ">
              <span className="d-flex  input-group-text bg-transparent  border border-0  p-0">
                <i
                  style={{ color: "#7A8FC1" }}
                  className="fa fa-map-marker"
                  aria-hidden="true"
                ></i>
              </span>
              <h5 className="my-1 px-2 fw-bold">To</h5>
            </div>
            <div className=" mx-3">
              <p>
                {" "}
                <u>{t.to}</u>{" "}
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center mx-2 ">
            <button
              className="btn d-flex justify-content-center align-items-center my-3 download-ticket"
              onClick={()=>{window.open(t.ticket,"_blank")}}
            >
              <a href={t.tickets} download target="_blank">
                <p style={{ color: "#FFFFFF" }} className="m-0 p-0">
                  Ticket
                </p>
                <span
                  style={{ color: "#FFFFFF" }}
                  className="d-flex justify-content-center align-items-center  input-group-text bg-transparent mx-2  border border-0  p-0"
                >
                  <i className="fa fa-download" aria-hidden="true"></i>
                </span>
              </a>
            </button>
          </div>
        </div>
      );
    })
  };

  useEffect(() => {

    let urlArr = window.location.href.split("/");
    urlArr = urlArr[urlArr.length - 1];
    const asyncFunction = async (urlArr) => {
      let guestObj = await guestList.find((e) => e.id === urlArr);
      console.log(guestObj);
      setGuest(guestObj);
      getItenary()
      let loObj = await loList.find((e) => e.id === guestObj.lo);
      let hotelObj = await (hotelList.find((e) => e.id === guestObj.hotel));
      let vehicleObj = await vehicleList.find((e) => e.id === guestObj.vehicle);

      console.log(guest);

      if (guestObj.lo !== null) {
        if (typeof loObj === 'undefined')
          setLoAdded(null)
        setLoAdded(loObj.data);
        console.log(loObj);
      } else {
        setLoAdded(null)
      }

      if (guestObj.hotel !== null) {
        if (typeof hotelObj === 'undefined')
          setStayDetailsAdded(null);
        setStayDetailsAdded(hotelObj)
      } else {
        setStayDetailsAdded(null);
      }

      if (guestObj.vehicle !== null) {
        if (typeof vehicleObj === 'undefined')
          setConveyanceAdded(null);
        setConveyanceAdded(vehicleObj)
      } else {
        setConveyanceAdded(null);
      }
    }

    asyncFunction(urlArr);

  }, [location.pathname])

  useEffect(() => {
    if (guest !== null) {
      setLoader(false);
    }
  }, [guest])

  const displayLo = () => {
    return (
      loAdded ? (
        <div className="d-flex py-2 my-2">
          <div className="d-flex justify-content-center align-items-center text-center">
            <img className="imgProfile " src={loAdded.image} alt="profile" />
          </div>
          <div>
            <h5 className="m-0 fw-bold">{loAdded.name}</h5>
            {/* <p className="m-0 ">{loAdded.contacts[0]} | {loAdded.contacts[1]}</p> */}
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center p-4 my-2' style={{ backgroundColor: '#F8F8FB', borderStyle: 'dashed', borderColor: '#7A8FC1' }}>
          <i className="fa-solid fa-plus fs-4" style={{ color: '#7A8FC1' }}></i>
        </div>
      )
    )
  }
  const displayConveyance = () => {
    return (
      conveyanceAdded ? (
        <div className="d-flex my-3 ">

          <div className="d-flex iconMagin">
            <span className=" input-group-text bg-white border border-0">
              <i className="icon d-flex justify-content-center align-items-center fa fa-car carSymbol fa-2x"
                aria-hidden="true"></i>
            </span>
            <div>
              <h5 className="m-0 fw-bold">{conveyanceAdded.no}</h5>
              <p className="m-0">{conveyanceAdded.name}</p>
            </div>
          </div>
          <div className="d-flex  iconMagin ">
            <span className=" input-group-text bg-white border border-0 ">
              <i className="icon d-flex justify-content-center align-items-center fa fa-id-card  carSymbol fa-2x"
                aria-hidden="true"></i>
            </span>
            <div>
              <h5 className="m-0 fw-bold">{conveyanceAdded.driver_name}</h5>
              {/* <p className="m-0">{conveyanceAdded.driver_contacts[0]} | {conveyanceAdded.driver_contacts[1]}</p> */}
            </div>
          </div>

        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center p-4 my-2' style={{ backgroundColor: '#F8F8FB', borderStyle: 'dashed', borderColor: '#7A8FC1' }}>
          <i className="fa-solid fa-plus fs-4" style={{ color: '#7A8FC1' }}></i>
        </div>
      )
    )
  }
  const displayStayDetails = () => {
    return (
      stayDetailsAdded ? (
        <div className="d-flex my-3 ">

          <div className="d-flex iconMagin">
            <span className=" input-group-text bg-white border border-0">
              <i className="icon d-flex justify-content-center align-items-center fa fa-bed carSymbol fa-2x"
                aria-hidden="true"></i>
            </span>
            <div className="d-flex justify-content-center align-items-center ">
              <h5 className="m-0 fw-bold ">{stayDetailsAdded.name}</h5>

            </div>
          </div>
          <div className="d-flex  iconMagin ">
            <span className=" input-group-text bg-white border border-0 ">
              <i className="icon d-flex justify-content-center align-items-center fa fa-key carSymbol fa-2x"
                aria-hidden="true"></i>
            </span>
            <div>
              <h5 className="m-0 fw-bold">Room No. {rooms.no} </h5>
              <p className="m-0"> {rooms.type} type with occupancy of {rooms.occupancy_size}</p>
            </div>
          </div>

        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center p-4 my-2' style={{ backgroundColor: '#F8F8FB', borderStyle: 'dashed', borderColor: '#7A8FC1' }}>
          <i className="fa-solid fa-plus fs-4" style={{ color: '#7A8FC1' }}></i>
        </div>
      )
    )
  }
  const displayArrival = () => {
    return (
      arrivalAdded ? (
        <div>
          <h5 className="fw-bold ">Arrival</h5>
          <div className="d-flex  ">
            <span className="pe-2 pb-2 bg-white border border-0">
              <i className="fa-solid fa-calendar-days fs-5" style={{ color: '#7A8FC1' }}></i>
            </span>
            <p className="m-0"> {dateToFormatedDate(new Date(arrivalAdded.check_in[0]._seconds * 1000 + arrivalAdded.check_in[0]._nanoseconds / 1000000))} </p>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center px-5 py-2' style={{ backgroundColor: '#F8F8FB', borderStyle: 'dashed', borderColor: '#7A8FC1' }}>
          <h5 className="fw-bold " style={{ margin: '0 16px 0 16px' }}>Arrival</h5><i className="fa-solid fa-plus fs-4" style={{ color: '#7A8FC1' }}></i>
        </div>
      )
    )
  }
  const displayDeparture = () => {
    return (
      departureAdded ? (
        <div>
          <h5 className="fw-bold ">Departure</h5>
          <div className="d-flex  ">
            <span className="pe-2 pb-2 bg-white border border-0">
              <i className="fa-solid fa-calendar-days fs-5" style={{ color: '#7A8FC1' }}></i>
            </span>
            <p className="m-0"> {dateToFormatedDate(new Date(departureAdded.check_in[0]._seconds * 1000 + departureAdded.check_in[0]._nanoseconds / 1000000))} </p>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center px-5 py-2' style={{ backgroundColor: '#F8F8FB', borderStyle: 'dashed', borderColor: '#7A8FC1' }}>
          <h5 className="fw-bold " style={{ margin: '0 16px 0 16px' }}>Departure</h5><i className="fa-solid fa-plus fs-4" style={{ color: '#7A8FC1' }}></i>
        </div>
      )
    )
  }

  return (<>
    {loader ? "Loading" : (
    <div className="dashboard d-flex dashboardmain flex-grow-1">
      <div className="main-outerclass">
        <main className="container-fluid my-3">
          <div className='guest_description_main_div'>
            <div>
              <img className='guest_progfile_img' src={guest.image} alt='guest_profile_image' />
            </div>
            <div>
              <h4 className=" fw-bold m-0">{guest.name}</h4>
              <h5 className=" fw-bold m-0">{guest.designation}</h5>
              <p classNameName="  m-0">{guest.constituency}</p>
            </div>
          </div>
          <hr />
          <div>
            <div>
              <h4 className=" fw-bold m-0">Contact Information </h4>
              <p className="my-2">{guest.contacts[0]}</p>
              <p className='my-2'>{guest.contacts[1]}</p>
            </div>
            <hr />
          </div>
          <div>
            <div>
              <h4 className="d-flex justify-content-between fw-bold m-0">Liaison Officer
                {loAdded ? <i className="fa-solid fa-xmark pe-2" style={{ fontSize: 25 }}></i>
                  : null}
              </h4>
              {displayLo()}
            </div>
            <hr />
          </div>
          <div>
            <div>
              <h4 className="d-flex justify-content-between fw-bold m-0">Conveyance
                {conveyanceAdded ? <i className="fa-solid fa-xmark pe-2" style={{ fontSize: 25 }}></i>
                  : null}
              </h4>
              {displayConveyance()}
            </div>
            <hr />
          </div>
          <div>
            <div>
              <h4 className="d-flex justify-content-between fw-bold m-0">Stay Details
                {stayDetailsAdded ? <i className="fa-solid fa-xmark pe-2" style={{ fontSize: 25 }}></i>
                  : null}
              </h4>
              {displayStayDetails()}
            </div>
            <hr />
          </div>

          <div className="time-div m-0">
            <div className='pe-5'>
              <h4 className="d-flex justify-content-between fw-bold mb-2">Expected</h4>
              <div>
                <h5 className="">Check-In Time</h5>
                <div className="d-flex  ">
                  <span className="pe-2 pb-2 bg-white border border-0">
                    <i
                      className="fa-solid fa-calendar-days fs-5"
                      style={{ color: "#7A8FC1" }}
                    ></i>
                  </span>
                  {
                    guest.check_in.length > 0 ? <p className="m-0">{dateToFormatedDate(new Date(guest.check_in[0]._seconds * 1000 + guest.check_in[0]._nanoseconds / 1000000))}</p>
                    : null
                  }
                </div>
              </div>
              <div>
                <h5 className="">Check-Out Time</h5>
                <div className="d-flex  ">
                  <span className="pe-2 pb-2 bg-white border border-0">
                    <i
                      className="fa-solid fa-calendar-days fs-5"
                      style={{ color: "#7A8FC1" }}
                    ></i>
                  </span>
                  {
                    guest.check_out.length > 0 ? <p className="m-0">{dateToFormatedDate(new Date(guest.check_out[0]._seconds * 1000 + guest.check_out[0]._nanoseconds / 1000000))}</p>
                    : null
                  }
                </div>
              </div>
            </div>

            <div>
              <h4 className="d-flex justify-content-between fw-bold mb-2">Actual</h4>
              <div>
                <h5 className="">Check-In Time</h5>
                <div className="d-flex">
                  <span className="pe-2 pb-2 bg-white border border-0">
                    <i
                      className="fa-solid fa-calendar-days fs-5"
                      style={{ color: "#7A8FC1" }}
                    ></i>
                  </span>
                  {
                    guest.check_in.length > 1 ? <p className="m-0">{dateToFormatedDate(new Date(guest.check_in[1]._seconds * 1000 + guest.check_in[1]._nanoseconds / 1000000))}</p>
                    : <p>Not Checked In</p>
                  }
                </div>
              </div>
              <div>
                <h5 className="">Check-Out Time</h5>
                <div className="d-flex  ">
                  <span className="pe-2 pb-2 bg-white border border-0">
                    <i
                      className="fa-solid fa-calendar-days fs-5"
                      style={{ color: "#7A8FC1" }}
                    ></i>
                  </span>
                  {
                    guest.check_out.length > 1 ? <p className="m-0">{dateToFormatedDate(new Date(guest.check_out[1]._seconds * 1000 + guest.check_out[1]._nanoseconds / 1000000))}</p>
                    : <p>Not Checked Out</p>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="sec-div2">
            
            <div className="add-ticket">
              <h2>Add Ticket</h2>
              <div className="add-ticket-div1">
                <div>
                  <label htmlFor="departure" className="label"><h5 className="fw-bold">From</h5></label>
                  <input
                    type="text"
                    id="departure"
                    name="departure"
                    value={departure}
                    onChange={(e) => setDeparture(e.target.value)}
                    placeholder="Enter Departure"
                  />
                </div>
                <div>
                  <label htmlFor="destination" className="label"><h5 className="fw-bold">To</h5></label>
                  <input
                    type="text"
                    id="destination"
                    name="destination"
                    value={destination}
                    onChange={(e) => setDestination(e.target.value)}
                    placeholder="Enter Destination"
                  />
                </div>
              </div>

              <div className="terminal-div">
                    <div>
                        <label htmlFor="pickup" className="label"><h5 className="fw-bold">Pick up point</h5></label>
                        <input
                          type="text"
                          id="pickup"
                          name="pickup"
                          value={pickUp}
                          onChange={(e)=>setPickUp(e.target.value)}
                          placeholder="Enter Pickup Point"
                        />
                      </div>
                      <div>
                        <label htmlFor="dropoff" className="label"><h5 className="fw-bold">Drop off point</h5></label>
                        <input
                          type="text"
                          id="dropoff"
                          name="dropoff"
                          value={dropOff}
                          onChange={(e)=>setDropOff(e.target.value)}
                          placeholder="Enter Dropoff Point"
                        />
                      </div>
                  </div>

              <div className="add-ticket-div2">
                <div>
                  <label className="label">
                    <h5 className="fw-bold">Transport Mode</h5>
                  </label>
                  <select className="form-select" onChange={(e) => setTransportMode(e.target.options[e.target.selectedIndex].text)}>
                    <option value="" className="valueColor">
                      Select mode
                    </option>
                    <option value="" className="valueColor">
                      Airway
                    </option>
                    <option value="" className="valueColor">
                      Railway
                    </option>
                    <option value="" className="valueColor">
                      Bus
                    </option>
                  </select>
                </div>
                <div>
                  <label className="label">
                    <h5 className="fw-bold">Select File</h5>
                  </label>
                  <div className="upload-div">
                    <input type="file" className="form-control uploadFileSize select-file-input" onChange={(e) => {
                      setFile(e.target.files[0])
                    }} multiple />
                    {uploadLoader ? (
                            <div className="loader-div">
                              <CircularProgress className="loader" />
                            </div>
                          ) : (
                            <button
                              className="uploadBtn"
                              onClick={() => {
                                uploadTicketDoc();
                              }}
                            >
                              <i
                                className="fa fa-upload"
                                aria-hidden="true"
                              ></i>
                            </button>
                          )}
                  </div>
                </div>
              </div>
                <div>
                        <h5 className="fw-bold mt-4">Reference No</h5>
                        <input
                          type="text"
                          id="ref_no"
                          name="ref_no"
                          className="application_input_field_form_version"
                          value={refNo}
                          onChange={(e) => setRefNo(e.target.value)}
                          placeholder="Enter airline name and flight number"
                        />
                      </div>

              <div className="itenary-card-div">
                <div>
                  <label htmlFor="departure_time" className="label"><h5 className="fw-bold">Departure</h5></label>
                  <input
                    type="datetime-local"
                    id="departure_time"
                    name="departure_time"
                    onChange={(e) => setDepartureTime(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="arrival_time" className="label"><h5 className="fw-bold">Arrival</h5></label>
                  <input
                    type="datetime-local"
                    id="arrival_time"
                    name="arrival_time"
                    onChange={(e) => setArrivalTime(e.target.value)}
                  />
                </div>
              </div>

              <div className="btn-div">
                <div className="text-danger">
                  {uploadError}
                </div>
                {addLoader ? 
                <div className="add_loader-div">
                  <CircularProgress className="loader" />
                </div> 
                : <button
                  className="btn d-flex  justify-content-center align-items-center my-3 add-ticket-btn"
                  onClick={addItenary}
                  disabled={check? false: true}
                >
                  <i className="fa fa-add" aria-hidden="true"></i>
                  <p style={{ color: "#FFFFFF" }} className="m-0 p-0 ">
                    Add Ticket
                  </p>
                  <span
                    style={{ color: "#FFFFFF" }}
                    className="d-flex justify-content-center align-items-center  input-group-text bg-transparent mx-2  border border-0  p-0"
                  >
                  </span>
                </button>
                }
              </div>
            </div>
            <div className="travel-ticket-info d-flex flex-column">
                      <h2>Travel & Ticket Info</h2>
                      {loadItenary ? (
                        <div className="loader-div">
                          <CircularProgress/>
                        </div>
                      ) : (
                          <div className="tickets-card">
                            {travelInfoCard()}
                          </div>
                      )}
                </div>
          </div>

        </main>
      </div>
    </div>)}

  </>
  )
}

export default GuestDesc