import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBInput,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBBtn,
  MDBModalBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Context } from "../../MainPage";
import "./VehicleAssignGuest.css";
import "./Modal.css";
import axios from "axios";
import PairDisplay from "./PairDisplay";
import { Box, Alert, Snackbar } from "@mui/material";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import CustomToolBar from "../MuiToolBar/CustomToolBar";
import CustomToolTip from "../ToolTip/CustomToolTip";
import { loAssignStyle, tableStyle } from "../../Static/Styles";

export default function LoAssignGuest(props) {
  // console.log(props.setIDs);
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const [modalStatus, setStatus] = useState(true);

  const { guestapi, loapi, childCaller } = useContext(Context);

  const [guests, setGuests] = useState(guestapi);
  const [los, setLos] = useState(loapi);
  const [doublePair,setDoublePair] = useState(false);
  const handleClose = () => setDoublePair(false);
  const navigate = useNavigate();
  // console.log(props.setIDs.guestId);
  // console.log(props.setIDs.loId);
  
  const [selectionRows, setSelectionRow] = useState([]);
  const [selectionRowsTable2, setSelectionRowTable2] = useState([]);

  const [guestId, setGuestId] = useState(
    props.setIDs.guestId !== null? props.setIDs.guestId : { name: "", id: "" }
  );

  const [loId, setLoID] = useState(
    props.setIDs.loId !== null
      ? {
          name: props.setIDs.loId.name,
          id: props.setIDs.loId.id,
        }
      : {
          name: "",
          id: "",
        }
  );

  const [guestTolo, setGuestToLo] = useState(props.setIDs.guestId !== null? guestapi.find((e) => {
    if(e.id === props.setIDs.guestId.id){
      if(e.lo == null){
        return {lo: ""}
      } else {
        return e;
      }
    }
  }).lo : "");


  const loInputRef = useRef();
  const guestInputRef = useRef();

  const [pair_guest_list, setGuestList] = useState([]);

  const [pair_lo_list, setLoList] = useState([]);

  const [pair_guest_l0_list, setGuestLoList] = useState([]);

  const lo_columns = [
    {
      fields: "assign",
      headerName: "PairBtn",
      sortable: false,
      hideable: false,
      flex: 1,
      filterable: false,
      disableClickEventBubbling: true,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <button
            onClick={() => {
              handleLOEntry(params.row);
            }}
            className="application_base_btn table_btn_padding"
            disabled={params.row.numberOfGuests === 10}
          >
            Pair LO
          </button>
        );
      },
    },
    {
      field: "name",
      headerName: "Lo Name",
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      flex: 1,
      editable: false,
      sortable: true,
      hideable: false,
    },
    {
      field: "numberOfGuests",
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      flex: 1,
      headerName: "Guests Assigned",
      editable: false,
      sortable: true,
    },
    {
      field: "contacts",
      headerName: "Contact No.",
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      flex: 1,
      editable: false,
      sortable: true,
    },
  ];

  const guest_columns = [
    {
      field: "id",
      headerName: "PairBtn",
      sortable: false,
      hideable: false,
      flex: 1,
      filterable: false,
      disableClickEventBubbling: true,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      minWidth: 150,
      renderCell: (params) => {
        return (
          <button
            onClick={() => {
              handleGuestEntry(params.row, params.row.assignLo);
            }}
            className="application_base_btn table_btn_padding"
          >
            Pair Guest
          </button>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      editable: false,
      sortable: true,
      hideable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "assignLo",
      headerClassName: "prime_table_header_tab",
      headerName: "LO_Assigned",
      headerAlign: "center",
      editable: false,
      sortable: true,
      filterable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (cellValues) => {
          return <p className="mt-3">{cellValues.row.assignLo}</p>
      }
    },
    {
      field: "designation",
      headerClassName: "prime_table_header_tab",
      headerName: "Designation",
      headerAlign: "center",
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "party",
      headerClassName: "prime_table_header_tab",
      headerName: "Pol. Party",
      headerAlign: "center",
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "category",
      headerClassName: "prime_table_header_tab",
      headerName: "Category",
      headerAlign: "center",
      editable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "constituency",
      headerClassName: "prime_table_header_tab",
      headerName: "Constituency",
      headerAlign: "center",
      editable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "event_id",
      headerName: "NLC ID",
      editable: false,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
    },
  ];

  useEffect(() => {
    setGuests(guestapi);
    setLos(loapi);
  }, []);

  const parentHandler = () => {
    setLoID("");
    setGuestId("");
    props.parentHandler(false);
    setStatus(false);
  };

  const handleLOEntry = (e) => {
    setLoID(e);
  };

  const handleGuestEntry = (id, loid) => {
    setGuestId(id);
    if (loid === "Not Assigned" || loid === undefined) {
      setGuestToLo("");
    } else {
      setGuestToLo(loid);
    }
  };

  const PairItems = () => {
    if (
      typeof guestId !== "undefined" &&
      typeof loId !== "undefined" &&
      guestId.id !== "" &&
      loId.id !== ""
    ) {

      const gCheck = pair_guest_list.some(ele => ele.id === guestId.id);

      if(gCheck){
        setDoublePair(true);
      }else{
        setGuestList([...pair_guest_list, guestId]);
        setLoList([...pair_lo_list, loId]);
        setGuestLoList([...pair_guest_l0_list, guestTolo !== "" ? guestTolo : ""]);
      }
    }
    loInputRef.current.value = "";
    guestInputRef.current.value = "";
    setGuestId({ name: "", id: "" });
    setLoID({ name: "", id: "" });
    setGuestToLo(undefined);

    setSelectionRow([]);
    setSelectionRowTable2([]);
  };

  const AssignLo = () => {
    let pair_guest_ids = [];
    pair_guest_ids = pair_guest_list.map((e) => e.id);
    let pair_lo_ids = [];
    pair_lo_ids = pair_lo_list.map((e) => e.id);

    axios
      .post(
        `${BASE_URL}/los/updateLos`,
        {
          loList: pair_lo_ids,
          guestList: pair_guest_ids,
          guestLoList: pair_guest_l0_list,
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((res) => {
        console.log(res.data);
        parentHandler();
        alert("LO Assigned successfully")
        childCaller("Lo_assigned");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let userCheck;
  let check;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    check =
      ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
      ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true) ||
      ("LoAdmin" in userCheck && userCheck.LoAdmin === true)
        ? true
        : false;
  } catch (error) {
    navigate("/");
  }

  return (
    <MDBModal
      show={modalStatus}
      tabIndex="-1"
      onHide={() => {
        parentHandler();
      }}
    >
      <MDBModalDialog size="xl" style={{ minWidth: "1440px" }}>
        <MDBModalContent>
          <MDBModalHeader>
            <div className="MDB_Modal_Header_Wrapper">
              <MDBModalTitle>LINK GUEST AND LOs</MDBModalTitle>

              <div className="modal_header_Tooltip_Holder">
                <CustomToolTip title="Assign Lo a Guest">
                  <button
                    className="application_base_btn update_header_bars_btn"
                    onClick={AssignLo}
                    disabled={
                      check && pair_guest_l0_list.length !== 0 ? false : true
                    }
                  >
                    Assign
                  </button>
                </CustomToolTip>

                  <CustomToolTip title="Make a Guest,Lo pair">
                    <button
                      className="application_base_btn update_header_bars_btn"
                      disabled={pair_guest_l0_list.length === 10 ? true : false}
                      onClick={PairItems}
                    >
                      Pair
                    </button>
                  </CustomToolTip>

                  <button
                    disabled={pair_guest_l0_list.length >= 1 ? false : true}
                    className="application_base_btn update_header_bars_btn"
                    onClick={() => {
                      let tempPair1 = pair_guest_l0_list.slice(
                        0,
                        pair_guest_l0_list.length - 1
                      );
                      let tempPair2 = pair_guest_list.slice(
                        0,
                        pair_guest_list.length - 1
                      );
                      let tempPair3 = pair_lo_list.slice(
                        0,
                        pair_lo_list.length - 1
                      );
                      setGuestLoList(tempPair1);
                      setGuestList(tempPair2);
                      setLoList(tempPair3);
                    }}
                  >
                    Undo Pair
                  </button>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  style={{ margin: 0 }}
                  onClick={() => {
                    parentHandler();
                  }}
                ></MDBBtn>
              </div>
            </div>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="modal_body_pair_display_wrapper_Div">
              <div className="modal_body_pair_display_holder">
                {pair_guest_l0_list.map((e, index) => {
                  return (
                    <PairDisplay
                      key={"LoPairGuestDisplayTab" + index}
                      index={index}
                      pairOne={pair_guest_list[index].name}
                      pairTwo={pair_lo_list[index].name}
                    />
                  );
                })}
              </div>
            </div>

            <MDBRow className="gx-0">
              <MDBCol size={6} className="px-3">
                <MDBRow>
                  <MDBInput
                    label="Select Liaison Officer"
                    value={loId.name}
                    ref={loInputRef}
                    onChange={(e) => {
                      handleLOEntry(e.target.value);
                    }}
                  />
                </MDBRow>
                <MDBRow>
                  <Box
                    sx={loAssignStyle}
                  >
                    <DataGrid
                      rows={los.map((lo, index) => {
                        return {
                          id: lo.id,
                          name: lo.data.name,
                          contacts: lo.data.contacts[0],
                          numberOfGuests: lo.data.guests
                            ? lo.data.guests.length
                            : 0,
                        };
                      })}
                      getCellClassName={(params) => {
                        if (params.row.numberOfGuests <= 3) {
                          return "super-app-theme--free";
                        } else if (
                          params.row.numberOfGuests > 3 &&
                          params.row.numberOfGuests <= 7
                        ) {
                          return "super-app-theme--default";
                        } else if (params.row.numberOfGuests < 10) {
                          return "super-app-theme--crowded";
                        } else {
                          return "super-app-theme--closed";
                        }
                      }}
                      columns={lo_columns}
                      slots={{ toolbar: CustomToolBar }}
                      sx={tableStyle}
                      rowSelectionModel={selectionRowsTable2}
                      onRowSelectionModelChange={setSelectionRowTable2}
                    />
                  </Box>
                </MDBRow>
              </MDBCol>
              <MDBCol size={6} className="px-3">
                <MDBRow>
                  <MDBInput
                    label="Select Guest"
                    value={guestId.name}
                    ref={guestInputRef}
                    onChange={(e) => handleGuestEntry(e.target.value)}
                  />
                </MDBRow>
                <MDBRow>
                  <Box
                    sx={{
                      width: "100%",
                      height: "60vh",
                      padding: "0",
                      paddingTop: "5px",
                    }}
                  >
                    <DataGrid
                      rows={guests.map((guest, index) => {
                        return {
                          id: guest.id,
                          name: guest.name,
                          assign:guest.lo,
                          assignLo: guest.lo ? los.filter((lo) => {
                            if(lo.id === guest.lo) {
                              return lo
                            }
                          })[0].data.name : "Not Assigned",
                          designation: guest.designation,
                          party: guest.party,
                          category: guest.category,
                          constituency: guest.constituency,
                          event_id: guest.event_id,
                        };
                      })}
                      getRowClassName={(params) => {
                        return params.indexRelativeToCurrentPage % 2 === 0
                          ? "mui_grid_table_even_row"
                          : "mui_grid_table_odd_row";
                      }}
                      rowSelectionModel={selectionRows}
                      onRowSelectionModelChange={setSelectionRow}
                      columns={guest_columns}
                      slots={{ toolbar: CustomToolBar }}
                      sx={tableStyle}
                    />
                  </Box>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <Snackbar open={doublePair} autoHideDuration={3000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                Cannot pair same entites again
              </Alert>
            </Snackbar>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}