import React, { useState } from "react";
import { useContext } from "react";
import './sidebar.css'
import logo from "../Assets/logo.png";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { useNavigate } from "react-router-dom";
import { app } from "../FirebaseConfig";
import { getAuth, signOut } from "firebase/auth";
import { NavLink } from "react-router-dom";
import { Context } from "../MainPage";

const Sidebar = () => {
  const auth = getAuth(app);
  const {pendingCount} = useContext(Context)
  const [pendingBg,setPendingBg] = useState('')

  const navigate = useNavigate();
  const logout = () => {
    signOut(auth);
    navigate("/");
    sessionStorage.clear();
    localStorage.clear();
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "scroll initial",
      }}
    >
      <CDBSidebar 
      textColor="var(--application-base-theme-primary-color)" backgroundColor="#f0f0f0">
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
          <div
            className="container"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              onClick={() => {
                navigate("/dashboard");
              }}
              src={logo}
              alt=""
              style={{ width: "100px", height: "100px", cursor: "pointer" }}
            />
          </div>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                isActive ? "activeClicked" : "none"
              }>
              <CDBSidebarMenuItem icon="columns" className="sidebar-menu-item">
                Dashboard
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              to="/guests"
              className={({ isActive }) =>
                isActive ? "activeClicked" : "none"
              }
            >
              <CDBSidebarMenuItem icon="users" className="sidebar-menu-item">Guests</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              to="/los"
              className={({ isActive }) =>
                isActive ? "activeClicked" : "none"
              }
            >
              <CDBSidebarMenuItem icon="user" className="sidebar-menu-item">LOs</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              to="/vehicles"
              className={({ isActive }) =>
                isActive ? "activeClicked" : "none"
              }
            >
              <CDBSidebarMenuItem icon="car-side" className="sidebar-menu-item">Vehicles</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              to="/hotels"
              className={({ isActive }) =>
                isActive ? "activeClicked" : "none"
              }
            >
              <CDBSidebarMenuItem icon="hotel" className="sidebar-menu-item">Hotels</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              to="/sessions"
              className={({ isActive }) =>
                isActive ? "activeClicked" : "none"
              }
            >
              <CDBSidebarMenuItem icon="book" className="sidebar-menu-item">Sessions</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              to="/travelItinerary"
              className={({ isActive }) =>
                isActive ? "activeClicked" : "none"
              }
            >
              <CDBSidebarMenuItem icon="ticket" className="sidebar-menu-item">Media</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              to="/requests"
              className={({ isActive }) => {
                if(isActive){
                  setPendingBg('#FF0000')
                  return "activeClicked"
                }else{
                  setPendingBg('#A30000')
                  return "none"
                }
              } }
            >
              <CDBSidebarMenuItem 
              icon="mail-bulk" 
              className="sidebar-menu-item"
              >
                <div className="d-flex">
                  <div>Requests</div>
                  {pendingCount === 0 ? <></> : 
                  <>
                    <div className={`ms-4 me-4 ps-2 pe-2 rounded-circle text-white ${pendingCount === '' ? 'd-none' : 'd-block'}`}
                    style={{backgroundColor: `${pendingBg}`}}>
                      {pendingCount}
                    </div>
                  </>}
                  
                </div>
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              to="/contacts"
              className={({ isActive }) =>
                isActive ? "activeClicked" : "none"
              }
            >
              <CDBSidebarMenuItem icon="address-book" className="sidebar-menu-item">Contacts</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: "center" }}>
          <div>
            <button
              style={{
                backgroundColor: "var(--application-base-theme-primary-color)",
                color: "var(--application-base-theme-secondary-color)",
                width: "100%",
                padding: "10px",
                border:"None",
                fontWeight:"bold"
              }}
              onClick={logout}
            >
              {" "}
              Logout{" "}
            </button>
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
