import React, { useState } from 'react'
import './HotelsDesc.css'

const HotelSemiDesc = ({ hotels }) => {
  const TotalHotel = hotels.length;
  const availableHotels = hotels.filter((hotel) => hotel.inventory[0] > 0)
  const reservedHotels = hotels.filter((hotel) => hotel.inventory[1] > 0)
  const totalRoomsAvailable = availableHotels.reduce(
    (total, hotel) => total + hotel.inventory[0], 0
  );

  return (
    <div className='application_semi_description_bar_wrapper row g-0'>
      <div className='col col-6'>Total Number Of Hotels: {TotalHotel} </div>
      <div className='col col-6' >Number Of Hotels with Available rooms: {availableHotels.length} </div>
      <div className='col col-6'>Number Of Hotels with Reserved rooms: {reservedHotels.length} </div>
      <div className='col col-6'>Total Number Of rooms Available: {totalRoomsAvailable} </div>
    </div>
  )
}

export default HotelSemiDesc