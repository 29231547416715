import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
// import Sidebar from "../../Components/Sidebar";
import "../Dashboard.css";
import { app } from "../../FirebaseConfig";
import { Outlet } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
// import RightSidebar from "../../Components/RightSidebar";
// import { MDBBadge } from "mdb-react-ui-kit";
import HotelsDesc from "./HotelsDesc";
import { CircularProgress } from "@mui/material";
import ScreenLoader from "../ScreenLoader";

import { Box, Button } from "@mui/material";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF, GridRowModes } from "@mui/x-data-grid";
import CustomToolBar from "../../Components/MuiToolBar/CustomToolBar";
import HotelSemiDesc from "./HotelSemiDesc";
import { Context } from "../../MainPage";
import { Input } from "@mui/material";
import CustomToolTip from "../../Components/ToolTip/CustomToolTip";
import { MDBIcon, MDBCol } from "mdb-react-ui-kit";
import { tableStyle } from "../../Static/Styles";
import Editicon from "../../Assets/edit.svg";
import Doneicon from "../../Assets/done.svg";
import FamilyRoomsModal from "../HotelPages/FamilyRoomsModal";
import axios from "axios";

const Hotels = () => {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const auth = getAuth(app);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  // State to store hotels
  // const [hotel, setHotel] = useState(useContext(Context));
  const { hotelapi, childCaller } = useContext(Context);
  const [hotels, setHotels] = useState(hotelapi);
  const [searchTerm, setSearchTerm] = useState("");

  const [editBoolean, setEditBoolean] = useState(false);
  const [hotelId, setHotelId] = useState("");

  const [FamRomms, setFamRooms] = useState(false);

  const goToHotelDescription = (hotel) => {
    navigate(`/hotelDesc/${hotel.value.id}`, { state: hotel.value });
  };

  const handleDone = (params) => {
    setEditBoolean(false);
    axios.post(`${BASE_URL}/hotels/updateSoloHotel`, {
      data: {
        id: params.id,
        category: params.row.category,
        contacts: [params.row.phone_no, params.row.email],
        name: params.row.name,
        rating : params.row.grading,
      }
    }, {
      headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
    })
    .then((response) => {
      alert("Hotel Updated Successfully");
    })
    .catch((err) => {
      alert("Could not Update Hotel");
    })
  }

  // search function
  const handleAdanvedSearch = (e) => {
    e.preventDefault()
    if (searchTerm.trim().length === 0) {
      setHotels(hotelapi);
    } else {
      let tempList = hotelapi;
      let filteration = tempList.filter((e) => {
        return (
          e.name
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim()) ||
          e.contacts[0]
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim()) ||
          e.category
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim()) ||
          e.rating === searchTerm
        );
      });
      if (filteration.length === 0) {
        alert("No Rows Found");
      } else {
        setHotels(filteration);
      }
    }
  };

  const handleAdancedClear = () => {
    setHotels(hotelapi);
    setSearchTerm("");
    childCaller("Hotels");
  };

  useEffect(() => {
    if (user) {
      if (user) {
        user
          .getIdTokenResult()
          .then(async (e) => {
            await sessionStorage.setItem("Auth_Token", JSON.stringify(e));
            await sessionStorage.setItem(
              "User_Permission",
              JSON.stringify(e.claims)
            );
          })
          .catch((e) => console.log(e));
      }
    }

    let uuid = sessionStorage.getItem("UUID");
    if (uuid === null) {
      let token = JSON.parse(sessionStorage.getItem("Auth_Token"));
      if (token !== null) {
        uuid = token.claims.user_id;
      }
    }

    setHotels(hotelapi);
  }, [user, loading, navigate, hotelapi]);

  let userCheck;
  const [check,setCheck] = useState(false);

  useEffect(()=>{
    try {
      userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
      setCheck(
      ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
      ("HotelAdmin" in userCheck && userCheck.HotelAdmin === true)
        ? true
        : false)

        console.log(
          ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
          ("HotelAdmin" in userCheck && userCheck.HotelAdmin === true)
            ? true
            : false);
    } catch (error) {
      navigate("/");
    }
  },[])

  return (
    <>
      <>
        <Outlet />
        {hotels.length === 0 ? (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div
              style={{
                flex: "1 1 auto",
                display: "flex",
                flexFlow: "column",
                height: "100vh",
                overflowY: "hidden",
              }}
            >
              <div className="container-fluid">
                <div className="w-100 ">
                  <div className="d-flex justify-content-between">
                    <h3 className="fw-bold my-3 page_header_title_h3">
                      Hotels
                    </h3>
                    <CustomToolTip title="Assign Family Rooms">
                          <button
                            className="m-2"
                            style={{
                              height: "40px",
                              border: "none",
                              backgroundColor: "#222",
                              color: "white",
                              padding: "0.5rem 1rem",
                              borderRadius: "5px"
                            }}
                            onClick={()=>setFamRooms(true)}
                          >Assign Family Rooms</button>
                        </CustomToolTip>
                  </div>
                 
                  <HotelSemiDesc hotels={hotels} />
                </div>
              </div>
              {/* <NavBar register={false} doco_logo={false} ridex={true} ridex_logo={true} /> */}
              {loader ? (
                <div className="min-w-100 min-h-100 d-flex justify-content-center align-items-center m-auto">
                  <ScreenLoader />
                </div>
              ) : (
                <>
                <form onSubmit={handleAdanvedSearch}>
                  <div className="hotel_searchBar">
                    <div className="application_page_search_bar_wrapper">
                      <button
                      type="submit"
                        onClick={handleAdanvedSearch}
                        className="application_base_btn btn_with_logo application_refresh_icon"
                      >
                        <MDBIcon
                          fas
                          icon="search"
                          size="md"
                          onClick={handleAdanvedSearch}
                        />
                        <div>Search</div>
                      </button>

                      <input
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        type="text"
                        className="application_page_search_bar"
                        placeholder="Search"
                      />

                      <button
                      type="button"
                        onClick={handleAdancedClear}
                        className="application_base_btn btn_with_logo application_refresh_icon"
                      >
                        <MDBIcon
                          fas
                          icon="sync-alt"
                          size="md"
                          style={{
                            color: "white",
                          }}
                        />
                        <div>Refresh</div>
                      </button>
                    </div>
                  </div>
                  </form>
                  <div>
                    <div>
                      <div className="ms-3 me-3 flex-grow-1 d-flex flex-column">
                        <Box
                          sx={{
                            height: 520,
                            width: "79.8vw",
                            margin: "auto",
                          }}
                          className="flex-grow-1"
                        >
                          <DataGrid
                          editMode="row"
                            columns={[
                              {
                                ...GRID_CHECKBOX_SELECTION_COL_DEF,
                                width: 55,
                                headerClassName: "prime_table_header_tab",
                                headerAlign: "center",
                              },
                              {
                                field: 'edit',
                                headerName: '',
                                headerClassName: "prime_table_header_tab",
                                width: 150,
                                headerAlign: 'center',
                                renderCell: (params) => {

                                  return (
                                    <>
                                      {editBoolean && hotelId === params.id ? (
                                        <div>
                                        <button
                                          style={{
                                            border: "none",
                                            padding: "0",
                                            backgroundColor: "transparent",
                                            margin: "10px",
                                          }}
                                        >
                                          <i
                                            className="trashIcon fas fa-times fa-lg"
                                            onClick={() => {
                                              setEditBoolean(false);
                                              params.api.stopRowEditMode({ id: params.id })
                                            }}
                                          ></i>
                                        </button>
                                        <img
                                          src={Doneicon}
                                          alt=""
                                          onClick={() => {
                                            if(check === true)
                                              handleDone(params);
                                              params.api.stopRowEditMode({ id: params.id })
                                          }}
                                        />
                                        </div>
                                      ) : (
                                        <div>
                                        <button
                                          style={{
                                            border: "none",
                                            padding: "0",
                                            backgroundColor: "transparent",
                                            margin: "10px",
                                          }}
                                        >
                                          {/* <i
                                            class="trashIcon fa-sharp fa-solid fa-trash"
                                            // onClick={() => handleDeleteRowModal(params.row)}
                                          ></i> */}
                                        </button>
                                        <img
                                          src={Editicon}
                                          alt=""
                                          onClick={() => {
                                            setHotelId(params.id);
                                            setEditBoolean(true);
                                            params.api.startRowEditMode({ id: params.id });
                                          }}
                                        />
                                        </div>
                                      )}
                                    </>
                                  );
                                },
                              },
                              {
                                field: "name",
                                headerClassName: "prime_table_header_tab",
                                headerName: "Name",
                                width: 150,
                                headerAlign: "center",
                                editable: true,
                              },
                              {
                                field: "category",
                                heacategory: "Category",
                                headerClassName: "prime_table_header_tab",
                                headerName: "Category",
                                width: 150,
                                headerAlign: "center",
                                editable: true,
                              },
                              {
                                field: "grading",
                                headerName: "Grading",
                                headerClassName: "prime_table_header_tab",
                                width: 150,
                                headerAlign: "center",
                                editable: true,
                              },
                              {
                                field: "phone_no",
                                headerName: "Phone No",
                                headerClassName: "prime_table_header_tab",
                                width: 150,
                                headerAlign: "center",
                                editable: true,
                              },
                              {
                                field: "total",
                                headerName: "Total",
                                headerClassName: "prime_table_header_tab",
                                width: 150,
                                headerAlign: "center",
                              },
                              {
                                field: "available",
                                headerName: "Available",
                                headerClassName: "prime_table_header_tab",
                                width: 150,
                                headerAlign: "center",
                              },
                              {
                                field: "reserved",
                                headerName: "Reserved",
                                headerClassName: "prime_table_header_tab",
                                width: 150,
                                headerAlign: "center",
                              },
                              {
                                field: "occupied",
                                headerName: "Occupied",
                                headerClassName: "prime_table_header_tab",
                                width: 150,
                                headerAlign: "center",
                              },
                              {
                                field: "locked",
                                headerClassName: "prime_table_header_tab",
                                headerName: "Locked",
                                width: 150,
                                headerAlign: "center",
                              },
                              {
                                field: "description",
                                headerClassName: "prime_table_header_tab",
                                headerName: "Description",
                                width: 150,
                                headerAlign: "center",
                                disableExport: true,
                                filterable: false,
                                renderCell: (cellValues) => {
                                  return (
                                    <button
                                      className="application_base_btn table_btn_padding"
                                      disabled={!check}
                                      onClick={() =>
                                        goToHotelDescription(cellValues)
                                      }
                                    >
                                      Description
                                    </button>
                                  );
                                },
                              },
                            ]}
                            checkboxSelection
                            rows={hotels.map((hotel, index) => {
                              return {
                                hotel_id: hotel.id,
                                name: hotel.name,
                                category: hotel.category,
                                grading: hotel.rating,
                                phone_no: hotel.contacts === undefined ? "No Contact" : hotel.contacts[0],
                                total:
                                  hotel.inventory[0] +
                                  hotel.inventory[1] +
                                  hotel.inventory[2] +
                                  hotel.inventory[3],
                                available: hotel.inventory[0],
                                reserved: hotel.inventory[1],
                                occupied: hotel.inventory[2],
                                locked: hotel.inventory[3],
                                description: hotel,
                              };
                            })}
                            getRowId={(row) => row.hotel_id}
                            slots={{ toolbar: CustomToolBar }}
                            getRowClassName={(params) => {
                              return params.indexRelativeToCurrentPage % 2 === 0
                                ? "mui_grid_table_even_row"
                                : "mui_grid_table_odd_row";
                            }}
                            sx={tableStyle}
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {FamRomms && <FamilyRoomsModal key={"FamRomms Modal"}
              parentHandler={setFamRooms}/>}
            </div>
          </>
        )}
      </>
    </>
  );
};

export default Hotels;
