import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { mediaStatesStyle } from "../../Static/Styles";

const MediaForSessions = ({ url, mediaFile }) => {

  const BASE_URL = process.env["REACT_APP_BASE_URL"]

  const [subjects, setSubjects] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [session, setSession] = useState({
    session_id: "",
    session_name: "",
  });
  const [sessionsBoolean, setSessionsBoolean] = useState(true);
  const [subjectID, setSubjectID] = useState("");

  const [file, setFile] = useState("");

  const getSubjects = () => {
    axios
      .get(BASE_URL+"/subjects/allSubjects", {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
      })
      .then((response) => {
        setSubjects(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSessions = (subjectId) => {
    setSubjectID(subjectId);
    if (sessionsBoolean === true) {
      axios
        .get(`${BASE_URL}/sessions/allsessions/${subjectId}`, {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        })
        .then((session) => {
          setSessions(session.data);
          console.log(session.data);
          setSessionsBoolean(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (sessionsBoolean === false) {
      setSessionsBoolean(true);
    }
  };

  const addMediaForSessions = () => {
    console.log(subjectID);
    console.log(url);
    axios.post(`${BASE_URL}/media/uploadMedia`, {
      uuid: [],
      url: url,
      states: [],
      type: "sessions",
      sessionId: session.session_id,
      subjectId: subjectID,
      file_type: mediaFile.type !== "video/mp4" ? "file" : mediaFile.type,
    })
    .then((response) => {
      console.log(response.data);
      alert("Media Uploaded Successfully");
    })
    .catch((err) => {
      console.log(err);
    })
  };

  useEffect(() => {
    getSubjects();
  }, []);

  return (
    <>
      <div>
        <h5>Selected Session: {session.session_name}</h5>
        {subjects.map((subject) => {
          return (
            <>
              <div key={subject.id}>
                <p
                  style={{
                    cursor: "pointer",
                    border: "1px solid rgb(235, 235, 224)",
                    borderRadius: "5px",
                    padding: "4px",
                  }}
                  onClick={() => getSessions(subject.id)}
                >
                  {subject.title}
                </p>
              </div>
              {subjectID === subject.id && (
                <>
                  {sessionsBoolean ? null : (
                    <>
                      <Box
                        sx={{
                          height: 270,
                          maxWidth: 400,
                          margin: "auto",
                          marginBottom: "15px",
                        }}
                      >
                        <DataGrid
                          columns={[
                            {
                              field: "session_name",
                              headerName: "Session Name",
                              headerClassName: "super-app-theme--header",
                              width: 400,
                              renderCell: (cellValues) => {
                                return <p style={{ cursor: "pointer" }} onClick={() => {
                                  setSession((prev) => {
                                    return {...prev, session_id: cellValues.row.id};
                                  })
                                  setSession((prev) => {
                                    return {...prev, session_name: cellValues.row.session_name};
                                  })
                                }}>{cellValues.row.session_name}</p>;
                              },
                            },
                          ]}
                          rows={sessions?.map((session) => {
                            return {
                              id: session.id,
                              session_name: session.name,
                            };
                          })}
                          getRowClassName={(params) => {
                            return params.indexRelativeToCurrentPage % 2 === 0
                              ? "mui_grid_table_even_row"
                              : "mui_grid_table_odd_row";
                          }}
                          sx={mediaStatesStyle}
                        />
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          );
        })}
        <button
          style={{
            paddingLeft: "25px",
            paddingRight: "25px",
            marginTop: "55px",
            float: "right",
          }}
          className="btn btn-dark"
          onClick={addMediaForSessions}
        >
          Export
        </button>
      </div>
    </>
  );
};

export default MediaForSessions;
