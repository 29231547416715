import React, { useState } from 'react';
import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from "axios";
import {tableStyle} from '../../Static/Styles';

import "./Sessions.css";
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';

const AddSession = ({ subjects, handleCloseSession }) => {

    const BASE_URL = process.env["REACT_APP_BASE_URL"]

    const [subjectId, setSubjectId] = useState("");
    const [name, setName] = useState("");
    const [venue, setVenue] = useState("");
    const [totalSeats, setTotalSeats] = useState(0);
    const [lockedSeats, setLockedSeats] = useState(0);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const addSession = (e) => {
        e.preventDefault();
        axios.post(`${BASE_URL}/sessions/createSession/${subjectId}`, {
          name: name,
          venue: venue,
          total_seats: totalSeats,
          locked_seats: lockedSeats,
          timings: [startTime, endTime],
          guests: [],
        }, {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
        })
        .then((response) => {
          console.log(response.data);
          alert("Session Added Successfully");
        })
        .catch((err) => {
          console.log(err);
        })
    }

  return (
    <>
        <form onSubmit={addSession} className="add-session-form">
          <div className="close-btn">
          <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseSession}></button>
          </div>
            <h5>Add Session</h5>
            <label>Subject</label>
            <Box
              sx={{
                height: 300,
              }}
            >
              <DataGrid
                columns={[
                  {
                    field: "subject_id", 
                    headerName: "Subject ID", 
                    headerAlign : "center",
                    flex:0.5,
                    headerClassName: "prime_table_header_tab",
                    width: 300, 
                    renderCell: (cellValues) => {
                      return <button 
                      className="application_base_btn btn_with_logo table_btn_padding"
                      onClick={() => setSubjectId(cellValues.row.subject_id)}>
                        Select Subject
                      </button>}},
                  {
                    field: "subject_name", 
                    headerName: "Subject Name",
                    flex : 0.5,
                    headerClassName: "prime_table_header_tab",
                    headerAlign : "center", 
                    width: 450
                  },
                ]}
                sx={tableStyle}
                rows={
                  subjects.map((subject) => {
                    return {
                      subject_id: subject.id,
                      subject_name: subject.title
                    }
                  })
                }

                getRowId={(row) => row.subject_id}
              />
            </Box>
            <MDBRow>
              <MDBCol size={6}>
                <MDBRow>
                  <label>Name</label>
                  <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                </MDBRow>
              </MDBCol>

              <MDBCol size={6}>
                <MDBRow>
                  <label>Venue</label>
                  <input type="text" placeholder="Venue" value={venue} onChange={(e) => setVenue(e.target.value)} />      
                </MDBRow>
              </MDBCol>

              <MDBCol size={6}>
                <MDBRow>
                  <label>Total Seats</label>
                  <input type="number" placeholder="Total Seats" value={totalSeats} onChange={(e) => setTotalSeats(e.target.value)} />
                </MDBRow>
              </MDBCol>

              <MDBCol size={6}>
                <MDBRow>
                  <label>Locked Seats</label>
                  <input type="number" placeholder="Locked Seats" value={lockedSeats} onChange={(e) => setLockedSeats(e.target.value)} />
                </MDBRow>
              </MDBCol>

              <MDBCol size={6}>
                <MDBRow>
                  <label id="timing-label">From Time</label>
                  <input type="datetime-local" placeholder="Start Time" onChange={(e) => setStartTime(e.target.value)} />
                </MDBRow>
              </MDBCol>

              <MDBCol size={6}>
                <MDBRow>
                  <label id="timing-label">To Time</label>
                  <input type="datetime-local" placeholder="End Time" onChange={(e) => setEndTime(e.target.value)} />
                </MDBRow>
              </MDBCol>

              <MDBCol size={12}>
                <MDBRow>
                  <input id="add-subject-button" type="submit" value="Add Session" />
                </MDBRow>
              </MDBCol>
            </MDBRow>
        </form>
    </>
  )
}

export default AddSession;
