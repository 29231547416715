import React from 'react'

import { GridToolbarContainer,GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport,GridToolbarDensitySelector
} from '@mui/x-data-grid';
import CustomToolTip from '../ToolTip/CustomToolTip';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
export default function CustomToolBar(props){

    return (
        <>
        <GridToolbarContainer>
            <CustomToolTip title="Filter using Columns Of Table" >
                <GridToolbarFilterButton 
                componentsProps={{
                    button: {
                      startIcon: (
                        <IconButton>
                          <SearchIcon sx={{color : "var(--application-base-theme-primary-color)"}} />
                        </IconButton>
                      )
                    }
                  }}
                />
            </CustomToolTip>
            <CustomToolTip title="Hide Columns of table">
                <GridToolbarColumnsButton />
            </CustomToolTip>
            <CustomToolTip title="Export below table in CSV format">
                <GridToolbarExport />
            </CustomToolTip>
            <CustomToolTip title="Change Row Size">
                <GridToolbarDensitySelector />
            </CustomToolTip>
        </GridToolbarContainer>
        </>
    )
}