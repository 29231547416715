import React, { useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalFooter,
  MDBModalHeader,
  MDBBtn,
  MDBModalBody,
} from "mdb-react-ui-kit";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { CircularProgress } from "@mui/material";
import { Snackbar, Alert } from "@mui/material";
import { app } from "../../FirebaseConfig";
import axios from "axios";

export default function ImageModal(props) {
  const [toggleShow, setToggleShow] = useState(true);
  const [file,setFile] = useState();
  const [image, setImage] = useState(null);
  const [upload, setload] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [storeFolders, setStoreFolders] =useState(props.typeImage);
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const handleImageSelected = (e) => {
    setFile(e)
    setImage(URL.createObjectURL(e));
  };
  const parentNoHandler = () => {
    props.onClose();
  };

  const UploadFile = () => {
    setUploaded(true);
    if (image === null) {
      setload(true);
      setUploaded(false);
    } else {
      let storage = getStorage(app);
      let ticketMetaData = { contentType: file.type };

      const travelIdDocRef = ref(storage, storeFolders + "s/" + file.name);
      const uploadTravelIdDocTask = uploadBytesResumable(
        travelIdDocRef,
        file,
        ticketMetaData
      );
      uploadTravelIdDocTask.on(
        "state_changed",
        (snapshot) => {
          console.log("Upload");
        },
        (error) => {
          console.log(error);
        },
        () => {
            console.log("Uploaded")
          getDownloadURL(uploadTravelIdDocTask.snapshot.ref).then(
            (downloadURL) => {
              axios
                .post(
                  BASE_URL + "/guests/updateProfilePhoto",
                  {
                    entity_id: props.object.id,
                    type_guest: props.typeImage,
                    photo_link: downloadURL,
                  },
                  {
                    headers: {
                      FirebaseToken: sessionStorage.getItem("Auth_Token"),
                    },
                  }
                )
                .then((res) => {
                  props.onClose();
                  setToggleShow(!toggleShow);
                  alert("Profile Photo Updated");
                })
                .catch((e) => {
                  props.onClose();
                  setToggleShow(!toggleShow);
                });
            }
          );
        }
      );
    }
  };

  return (
    <MDBModal show={toggleShow} setShow={setToggleShow} tabIndex="-1" 
    onHide={() => {
          parentNoHandler();
        }}>
      <MDBModalDialog size="xl">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Upload / Update Profile Picture</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => {
                props.onClose();
                setToggleShow(!toggleShow);
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="row g-0">
              <div className="col col-6">
                <img
                  src={image !== null ? image : props.object.image}
                  alt="Profile_Photo_Profile"
                  className="application_poster_size_image"
                />
              </div>
              <div className="col col-6">
                <div className="image_uploader_div">
                  <div className="upload-div">
                    <input
                      type="file"
                      className="form-control uploadFileSize select-file-input"
                      onChange={(e) => {
                        handleImageSelected(e.target.files[0]);
                      }}
                      accept="image/png, image/gif, image/jpeg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            {uploaded ? (
              <CircularProgress />
            ) : (
              <button
                className="application_base_btn table_btn_padding"
                onClick={UploadFile}
              >
                Replace Profile Photo
              </button>
            )}
          </MDBModalFooter>
        </MDBModalContent>
        <Snackbar
          open={upload}
          autoHideDuration={4000}
          onClose={() => {
            setload(false);
          }}
        >
          <Alert
            onClose={() => {
              setload(false);
            }}
            severity="warning"
            sx={{ width: "100%" }}
          >
            Not Image Selected
          </Alert>
        </Snackbar>
      </MDBModalDialog>
    </MDBModal>
  );
}
