/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./Requests.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { app } from "../../FirebaseConfig";
import { getAuth } from "firebase/auth";
import Pending from "./Pending";
import Accepted from "./Accepted";
import Completed_Rejected from "./Completed_Rejected";
import { MDBIcon } from "mdb-react-ui-kit";
import { Context } from "../../MainPage";

export default function RequestPage() {
  const [value, setValue] = useState("1");
  const auth = getAuth(app);
  const [user, loading, error] = useAuthState(auth);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isRequestsLoading, setIsRequestsLoading] = useState(true);
  const { loapi, guestapi, requests } = useContext(Context);

  const [pendingRequest, setPendingRequest] = useState([]);
  const [approvedRequest, setApprovedRequest] = useState([]);
  const [completedRequest, setCompletedRequest] = useState([]);
  const [rejectedRequest, setRejectedRequest] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [tempRejectedRequest, setTempRejectedRequest] = useState(
    rejectedRequest
  );
  const [tempCompletedRequest, setTempCompletedRequest] = useState(
    completedRequest
  );
  const [tempPendingRequest, setTempPendingRequest] = useState(pendingRequest);
  const [tempApprovedRequest, setTempApprovedRequest] = useState(
    approvedRequest
  );
  
  const requestsReduce = (uuid, newRequests) => {

    let [ppr, par, pcr, prr] = newRequests.reduce(
      ([a, b, c, e], ele) => {
        if (ele.status === "Pending") {

          return [[...a, ele], b, c, e];
        } else if (ele.status === "Accepted" && ele.admin === uuid) {
          return [a, [...b, ele], c, e];
        } else if (ele.status === "Completed" && ele.admin === uuid) {
          return [a, b, [...c, ele], e];
        } else if (ele.status === "Rejected" && ele.admin === uuid) {
          return [a, b, c, [...e, ele]];
        } else {
          return [a, b, c, e];
        }
      },
      [[], [], [], []]
    );
    
    setPendingRequest(ppr);

    // Sorting Based on Priority (updates in same object ppr);
    ppr.sort((a, b) => {
      return a.priority - b.priority;
    });
    
    setApprovedRequest(par);
    setCompletedRequest(pcr);
    setRejectedRequest(prr);
    setIsRequestsLoading(false);

    setTempPendingRequest(ppr);
    setTempApprovedRequest(par);
    setTempCompletedRequest(pcr);
    setTempRejectedRequest(prr);

  };

  useEffect(() => {
    if (user) {
      if (user) {
        user
          .getIdTokenResult()
          .then(async (e) => {
            await sessionStorage.setItem("Auth_Token", JSON.stringify(e));
            await sessionStorage.setItem(
              "User_Permission",
              JSON.stringify(e.claims)
            );
          })
          .catch((e) => console.log(e));
      }
    }

    let uuid = JSON.parse(sessionStorage.getItem("Auth_Token")).claims.user_id;
    if (uuid === null) {
      let token = JSON.parse(sessionStorage.getItem("Auth_Token"));
      if (token !== null) {
        uuid = token.claims.user_id;
      }
    }
    requestsReduce(uuid, requests);
  }, [requests]);

  useEffect(() => {
    getFilterList();
  }, [searchTerm]);

  const getFilterList = () => {
    // filters pendingList
    if (pendingRequest.length > 0) {
      const filterList = pendingRequest.filter((req) => {
        let sender_name = loapi.find((e) => {
          return e.id === req.sender;
        });
        if (typeof sender_name != "undefined")
          sender_name = sender_name.data.name;
        else sender_name = "LOName";

        let guest_name = guestapi.find((e) => e.id === req.guest);
        if (typeof guest_name != "undefined") guest_name = guest_name.name;
        else guest_name = "GuestName";

        return (
          sender_name
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim()) ||
          guest_name
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim()) ||
          req.title.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()) ||
          req.description.toLowerCase().trim().includes(searchTerm.toLowerCase().trim())
        );
      });
      setTempPendingRequest(filterList);
    }

    // filters approvedList
    if (approvedRequest.length > 0) {
      const filterList = approvedRequest.filter((req) => {
        let sender_name = loapi.find((e) => {
          return e.id === req.sender;
        });
        if (typeof sender_name != "undefined")
          sender_name = sender_name.data.name;
        else sender_name = "LOName";

        let guest_name = guestapi.find((e) => e.id === req.guest);
        if (typeof guest_name != "undefined") guest_name = guest_name.name;
        else guest_name = "GuestName";

        return (
          sender_name
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim()) ||
          guest_name
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim())||
            req.title.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()) ||
            req.description.toLowerCase().trim().includes(searchTerm.toLowerCase().trim())
        );
        //
      });
      setTempApprovedRequest(filterList);
    }

    // filters completedList
    if (completedRequest.length > 0) {
      const filterList = completedRequest.filter((req) => {
        let sender_name = loapi.find((e) => {
          return e.id === req.sender;
        });
        if (typeof sender_name != "undefined")
          sender_name = sender_name.data.name;
        else sender_name = "LOName";

        let guest_name = guestapi.find((e) => e.id === req.guest);
        if (typeof guest_name != "undefined") guest_name = guest_name.name;
        else guest_name = "GuestName";

        return (
          sender_name
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim()) ||
          guest_name
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim()) ||
            req.title.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()) ||
            req.description.toLowerCase().trim().includes(searchTerm.toLowerCase().trim())
        );
        //
      });
      setTempCompletedRequest(filterList);
    }

    // filters rejectList
    if (rejectedRequest.length > 0) {
      const filterList = rejectedRequest.filter((req) => {
        let sender_name = loapi.find((e) => {
          return e.id === req.sender;
        });
        if (typeof sender_name != "undefined")
          sender_name = sender_name.data.name;
        else sender_name = "LOName";

        let guest_name = guestapi.find((e) => e.id === req.guest);
        if (typeof guest_name != "undefined") guest_name = guest_name.name;
        else guest_name = "GuestName";

        return (
          sender_name
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim()) ||
          guest_name
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim()) ||
            req.title.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()) ||
            req.description.toLowerCase().trim().includes(searchTerm.toLowerCase().trim())
        );
        //
      });
      setTempRejectedRequest(filterList);
    }
  };

  const handleAdancedClear = () => {
    setSearchTerm("");
  };
  const handleAdanvedSearch = () => {};

  // Determining the request colour
  const determineRequestColour = (target) => {
    if(target === "Vehicle") {
      return "--application-request-tab-color-purple-Vehicle_Admin";
    }
    else if(target === "LO") {
      return "--application-request-tab-color-yellow-LO_Admin";
    }
    else if(target === "Session") {
      return "--application-request-tab-color-green-Session_Admin";
    }
    else if(target === "Hotel") {
      return "--application-request-tab-color-yellow-Hotel_Admin";
    }
    else if(target === "Guest") {
      return "--application-request-tab-color-pastel_Green-Guest_Admin";
    }
    else if(target === "Media") {
      return "--application-request-tab-color-purple-Media";
    }
    else if(target === "Emergency") {
      return "--application-request-tab-color-purple-Emergency";
    }
    // Media request colour and Emergency Request colour not provided yet
    // else if(target === "Request") {
    //   return "--application-request-tab-color-pink-Requests_Admin"
    // }
  }

  return (
    <div className="container-fluid">
      <div className="w-100">
        <h3 className="fw-bold my-3 page_header_title_h3">Requests</h3>
      </div>

      <div class="search-container ps-4 pe-4">
        <div className="application_page_search_bar_wrapper">
          <button
            onClick={handleAdanvedSearch}
            className="application_base_btn btn_with_logo application_refresh_icon"
          >
            <MDBIcon fas icon="search" size="md" />
            <div>Search</div>
          </button>

          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="text"
            className="application_page_search_bar"
            placeholder="Search"
          />

          <button
            onClick={handleAdancedClear}
            className="application_base_btn btn_with_logo application_refresh_icon"
          >
            <MDBIcon
              fas
              icon="sync-alt"
              size="md"
              style={{
                color: "white",
              }}
            />
            <div>Refresh</div>
          </button>
        </div>
      </div>
      <div class="main">
        <div className="request">
          <Box
            sx={{ width: "1221px", height: "80vh", overflow: "auto" }}
            className="box w-100"
          >
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  "& .MuiTab-root.Mui-selected": {
                    color: "#000000",
                  },
                }}
              >
                <TabList
                  selectionFollowsFocus
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#000000",
                    },
                  }}
                  onChange={handleChange}
                  centered
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Pending Requests"
                    value="1"
                    sx={{ fontFamily: "Poppins" }}
                  />
                  <Tab
                    label="Work In Progress"
                    value="2"
                    sx={{ fontFamily: "Poppins" }}
                  />
                  <Tab
                    label="Rejected Requests"
                    value="3"
                    sx={{ fontFamily: "Poppins" }}
                  />
                  <Tab
                    label="Completed Requests"
                    value="4"
                    sx={{ fontFamily: "Poppins" }}
                  />
                </TabList>
              </Box>
              {isRequestsLoading ? (
                <div className="circular-progress">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <TabPanel value="1" style={{ marginTop: 30 }}>
                    {tempPendingRequest.map((e) => {
                      return (
                        <Pending
                          item={e}
                          requestColour = {determineRequestColour(e.target)}
                          reduceFunc={requestsReduce}
                          key={"RequestCard_Item" + e.id}
                        />
                      );
                    })}
                  </TabPanel>
                  <TabPanel value="2">
                    {tempApprovedRequest.map((e) => {
                      return (
                        <Accepted
                          item={e}
                          requestColour = {determineRequestColour(e.target)}
                          reduceFunc={requestsReduce}
                          key={"RequestCard_Item" + e.id}
                        />
                      );
                    })}
                  </TabPanel>
                  <TabPanel value="3">
                    {tempRejectedRequest.map((e) => {
                      return (
                        <Completed_Rejected
                          item={e}
                          requestColour = {determineRequestColour(e.target)}
                          reduceFunc={requestsReduce}
                          key={"RequestCard_Item" + e.id}
                        />
                      );
                    })}
                  </TabPanel>
                  <TabPanel value="4">
                    {tempCompletedRequest.map((e) => {
                      return (
                        <Completed_Rejected
                          item={e}
                          requestColour = {determineRequestColour(e.target)}
                          reduceFunc={requestsReduce}
                          key={"RequestCard_Item" + e.id}
                        />
                      );
                    })}
                  </TabPanel>
                </>
              )}
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
}
