import React, { useState } from 'react';
import axios from "axios";

import "./Sessions.css";

const AddSubject = ({ handleCloseSubject }) => {

    const BASE_URL = process.env["REACT_APP_BASE_URL"]

    const [subjectTitle, setSubjectTitle] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const addSubject = (e) => {
        e.preventDefault();
        axios.post(BASE_URL+"/subjects/createSubject",
        {
            title: subjectTitle,
            timings: [startTime, endTime]
        }, {
            headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
        }
        )
        .then((response) => {
            console.log(response.data);
            alert('Subject Added Successfully');
        })
        .catch((err) => {
            console.log(err);
        })
    }

  return (
    <>
        <form onSubmit={addSubject} className="add-subject-form">
            <div className="close-btn">
                <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseSubject}></button>
            </div>
            <h5>Add Subject</h5>
            <label>Title</label>
            <input type="text" placeholder="Title" value={subjectTitle} onChange={(e) => setSubjectTitle(e.target.value)} />
            <label id="timing-label">Timings</label>
            <input type="datetime-local" placeholder="Start Time" onChange={(e) => setStartTime(e.target.value)} />
            <input type="datetime-local" placeholder="End Time" onChange={(e) => setEndTime(e.target.value)} />
            <input id="add-subject-button" type="submit" value="Add Subject" />
        </form>
    </>
  )
}

export default AddSubject
