import React, { useState, useEffect } from "react";
import axios from "axios";
import "./RightSidebar.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { app } from "../FirebaseConfig";

import { InputLabel, MenuItem, FormHelperText, FormControl, Select, Input} from "@mui/material";

import PendingRequests from './RequestsCards/PendingRequests';
import AcceptedRequests from './RequestsCards/AcceptedRequests';
import CompletedRequests from './RequestsCards/CompletedRequests';

const RightSidebar = () => {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];
  const [expanded, setExpanded] = useState(false);
  const [cardCount, setCardCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [cardType,setCardType] = useState("Pending");

  const [pendingRequest,setPendingRequest] = useState([]);
  const [approvedRequest,setApprovedRequest] = useState([]);
  const [completedRequest,setCompletedRequest] = useState([]);
  const [rejectedRequest,setRejectedRequest] = useState([]);

  const auth = getAuth(app);
  const [user, loading, error] = useAuthState(auth);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleTabChange = (event) =>{
    setCardType(event.target.value);
  }

  useEffect(()=>{

    if(user){
      if (user) {
        user
          .getIdTokenResult()
          .then(async (e) => {
            await sessionStorage.setItem("Auth_Token", JSON.stringify(e));
            await sessionStorage.setItem("User_Permission", JSON.stringify(e.claims));
          })
          .catch((e) => console.log(e));
      }
    }

    // TODO : Convert Local Api Call to Global Api Call
    // TODO : Localhost To Deployed

    let uuid = sessionStorage.getItem("UUID");
    if(uuid === null){
      let token = JSON.parse(sessionStorage.getItem("Auth_Token"));
      if(token !== null){
        uuid = token.claims.user_id;
      }
    }

    axios.get(`${BASE_URL}/requests/getPendingRequests`,{
      headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
    }).then((res)=>{
      let requestList = res.data
      setCardCount(requestList.length)
      let [ppr,par,pcr,prr] = requestList.reduce(([a,b,c,e],ele)=>{
        if(ele.status === "Pending"){
          return [[...a,ele],b,c,e];
        }else if(ele.status === "Accepted" && ele.admin === uuid){
          return [a,[...b,ele],c,e];
        }else if(ele.status === "Completed" && ele.admin === uuid){
          return [a,b,[...c,ele],e];
        }else if(ele.status === "Rejected" && ele.admin === uuid){
          return [a,b,c,[...e,ele]];
        }else{
          return [a,b,c,e];
        }
      },[[],[],[],[]]);

      setPendingRequest(ppr);
      setApprovedRequest(par);
      setCompletedRequest(pcr);
      setRejectedRequest(prr);

    }).catch((e)=>{
      console.log("Request Page Axios Error : ",e)
    })

  },[expanded])

  return (
    <div
      className="overflow-hidden"
      style={{
        position: "fixed",
        bottom: 0,
        left: "70vw",
        height: expanded ? "100vh" : "5vh",
        width: "30vw",
        backgroundColor: "#f8f8fb",
        transition: "height 0.5s ease-in-out",
        zIndex: 999,
        overflowY: "scroll",
        borderRadius: "10px 10px 0px 0px",
        boxShadow: "2px 0 5px rgba(0, 0, 0, 0.2)"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "calc(0.5rem + 0.2vh)",
          backgroundColor: "#425397",
          color: "white",
          fontWeight: "bold",
          fontSize: "16px",
          cursor: "pointer",
          borderRadius: "10px 10px 0px 0px"
        }}
        onClick={handleExpand}
      >
        <span>{pendingRequest?.length +" Pending Request"}</span>
        <span>{expanded ? "\u25BC" : "\u25B2"}</span>
      </div>
      <div style={{ 
        padding: "1rem",
        display:"flex",
        justifyContent:"space-between",
        alignContent:"center",
        flexWrap:"nowrap",
        flexDirection:"row", 
        alignItems:"center"}}>

        <Input 
        placeholder="Search here.."
        variant="outlined"
        color="primary"
        size="md"
        onChange={(e)=>setSearchQuery(e.target.value)}
        />

        <FormControl sx={{m : 1, flexBasis:"30%"}} size="small">
          <InputLabel id="request_simple_dropdown">RequestType</InputLabel>
          <Select
          labelId="request_simple_dropdown"
          id="request_single_dropdown_id"
          value={cardType}
          label="Request Type"
          onChange={handleTabChange}>
            <MenuItem value={"Pending"}>Pending</MenuItem>
            <MenuItem value={"Accepted"}>Accepted</MenuItem>
            <MenuItem value={"Completed"}>Completed</MenuItem>
            <MenuItem value={"Rejected"}>Rejected</MenuItem>
          </Select>
          <FormHelperText>Check Requests</FormHelperText>
        </FormControl>
      </div>
      <div className="Requests_Card_Content_Wrapper">
          
      {(cardType === "Pending") && 
      pendingRequest.map((e)=>{return (<PendingRequests item={e} />)})}

      {(cardType === "Accepted") && 
      approvedRequest.map((e)=>{return (<AcceptedRequests item={e} />) })}

      {(cardType === "Completed") && 
      completedRequest.map((e)=>{return (<CompletedRequests item={e} />)})}

      {(cardType === "Rejected" && 
      rejectedRequest.map((e)=>{return (<CompletedRequests item={e} />)}))}

      </div>
    </div>
  );
};

export default RightSidebar;
