import React, { useState, useContext, useRef } from "react";
import {
  MDBInput,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBBtn,
  MDBModalBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Context } from "../../MainPage";
import { Box, Snackbar, Alert } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomToolBar from "../MuiToolBar/CustomToolBar";
import CustomToolTip from "../ToolTip/CustomToolTip";
import "./VehicleAssignGuest.css";
import axios from "axios";
import PairDisplay from "./PairDisplay";
import { tableStyle, vehicleAssignStyle } from "../../Static/Styles";

export default function VehicleAssignGuest(props) {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const [modalStatus, setStatus] = useState(true);
  const { guestapi, vehicleapi, childCaller } = useContext(Context);

  const [guests, setGuests] = useState(guestapi);
  const [vehicles, setVehicles] = useState(vehicleapi);

  const [doublePair, setDoublePair] = useState(false);
  const handleClose = () => setDoublePair(false);

  const [guestId, setGuestId] = useState(
    props.setIDs.guestId
      ? {
          id: props.setIDs.guestId.id,
          name: props.setIDs.guestId.name,
        }
      : {
          id: "",
          name: "",
        }
  );
  const [vehicleId, setVehicleId] = useState(
    props.setIDs.vehicleID
      ? {
          id: props.setIDs.vehicleID.id,
          name: props.setIDs.vehicleID.name,
        }
      : {
          id: "",
          name: "",
        }
  );

  const [guestList, setGuestList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [guesttoVehicle, setGuestToVehicle] = useState([]);
  const [destinationList, setDestinationList] = useState([]);
  const [guesttoVehicleitem, setgtvItem] = useState();
  const [destination, setDestination] = useState("");

  const guestInputRef = useRef();
  const loInputRef = useRef();
  const vehicleRef = useRef();

  const [selectionRow1, setSelectionRow1] = useState([]);
  const [selectionRow2, setSelectionRow2] = useState([]);

  const parentHandler = () => {
    props.parentHandler(false);
    setStatus(false);
  };

  const handleVehicleEntry = (e, name) => {
    setVehicleId(e);
  };

  const handleGuestEntry = (id, vehicle) => {
    setGuestId(id);
    if (vehicle === "Not Assigned") {
      setgtvItem("");
    } else {
      setgtvItem(vehicle);
    }
  };

  const AssignGuest = () => {
    let temp = vehicleList.map((e) => e);
    let temp2 = guestList.map((e) => e);

    axios
      .post(
        `${BASE_URL}/vehicles/updateVehicles`,
        {
          vehicle_id: temp,
          guest_id: temp2,
          guest_vehicle: guesttoVehicle,
          destinationList: destinationList,
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((res) => {
        console.log(res.data);
        parentHandler();
        alert("Vehicle Assigned successfully");
        childCaller("Vehicle_assigned");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const vehicle_columns = [
    {
      fields: "assign",
      headerName: "PairBtn",
      sortable: false,
      hideable: false,
      width: 150,
      disableClickEventBubbling: true,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <button
            onClick={() => {
              handleVehicleEntry(params.row, params.row.name);
            }}
            className="application_base_btn table_btn_padding"
          >
            Pair Vehicle
          </button>
        );
      },
    },
    {
      field: "no",
      headerName: "Vehicle No.",
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      width: "150",
      editable: false,
    },
    {
      field: "name",
      headerName: "Vehicle Name",
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      width: "150",
      editable: false,
    },
    {
      field: "guest",
      headerName: "Guest Assigned",
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      width: "150",
      editable: false,
      hideable: false,
      renderCell: (cellValues) => {
        return <p className="mt-3">{cellValues.row.guest}</p>;
      },
    },
    {
      field: "driver_name",
      headerName: "Driver Name",
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      width: "150",
      editable: false,
    },
    {
      field: "driver_contacts",
      headerName: "Driver Contact",
      width: "150",
      editable: false,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      renderCell: (params) => {
        const item = params.row;
        return <>{item.driver_contacts[0]}</>;
      },
    },
    {
      field: "description",
      headerClassName: "prime_table_header_tab",
      headerName: "Type",
      width: "150",
      headerAlign: "center",
      editable: false,
    },
  ];

  const guest_columns = [
    {
      fields: "assign",
      headerName: "PairBtn",
      hideable: false,
      width: 150,
      disableClickEventBubbling: true,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <button
            onClick={() => {
              handleGuestEntry(params.row, params.row.assignLo);
            }}
            className="application_base_btn table_btn_padding"
          >
            Pair Guest
          </button>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      editable: false,
      hideable: false,
      width: "150",
    },
    {
      field: "vehicle",
      headerName: "Vehicle",
      width: "150",
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      editable: false,
      hideable: false,
      renderCell: (cellValues) => {
        return <p className="mt-3">{cellValues.row.vehicle}</p>;
      },
    },
    {
      field: "designation",
      headerClassName: "prime_table_header_tab",
      headerName: "Designation",
      editable: false,
      width: "150",
      headerAlign: "center",
    },
    {
      field: "party",
      headerClassName: "prime_table_header_tab",
      headerName: "Pol. Party",
      headerAlign: "center",
      editable: false,
      width: "150",
    },
    {
      field: "category",
      headerClassName: "prime_table_header_tab",
      headerName: "Category",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "constituency",
      headerClassName: "prime_table_header_tab",
      headerName: "Constituency",
      headerAlign: "center",
      editable: false,
      width: "150",
    },
    {
      field: "event_id",
      headerName: "NLC ID",
      editable: false,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      width: 150,
    },
  ];

  const PairItems = () => {
    setSelectionRow1([]);
    setSelectionRow2([]);
    if (
      typeof guestId !== "undefined" &&
      typeof vehicleId !== "undefined" &&
      guestId.id !== "" &&
      vehicleId.id !== ""
    ) {
      const gCheck = guestList.some((ele) => ele.id === guestId.id);
      const vCheck = vehicleList.some((ele) => ele.id === vehicleId);

      if (gCheck || vCheck) {
        setDoublePair(true);
      } else {
        setGuestList([...guestList, guestId]);
        setVehicleList([...vehicleList, vehicleId]);
        setGuestToVehicle([...guesttoVehicle, guesttoVehicleitem]);
        setDestinationList([...destinationList, destination]);

        setGuestId({ name: "", id: "" });
        setVehicleId({ id: "", name: "" });
        setDestination("");
        vehicleRef.current.value = "";
      }
    }
    loInputRef.current.value = "";
    guestInputRef.current.value = "";
    setgtvItem(undefined);
  };

  let userCheck;
  let check;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    check =
      ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
      ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true) ||
      ("VehicleAdmin" in userCheck && userCheck.VehicleAdmin === true)
        ? true
        : false;
  } catch (error) {}

  return (
    <MDBModal
      show={modalStatus}
      tabIndex="-1"
      onHide={() => {
        parentHandler();
      }}
    >
      <MDBModalDialog size="xl" style={{ minWidth: "1440px" }}>
        <MDBModalContent>
          <MDBModalHeader>
            <div className="MDB_Modal_Header_Wrapper">
              <MDBModalTitle>LINK GUEST AND VEHICLE </MDBModalTitle>
              <div className="modal_header_Tooltip_Holder">
                <CustomToolTip title="Assign Pairing">
                  <button
                    className="application_base_btn update_header_bars_btn"
                    onClick={AssignGuest}
                    disabled={
                      check && guesttoVehicle.length !== 0 ? false : true
                    }
                  >
                    Assign
                  </button>
                </CustomToolTip>

                <CustomToolTip title="Make a Guest,Lo Pair">
                  <button
                    disabled={guesttoVehicle.length === 10 ? true : false}
                    className="application_base_btn update_header_bars_btn"
                    onClick={PairItems}
                  >
                    Pair
                  </button>
                </CustomToolTip>

                <CustomToolTip title="Remove The Last Pair">
                  <button
                    disabled={guesttoVehicle.length >= 1 ? false : true}
                    className="application_base_btn update_header_bars_btn"
                    onClick={() => {
                      let tempPair = guesttoVehicle.slice();
                      tempPair.pop();
                      setGuestToVehicle(tempPair);
                      tempPair = guestList.slice();
                      tempPair.pop();
                      setGuestList(tempPair);
                      tempPair = vehicleList.slice();
                      tempPair.pop();
                      setVehicleList(tempPair);
                    }}
                  >
                    Undo Pair
                  </button>
                </CustomToolTip>
              </div>
            </div>

            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => {
                parentHandler();
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="modal_body_pair_display_wrapper_Div">
              <div className="modal_body_pair_display_holder">
                {guesttoVehicle.map((e, index) => {
                  return (
                    <PairDisplay
                      key={"VehiclePairGuestDisplayTab" + index}
                      index={index}
                      pairOne={guestList[index].name}
                      pairTwo={vehicleList[index].name}
                    />
                  );
                })}
              </div>
            </div>

            <MDBRow className="gx-0">
              <MDBCol size={6} className="px-3">
                <MDBRow>
                  <MDBCol size={6} className="px-2">
                    <MDBInput
                      label="Destination"
                      onChange={(e) => {
                        setDestination(e.target.value);
                      }}
                      ref={vehicleRef}
                    />
                  </MDBCol>
                  <MDBCol size={6} className="px-2">
                    <MDBInput
                      label="Select Vehicle"
                      value={vehicleId.name}
                      ref={loInputRef}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <Box sx={vehicleAssignStyle}>
                    <DataGrid
                      columns={vehicle_columns}
                      rows={vehicleapi.map((vehicle) => {
                        return {
                          id: vehicle.id,
                          edit: vehicle,
                          no: vehicle.no,
                          name: vehicle.name,
                          driver_name: vehicle.driver_name,
                          driver_contacts: vehicle.driver_contacts,
                          guest: vehicle.guest
                            ? guests.map((guest_) => {
                                if (vehicle.guest === guest_.id) {
                                  return guest_.name;
                                }
                              })
                            : "Not assigned",
                          description: vehicle.description,
                          delete: vehicle.id,
                        };
                      })}
                      getCellClassName={(params) => {
                        if (params.row.guest !== "Not assigned") {
                          return "super-app-theme--closed";
                        } else {
                          return "super-app-theme--default";
                        }
                      }}
                      slots={{ toolbar: CustomToolBar }}
                      sx={tableStyle}
                      rowSelectionModel={selectionRow1}
                      onRowSelectionModelChange={setSelectionRow1}
                    />
                  </Box>
                </MDBRow>
              </MDBCol>
              <MDBCol size={6} className="px-3">
                <MDBRow>
                  <MDBInput
                    label="Select Guest"
                    value={guestId.name}
                    ref={guestInputRef}
                  />
                </MDBRow>
                <MDBRow>
                  <Box
                    sx={{
                      width: "100%",
                      height: "70vh",
                      padding: "0",
                      paddingTop: "5px",
                    }}
                  >
                    <DataGrid
                      rows={guests.map((guest, index) => {
                        return {
                          id: guest.id,
                          name: guest.name,
                          vehicle: guest.vehicle
                            ? vehicles.map((vehicle_) => {
                                if (guest.vehicle === vehicle_.id) {
                                  return vehicle_.name;
                                }
                              })
                            : "Not assigned",
                          designation: guest.designation,
                          party: guest.party,
                          category: guest.category,
                          constituency: guest.constituency,
                          event_id: guest.event_id,
                        };
                      })}
                      columns={guest_columns}
                      slots={{ toolbar: CustomToolBar }}
                      getRowClassName={(params) => {
                        return params.indexRelativeToCurrentPage % 2 === 0
                          ? "mui_grid_table_even_row"
                          : "mui_grid_table_odd_row";
                      }}
                      sx={tableStyle}
                      rowSelectionModel={selectionRow2}
                      onRowSelectionModelChange={setSelectionRow2}
                    />
                  </Box>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <Snackbar
              open={doublePair}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                Cannot pair same entites again
              </Alert>
            </Snackbar>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
