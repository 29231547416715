import React, { useEffect, useState } from "react";
import { Box, List, CircularProgress, Modal } from "@mui/material";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import CustomToolBar from "../../Components/MuiToolBar/CustomToolBar";
import axios from "axios";
import "./Sessions.css";
import "../../App.css";
import Editicon from "../../Assets/edit.svg";
import Doneicon from "../../Assets/done.svg";
import AddSubject from "./AddSubject";
import AddSession from "./AddSession";
import EditSubject from "./EditSubject";
import { getAuth } from "firebase/auth";
import { app } from "../../FirebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import DisplayGuestModal from "./DisplayGuestModal";
import CustomToolTip from "../../Components/ToolTip/CustomToolTip";
import { tableStyle } from "../../Static/Styles";
import { MDBIcon } from "mdb-react-ui-kit";
import { collection, onSnapshot, query, getFirestore } from "@firebase/firestore";
import SubjectDeleteModal from "./SubjectDeleteModal";

export default function SessionPage() {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const [subjects, setSubjects] = useState([]);
  const [tempSubjects, setTempSubjects] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [isSessionLoading, setIsSessionLoading] = useState(true);
  const [isSubjectLoading, setIsSubjectLoading] = useState(true);
  const [guestModal, setGuestModal] = useState(false);
  const [guestIds, setGuestIds] = useState([]);
  const [openAddSubject, setOpenObject] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSubjectOpen, setIsSubjectOpen] = useState(false);
  const [selectSubjectId, setSelectedSubjectId] = useState('');

  const [editBoolean, setEditBoolean] = useState(false);

  const auth = getAuth(app);
  const [user] = useAuthState(auth);

  const handleOpenSubject = () => setOpenObject(true);
  const handleCloseSubject = () => setOpenObject(false);

  const [openAddSession, setOpenSession] = useState(false);

  const handleOpenSession = () => setOpenSession(true);
  const handleCloseSession = () => setOpenSession(false);

  const [openEditSubject, setOpenEditSubject] = useState(false);

  const handleOpenEditSubject = () => setOpenEditSubject(true);
  const handleCloseEditSubject = () => setOpenEditSubject(false);

  const [subjectId, setSubjectId] = useState("");
  const [editableBoolean, setEditableBoolean] = useState(false);
  const [deleteModal,setDeleteModal] = useState(false)
  const [sessionDeleteModal,setSessionDeleteModal] = useState(false)
  const [deleteData,setDeleteData] = useState({
    data: { id: null, message: null }
  })

  const [sessionId, setSessionId] = useState("");

  const getSubjects = () => {
    axios
      .get(BASE_URL + "/subjects/allSubjects", {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
      })
      .then((subject) => {
        setSubjects(subject.data);
        setTempSubjects(subject.data)
        setIsSubjectLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteSubject = (subject_Id) => {
    axios
      .delete(`${BASE_URL}/subjects/deleteSubject/${subject_Id}`, {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
      })
      .then((response) => {
        console.log(response.data);
        alert("Subject deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteModal = (editValue) => {
    setDeleteData({
      data: {
        id: editValue,
        message: "Are you sure you want to delete this item?",
      },
    });
    setDeleteModal(true);
  };

  const handleSessionDeleteModal = (editValue) => {
    setDeleteData({
      data: {
        id: editValue,
        message: "Are you sure you want to delete this item?",
      },
    });
    setSessionDeleteModal(true);
  };

  const getSessions = (subId) => {
    let isLoading
    if(subId !== subjectId){
      isLoading = true
      setIsSubjectOpen(true)
      setIsSessionLoading(true)
    }else{
      isLoading = isSessionLoading
      setIsSubjectOpen(!isSubjectOpen)
      setIsSessionLoading(false)
    }
    setSubjectId(subId);
    if (isLoading === true) {
      console.log("Inside True condition");
      axios
        .get(`${BASE_URL}/sessions/allsessions/${subId}`, {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        })
        .then((session) => {
          setIsSubjectOpen(true)
          setSessions(session.data);
          setIsSessionLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (isLoading === false) {
      setIsSessionLoading(true);
      setIsSubjectOpen(false)
    }
  };

  const freeSeats = (editValue) => {
    axios
      .post(
        `${BASE_URL}/sessions/freeLockedSeats/${subjectId}/${editValue.row.session_id}/`,
        {
          lockedSeats: 0,
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((res) => {
       alert("Seats Freed Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const guestModalDisplay = (editValue) => {
    setGuestIds({
      sessionID : editValue.row.session_id,
      guestData : editValue.row.id,
      totalSeats : editValue.row.total_seats,
    });
    setGuestModal(true);
  };

  const guestModalClose = () => {
    getSubjects();
    setGuestModal(false);
  };

  const setEditAndEditableTrue = () => {
    setEditBoolean(true);
    setEditableBoolean(true);
  };

  const cancelEdit = () => {
    setEditBoolean(false);
    setEditableBoolean(false);
  };

  const editSession = (editValue) => {
    setEditBoolean(false);
    setEditableBoolean(false);

    axios
      .post(
        `${BASE_URL}/sessions/updateSession/${subjectId}/${editValue.row.session_id}/`,
        {
          name: editValue.row.session_name,
          venue: editValue.row.venue,
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert("Session Updated Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteSession = (editValue) => {
    axios
      .delete(
        `${BASE_URL}/sessions/deleteSession/${editValue}/${subjectId}`,
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert("Session Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dateToFormatedDate = (dateObj) => {
    const yyyy = dateObj.getFullYear();
    let mm = dateObj.getMonth() + 1; // Months start at 0!
    let dd = dateObj.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "/" + mm + "/" + yyyy;
    return formattedToday;
  };

  useEffect(() => {
    if (user) {
      if (user) {
        user
          .getIdTokenResult()
          .then(async (e) => {
            await sessionStorage.setItem("Auth_Token", JSON.stringify(e));
            await sessionStorage.setItem(
              "User_Permission",
              JSON.stringify(e.claims)
            );
          })
          .catch((e) => console.log(e));
      }
    }

    let uuid = sessionStorage.getItem("UUID");
    if (uuid === null) {
      let token = JSON.parse(sessionStorage.getItem("Auth_Token"));
      if (token !== null) {
        uuid = token.claims.user_id;
      }
    }

    getSubjects();
  }, []);

  const handleAdancedClear = () => {};
  const handleAdanvedSearch = (e) => {
    e.preventDefault()
    if(searchTerm.trim().length === 0){
      setTempSubjects(subjects)
      console.log(tempSubjects);
      return
    }
    const newSubjects = subjects.filter((sub)=>{
      return sub.title.trim().toLowerCase().includes(searchTerm.trim().toLowerCase())
    })
    setTempSubjects(newSubjects)
  };

  let userCheck;
  let check;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    check =
      ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
      ("SessionAdmin" in userCheck && userCheck.SessionAdmin === true)
        ? true
        : false;
  } catch (error) {}

  return (
    <>
      <div className="mainDiv">
        <div className="container-fluid">
          <div className="header">
            <h3 className="fw-bold my-3 page_header_title_h3">Sessions</h3>
            <div className="addButtons">
              <CustomToolTip title="Add subject">
                <button
                  className="header-btn"
                  onClick={handleOpenSubject}
                  disabled={check ? false : true}
                >
                  Add Subject
                </button>
              </CustomToolTip>
              <CustomToolTip title="Add session">
                <button
                  className="header-btn"
                  onClick={handleOpenSession}
                  disabled={check ? false : true}
                >
                  Add Session
                </button>
              </CustomToolTip>
              <Modal
                sx={{ height: "100vh", overflow: "auto" }}
                open={openAddSession}
                onClose={handleCloseSession}
              >
                <AddSession
                  subjects={subjects}
                  handleCloseSession={handleCloseSession}
                />
              </Modal>
              <Modal open={openAddSubject} onClose={handleCloseSubject}>
                <AddSubject handleCloseSubject={handleCloseSubject} />
              </Modal>
            </div>
          </div>
        </div>
        {isSubjectLoading ? (
          <div className="circular-progress">
            <CircularProgress />
          </div>
        ) : (
          <>
                <form onSubmit={handleAdanvedSearch}>
            <div className="sessions_page_search_bar_wrapper">
              <div className="application_page_search_bar_wrapper">
                <button
                  className="application_base_btn btn_with_logo application_refresh_icon"
                  type="submit"
                >
                  <MDBIcon
                    fas
                    icon="search"
                    size="md"
                  />
                  <div>Search</div>
                </button>
                <input
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  type="text"
                  className="application_page_search_bar"
                  placeholder="Search"
                />
                <button
                  type="button"
                  onClick={handleAdancedClear}
                  className="application_base_btn btn_with_logo application_refresh_icon"
                  >
                  <MDBIcon
                    fas
                    icon="sync-alt"
                    size="md"
                    style={{
                      color: "white",
                    }}
                    />
                  <div>Refresh</div>
                </button>
              </div>
            </div>
                    </form>

            <div className="sessions overflow-scroll">
              <List
                sx={{
                  marginBottom: "50px",
                  marginTop: "30px",
                  overflow: "auto",
                }}
              >
                {tempSubjects.map((subject) => {
                  return (
                    <>
                      <div className="subject-class">
                        <h5>{subject.title}</h5>
                        <p className="subject-date-time">
                          From:{" "}
                          {dateToFormatedDate(
                            new Date(
                              subject.timings[0]._seconds * 1000 +
                                subject.timings[0]._nanoseconds / 100000
                            )
                          )}
                        </p>
                        <p className="subject-date-time">
                          To:{" "}
                          {dateToFormatedDate(
                            new Date(
                              subject.timings[1]._seconds * 1000 +
                                subject.timings[1]._nanoseconds / 100000
                            )
                          )}
                        </p>
                        <button
                          className="session-button"
                          onClick={() => {
                            getSessions(subject.id);
                          }}
                        >
                          <i class="fas fa-caret-down"></i>
                        </button>
                        <button
                          className="edit-subject"
                          onClick={handleOpenEditSubject}
                        >
                          <i class="fas fa-pen"></i>
                        </button>
                        <Modal
                          open={openEditSubject}
                          onClose={handleCloseEditSubject}
                        >
                          <EditSubject subjectId={subject.id} />
                        </Modal>
                        <div
                          className="delete-subject"
                          onClick={() => {
                            handleDeleteModal(subject.id);
                          }}
                          // onClick={() => deleteSubject(subject.id)}
                        >
                          <i class="fas fa-trash"></i>
                        </div>
                      </div>
                      {subjectId === subject.id && (
                        <>
                          {isSessionLoading ? 
                            isSubjectOpen ? 
                            <div className="d-flex justify-content-center">
                              <CircularProgress/>
                            </div>
                            : null
                          : (
                            <>
                              <Box
                                sx={{
                                  height: 400,
                                  maxWidth: "95%",
                                  margin: "auto",
                                  marginTop: "40px",
                                  marginBottom: "40px",
                                }}
                              >
                                <DataGrid
                                  sx={tableStyle}
                                  getRowClassName={(params) => {
                                    return params.indexRelativeToCurrentPage %
                                      2 ===
                                      0
                                      ? "mui_grid_table_even_row"
                                      : "mui_grid_table_odd_row";
                                  }}
                                  editMode="row"
                                  columns={[
                                    {
                                      ...GRID_CHECKBOX_SELECTION_COL_DEF,
                                      minWidth: 55,
                                      flex: 0.2,
                                      headerClassName: "prime_table_header_tab",
                                      headerAlign: "center",
                                    },
                                    {
                                      field: "edit",
                                      headerName: "Edit",
                                      headerClassName: "prime_table_header_tab",
                                      filterable: false,
                                      width: 120,
                                      headerAlign: "center",
                                      disableExport: true,
                                      renderCell: (cellValues) => {
                                        return (
                                          <>
                                            {editBoolean && sessionId === cellValues.id ? (
                                              <div
                                                className="edit-session"
                                                onClick={() => {
                                                  editSession(cellValues)
                                                  cellValues.api.stopRowEditMode({ id: cellValues.id });
                                                }}
                                              >
                                                <i
                                                  onClick={() => {
                                                    cancelEdit();
                                                    cellValues.api.stopRowEditMode({ id: cellValues.id });
                                                  }}
                                                  className="trashIcon fas fa-times fa-lg"
                                                />
                                                <img src={Doneicon} alt="" />
                                              </div>
                                            ) : (
                                              <div
                                                className="edit-session"
                                                onClick={() => {setEditAndEditableTrue();
                                                  setSessionId(cellValues.id);
                                                  cellValues.api.startRowEditMode({ id: cellValues.id });
                                                }}
                                              >
                                                <img src={Editicon} alt="" />
                                              </div>
                                            )}
                                          </>
                                        );
                                      },
                                    },
                                    {
                                      field: "delete",
                                      headerName: "Delete",
                                      filterable: false,
                                      disableExport: true,
                                      headerAlign: "center",
                                      headerClassName: "prime_table_header_tab",
                                      renderCell: (cellValues) => {
                                        return (
                                          <div
                                            className="delete-session"
                                            onClick={() => {
                                              handleSessionDeleteModal(cellValues.row.session_id);
                                            }}
                                          >
                                            <i class="fas fa-trash"></i>
                                          </div>
                                        );
                                      },
                                    },
                                    {
                                      field: "session_name",
                                      headerName: "Session Name",
                                      width: 200,
                                      headerClassName: "prime_table_header_tab",
                                      headerAlign: "center",
                                      editable: true,
                                    },
                                    {
                                      field: "venue",
                                      headerClassName: "prime_table_header_tab",
                                      headerAlign: "center",
                                      headerName: "Venue",
                                      width: 200,
                                      editable: true,
                                    },
                                    {
                                      field: "total_seats",
                                      headerName: "Available Seats",
                                      headerAlign: "center",
                                      width: 200,
                                      headerClassName: "prime_table_header_tab",
                                      editable: true,
                                    },
                                    {
                                      field: "locked_seats",
                                      headerName: "Locked Seats",
                                      headerAlign: "center",
                                      width: 200,
                                      headerClassName: "prime_table_header_tab",
                                    },
                                    {
                                      field: "free_seats",
                                      headerName: "Free Seats",
                                      headerAlign: "center",
                                      width: 200,
                                      headerClassName: "prime_table_header_tab",
                                      renderCell: (cellValues) => {
                                        return (
                                          <>
                                            {cellValues.row.locked_seats > 0 ? (
                                              <button
                                                onClick={() =>
                                                  freeSeats(cellValues)
                                                }
                                                className="AddGuestbtn"
                                              >
                                                Free Seats
                                              </button>
                                            ) : (
                                              <p style={{ marginTop: "17px" }}>
                                                All seats Free
                                              </p>
                                            )}
                                          </>
                                        );
                                      },
                                    },
                                    {
                                      field: "guests",
                                      headerName: "Guests",
                                      headerAlign: "center",
                                      width: 200,
                                      headerClassName: "prime_table_header_tab",
                                      renderCell: (cellValues) => {
                                        return (
                                          <button
                                            onClick={() =>{
                                              guestModalDisplay(cellValues)
                                            }
                                            }
                                            className="AddGuestbtn"
                                          >
                                            Guests
                                          </button>
                                        );
                                      },
                                    },
                                  ]}
                                  rows={sessions.map((session) => {
                                    return {
                                      id: session.guests,
                                      session_id: session.id,
                                      session_name: session.name,
                                      venue: session.venue,
                                      total_seats:
                                        session.total_seats -
                                        session.locked_seats -
                                        session.guests.length,
                                      locked_seats: session.locked_seats,
                                      free_seats: session.id,
                                      edit: session,
                                      delete: session,
                                    };
                                  })}
                                  getRowId={(row) => row.session_id}
                                  slots={{ toolbar: CustomToolBar }}
                                />
                              </Box>
                            </>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </List>
            </div>
          </>
        )}
      </div>
      {guestModal && (
        <DisplayGuestModal
          parentHandler={guestModalClose}
          guest={guestIds.guestData}
          sessionID={guestIds.sessionID}
          totalSeats={guestIds.totalSeats}
          subjectID={subjectId}
        />
      )}

        {deleteModal && (
          <SubjectDeleteModal
            parentHandler={()=>setDeleteModal(false)}
            data={deleteData}
            onConfirm={deleteSubject}
          />
        )}

        {sessionDeleteModal && (
          <SubjectDeleteModal
            parentHandler={()=>setSessionDeleteModal(false)}
            data={deleteData}
            onConfirm={deleteSession}
          />
        )}
    </>
  );
}
