import React,{ useState, useContext} from 'react';
import {Context} from "../../MainPage.js";
import "./Pending.css";
import { useNavigate } from "react-router-dom";
import { MDBIcon } from "mdb-react-ui-kit";
export default function CompletedRequests(props){
  const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const {loapi,guestapi} = useContext((Context));
    const [guestList,setGuestList] = useState(guestapi);
    const [loList,setLoList] = useState(loapi);
  
    const handleExpand = () => {
      setExpanded(!expanded);
    };
  
    const getTimeString = (e) =>{
      let date = new Date(e._seconds * 1000 + e._nanoseconds / 1000000)
  
      const yyyy = date.getFullYear();
      let mm = date.getMonth() + 1; // Months start at 0!
      let dd = date.getDate();
  
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
  
      const formattedToday = dd + '/' + mm + '/' + yyyy + "  " + date.toTimeString().slice(0,9);
      return formattedToday;
    }
  
    const getSenderName = (e) =>{
      let name = loList.find((e)=> {return e.id === props.item.sender});
      if (typeof(name) != 'undefined')
        return name.data.name;
      return "LoName";
    }
  
    const getGuestName = (e) => {
      let obj = guestapi.find((e) => e.id === props.item.guest);
      if (typeof obj != "undefined") return obj.name;
      return "Not Assigned";
    };
  
    const getGuestId = (e) => {
      let obj = guestapi.find((e) => e.id === props.item.guest);
      if (typeof obj != "undefined") {
        console.log(obj.id);
        return obj.id;
      }
  
      return "Not Assigned";
    };
  
    const handleDoneCard = (e) =>{
      console.log("Card-Done",e)
    }
  
    const handleRejectCard = (e) =>{
      console.log("Card-Reject",e);
    }
  
    return (
    <>
    <div className="Card_Holder"> 
      <div className="Card_Wrapper">
        <div className="Content_Wrapper">
        <div className="col-md-8">
          <div className='Header_Div'>
            <div className="description_title">{props.item.title}</div>
          </div>
          <div className="timestamp">
              {getTimeString(props.item.timestamp)}
            </div>

            <div className="description">
              <div className='description_content_wrapper'>
                {props.item.description}
              </div>
              </div>

            {/* shows remarks for rejection */}
            <div className="comment">
              <div className='description_content_wrapper'>
                {props.item.status === "Rejected" ? props.item.comment : null}
              </div>
              </div>
              
            </div>
            <div class="sender-target">
            <div className="Description_Sender_wrapper">
              <div style={{marginInline:"2px",marginTop:"2px",fontSize:"15px"}}>
                Request Sender
              </div>
              <div className="box_name">
                <div className="Sender_block"
                style={{ cursor: "default" }}
                >
                  {getSenderName()}
                  <MDBIcon className="" fas icon="angle-right" />
                </div>
              </div>
            </div>
            <div className="Guest_wrapper">
              <div style={{marginInline:"2px",marginTop:"2px"}}>
                Target Guest
              </div>
              <div style={{marginLeft: '25px',marginTop:"2px",paddingInline:"5px"}}>
                <div className="Sender_block"
                onClick={() => {
                  const id = getGuestId();
                      if (id !== "Not Assigned") {
                        navigate("/guestDesc/" + id);
                      }
                    }}
                >
                  {getGuestName()}
                  <MDBIcon fas icon="angle-right" />
                </div>
              </div>
            </div>
            <div>
              <button
              style={{cursor:"default", backgroundColor: `var(${props.requestColour})`}}
                  className="chip_target mx-2 my-4"
                >{props.item.target}</button>
               
              </div>
              {props.item.status==="Rejected"?
                    (<button
                     style={{cursor:"default"}}
                className="Main_Button rejected_button"
              >
                <MDBIcon far icon="times-circle" className="mx-2" />
                Rejected
              </button>)
              :
              (<button
              style={{cursor:"default"}}
                className="Main_Button completed_button"
              >
                <MDBIcon far icon="check-circle" className="mx-2" />
                Completed
              </button>)
              }
              
            </div>
        </div>
        </div>
    </div>
    </>
    );
}