import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import "../Dashboard.css";
import { app } from "../../FirebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { MDBBtn } from "mdb-react-ui-kit";
import RightSidebar from "../../Components/RightSidebar";
import profile from "../../Assets/profile.jpg";
import { data } from "jquery";

import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Context } from "../../MainPage";

const LoDesc = () => {
  const { guestapi, loapi, vehicleapi, hotelapi } = useContext(Context);

  const auth = getAuth(app);
  let navigate = useNavigate();
  let location = useLocation();
  let loState = location.state;
  const [user, loading, error] = useAuthState(auth);

  const [guests, setGuests] = useState(guestapi);
  const [los, setLos] = useState(loapi);
  const [vehicles, setVehicles] = useState(vehicleapi);
  const [hotels, setHotels] = useState(hotelapi);

  const [vehicleName, setVehicleName] = useState("");
  const [hotelName, setHotelName] = useState("");

  // Setting the display back to the list of all Lo(s)
  const GoBackToLos = () => {
    navigate("/los", { state: "Los" });
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const handleRowClick = (id) => {
    const currentExpandedRows = expandedRows.includes(id)
      ? expandedRows.filter((rowId) => rowId !== id)
      : [...expandedRows, id];

    setExpandedRows(currentExpandedRows);
  };

  const rows = () => {
    guests
      .filter((guest) => {
        if (loState.id === guest.lo) {
          return guest;
        }
      })
      .map((guest) => {
        console.log(guest);
        return {
          id: guest.id,
          category: guest.category,
          guest_name: guest.name,
          hotel: guest.hotel,
          expected_check_in: guest.check_in[0],
          vehicle: guest.vehicle,
          constituency: guest.constituency,
          designation: guest.designation,
        };
      });
  };

  const dateToFormatedDate = (dateObj) => {
    const yyyy = dateObj.getFullYear();
    let mm = dateObj.getMonth() + 1; // Months start at 0!
    let dd = dateObj.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday =
      dd + "/" + mm + "/" + yyyy + "  " + dateObj.toTimeString().slice(0, 9);
    return formattedToday;
  };

  const getHotelName = () => {
    guests.map((guest) => {
      if (loState.id === guest.lo) {
        hotels.map((hotel) => {
          if (guest.hotel === hotel.id) {
            setHotelName(hotel.name);
          }
        });
      }
    });
  };

  const getVehicleName = () => {
    guests.map((guest) => {
      if (loState.id === guest.lo) {
        console.log("inside vehicle");
        vehicles.map((vehicle) => {
          if (vehicle.id === guest.vehicle) {
            setVehicleName(vehicle.name);
          }
        });
      }
    });
  };

  const nameStyle = {
    color: "#3d549c",
    marginTop: "5px",
  };
  const infoStyle = {
    color: "#7e8ebd",
    marginTop: "-20px",
  };
  const infoStyle2 = {
    color: "#7e8ebd",
  };

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) navigate("/");
    if (error) alert(error);
    console.log(location.state);
    console.log(guests, los);
    rows();
    getVehicleName();
    getHotelName();
  }, [user, loading, navigate]);
  return (
    <>
      {/*<div className="dashboard d-flex dashboardmain">
       <div>
        <Sidebar />
        <RightSidebar/>
      </div> */}
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        {/* <NavBar register={false} doco_logo={false} ridex={true} ridex_logo={true} /> */}
        <div style={{ height: "100%", marginTop: "35px" }}>
          <div style={{ height: "calc(100%)", overflowY: "scroll" }}>
            <div className="d-flex">
              <img
                src={profile}
                alt="My Image"
                className="rounded-image mx-4"
              />
              <div className="d-inlin">
                <h2 style={nameStyle}>
                  <b>{loState.data.name}</b>
                </h2>
                <br />
                <h4 style={infoStyle}>
                  <b>
                    Liaison officer for {loState.data.guests?.length} guests
                  </b>
                </h4>
              </div>
            </div>

            <div className="mx-4">
              <hr></hr>
              <h4 style={nameStyle}>Contact Information</h4>
              <h6 style={infoStyle2}>
                +91 {loState.data.contacts[0]} | {loState.data.contacts[1]}
              </h6>
              <hr />
            </div>
            <Box
              sx={{
                height: 300,
                width: "95%",
                margin: "auto",
              }}
            >
              <DataGrid
                columns={[
                  {
                    field: "category",
                    headerName: "Category",
                    width: 150,
                    flex: 0.5,
                  },
                  {
                    field: "guest_name",
                    headerName: "Guest Name",
                    width: 150,
                    flex: 0.5,
                  },
                  {
                    field: "hotel",
                    headerName: "Hotel",
                    width: 150,
                    flex: 0.5,
                  },
                  {
                    field: "expected_check_in",
                    headerName: "Expected Check In",
                    width: 150,
                    flex: 0.5,
                  },
                  {
                    field: "vehicle",
                    headerName: "Vehicle",
                    width: 150,
                    flex: 0.5,
                  },
                  {
                    field: "constituency",
                    headerName: "Constituency",
                    width: 150,
                    flex: 0.5,
                  },
                  {
                    field: "designation",
                    headerName: "Designation",
                    width: 150,
                    flex: 0.5,
                  },
                ]}
                rows={guests
                  .filter((guest) => {
                    if (loState.id === guest.lo) {
                      return guest;
                    }
                  })
                  .map((guest, index) => {
                    return {
                      guest_id: index + 1,
                      category: guest.category,
                      guest_name: guest.name,
                      hotel: hotelName,
                      expected_check_in: dateToFormatedDate(
                        new Date(
                          guest.check_in[0]?._seconds * 1000 +
                            guest.check_in[0]?._nanoseconds / 1000000
                        )
                      ),
                      vehicle: vehicleName,
                      constituency: guest.constituency,
                      designation: guest.designation,
                    };
                  })}
                getRowId={(row) => row.guest_id}
                slots={{ toolbar: GridToolbar }}
              />
            </Box>
            <div className="m-3">
              <MDBBtn color="warning" onClick={GoBackToLos}>
                Go Back
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default LoDesc;
