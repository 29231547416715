import React from 'react';
import "./guesttable.css";
import axios from 'axios';
import { useEffect, useState } from 'react';

import "./IDCard.css";

const UploadOrGetIdCardModal = ({nlcIdCard, guestId, handleClose}) => {
    const BASE_URL = process.env["REACT_APP_BASE_URL"];

    const [nlcID, setNLCid] = useState(nlcIdCard)

    const uploadNlcIdCardUrl = (e) => {
      e.preventDefault();
        axios.post(
          `${BASE_URL}/guests/uploadNlcIdCardImageURL`, 
          {
            guestId: guestId,
            nlcIdCard: nlcID,
        },{
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
        })
        .then((response) => {
            alert("URL Uploaded");
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(()=>{
      console.log(nlcIdCard,guestId);
    },[])

  return (
    <>
    {nlcIdCard !== undefined ? (
      <div className='id-card-image-div'>
        <button className='id-card-image-close-button' onClick={handleClose}> <i className="fa-solid fa-close fs-5"></i></button>
        <img className='id-card-image' src={nlcID} alt="nlc_id_image" />
      </div>
    ) : (
        <div className='id-card-container'>
        <form onSubmit={uploadNlcIdCardUrl} className='id-card-form'>
          <label>NLC ID Card URL</label>
          <input type="text" placeholder='URL' onChange={(e)=>{setNLCid(e.target.value)}} value={nlcID} />
          <input className='id-card-submit' type="submit" value="Upload URL" />
        </form>
      </div>
    )}
    </>
  )
}

export default UploadOrGetIdCardModal
