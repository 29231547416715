import React, { useEffect, useState, useContext } from "react";
import "../Dashboard.css";
import { Card, Box, CardContent, Typography } from "@mui/material";
import { Context } from "../../MainPage";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";

import { MDBRow, MDBCol } from "mdb-react-ui-kit";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  ChartDataLabels,
  zoomPlugin,
  CategoryScale,
  LinearScale,
  BarElement
);

export default function VehicleDash(props) {
  const { loapi } = useContext(Context);

  const [loApi, setloAPI] = useState(loapi);

  const [loInformation, setLoInfo] = useState({
    total: 0,
    totalRequest: 0,
    losWithGuest: 0,
  });

  const LoCard = () => {
    return (
      <React.Fragment>
        <CardContent>
          <h5>Liasion Officer Information</h5>
          <h6>Basic Information</h6>
          <div>
            <div className="data_primary_table">
              <MDBRow>
                <MDBCol size={6}>Total Number Of LO's</MDBCol>
                <MDBCol size={6}>{loInformation.total}</MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size={6}>Total Number Of Requests</MDBCol>
                <MDBCol size={6}>{loInformation.totalRequest}</MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size={3}>Total Number Of Guests Assigned</MDBCol>
                <MDBRow>
                  <div className="data_secondary_table">
                    <MDBCol size={8} style={{
                      display:"flex",
                      flexWrap:"wrap",
                      gap:"15px"
                    }}>
                      {Object.keys(loInformation.losWithGuest).map((key, i) => {
                        return (
                          <>
                            <div>{key === "0" ? "Not Allocated" : "Allocated"} {key}</div>
                            <div> : {loInformation.losWithGuest[key]}</div>
                          </>
                        );
                      })}
                    </MDBCol>
                  </div>
                </MDBRow>
              </MDBRow>   
            </div>
          </div>
        </CardContent>
      </React.Fragment>
    );
  };

  useEffect(() => {
    let loObj = loApi.reduce(
      (object, ele) => {
        let temp_total = object.total;
        temp_total += 1;

        let temp_totalRequest = object.totalRequest;
        if (!(typeof ele.data.requests === "undefined")) {
          temp_totalRequest += ele.data.requests.length;
        }


        let temp_losWithGuests = object.losWithGuest;
        if (ele.data.guests === null) {
          if (!temp_losWithGuests.hasOwnProperty(0)) {
            temp_losWithGuests[0] = 1;
          } else {
            temp_losWithGuests[0] += 1;
          }
        } else {
          if (!temp_losWithGuests.hasOwnProperty(ele.data.guests.length)) {
            temp_losWithGuests[ele.data.guests.length] = 1;
          } else {
            temp_losWithGuests[ele.data.guests.length] += 1;
          }
        }

        return {
          total: temp_total,
          totalRequest: temp_totalRequest,
          losWithGuest: temp_losWithGuests,
        };
      },
      {
        total: 0,
        totalRequest: 0,
        losWithGuest: {},
      }
    );

    setLoInfo(loObj);
  }, []);

  const loDougnutdata = {
    labels: ["Not Allocated", "Allocated"],
    datasets: [
      {
        label: "Allocation Distribution",
        data: Object.keys(loInformation.losWithGuest).map(
          (key, i) => loInformation.losWithGuest[key]
        ),
        backgroundColor: [
          "#66c2a5",
          "#fc8d62",
          // '#8da0cb',
          "#e78ac3",
          "#d0b25c",
        ],
        borderWidth: 1,
      },
    ],
  };

  const guestcardStyle = {
    backgroundColor: "var(--application-card-data-holder-blue)",
    color: "var(--application-base-theme-secondary-color)",
    height: "40vh",
  };

  const loGraphStyle = {
    backgroundColor: "var(--application-pastel-color-blue)",
    color: "var(--application-base-theme-secondary-color)",
    height: "40vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    padding: "10px",
  };

  return (
    <>
      <div className="row g-0">
        <div className="row g-2">
          <div className="col-4">
            <Box width={"100%"}>
              <Card variant="outlined" sx={loGraphStyle}>
                <Doughnut
                  data={loDougnutdata}
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          color:
                            "var(--application-base-theme-guest-graph-color)",
                          font: { family: "Poppins" },
                        },
                        align : "center",
                        position: "top",
                      },
                      datalabels: {
                        display: true,
                        color:
                          "var(--application-base-theme-guest-graph-color)",
                        font: { family: "Poppins" },
                      },
                      title: {
                        display: true,
                        text: "LO's",
                        align: "center",
                        color:
                          "var(--application-base-theme-guest-graph-color)",
                        font: {
                          size: 24,
                          family: "Poppins",
                        },
                      },
                    },
                    scales: {
                      x: {
                        ticks: { font: { family: "Poppins" } },
                      },
                      y: {
                        ticks: { font: { family: "Poppins" } },
                      },
                    },
                  }}
                />
              </Card>
            </Box>
          </div>
          <div className="col-8">
            <Box width={"100%"}>
              <Card variant="outlined" sx={guestcardStyle}>
                <LoCard />
              </Card>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}
