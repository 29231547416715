import React, { useContext, useState } from "react";
import "./contact.css";
import "../../App.css"
import Editicon from "../../Assets/edit.svg";
import Doneicon from "../../Assets/done.svg";
import { MDBIcon, MDBCol } from "mdb-react-ui-kit";
import CustomToolTip from "../../Components/ToolTip/CustomToolTip";
import {
  DataGrid,
  GridRowModes,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import { Box, Modal } from "@mui/material";
import CustomToolBar from "../../Components/MuiToolBar/CustomToolBar";
import { Context } from "../../MainPage";
import ConfirmationModal from "../../Pages/ConfirmationModal";
import AddContact from "./AddContact";
import { tableStyle } from "../../Static/Styles";
import axios from "axios";
import { useEffect } from "react";

export default function ContactPage() {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const [searchTerm,setSearchTerm] = useState('')
  const [editModeModel, setEditMode] = useState({});
  const [editedRow, setEditRow] = useState(false);
  const [openAddContact, setOpenContact] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { contacts, childCaller } = useContext(Context);
  const [showContacts, setShowContacts] = useState(contacts);
  const handleOpenContact = () => setOpenContact(true);
  const handleCloseContact = () => setOpenContact(false);

  const [deleteData, setDeleteData] = useState({
    data: { contact: null, message: null },
  });

  const [editBoolean, setEditBoolean] = useState(false);
  const [editableBoolean, setEditableBoolean] = useState(false);
  const [oldObject, setOldObject] = useState({});

  useEffect(() => {
    setShowContacts(contacts);
  },[contacts]);
  
  const handleRowModesModelChange = (newRowModesModel) => {
    setEditMode(newRowModesModel);
  };
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  const handleEdit = (params) => {
    setOldObject(params.row);
    setEditBoolean(true);
    setEditableBoolean(true);
  }

  const handleDone = (params) => {
    setEditBoolean(false);
    setEditableBoolean(false);

    axios.post(`${BASE_URL}/misc/updateContact`, {
      oldObject: {
        contact: oldObject.contact,
        name: oldObject.name,
        section: oldObject.section,
      },
      newObject: {
        contact: params.row.contact,
        name: params.row.name,
        section: params.row.section,
      }
    }, {
      headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
    })
    .then((response) => {
      console.log(response);
      alert("Contact Updated Successfully");
    })
    .catch((err) => {
      console.log(err);
      alert("Could not update. Please try again");
    })
  };
  const handleDeleteRowModal = (deleteValue) => {
    setDeleteData({
      data: {
        contactDetails: {
          contact: deleteValue.contact,
          name: deleteValue.name,
          section: deleteValue.section,
        },
        message: "Are you sure you want to delete this item?",
      },
    });
    setDeleteModal(true);
  };
  const handleDeleteRowCloseModal = () => {
    setDeleteModal(false);
  };

  const DeleteRowApi = (id) => {
    // Delete row Axios call
  };

  const handleAdanvedSearch = () =>{
    if (searchTerm.trim().length === 0) {
      setShowContacts(contacts);
    }
    else {
      let tempList = contacts;
      let filteration = tempList.filter((e) => {
        return e.name.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()) ||
        e.contact.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()) || 
            e.section.toLowerCase().trim().includes(searchTerm.toLowerCase().trim())
      })
      if(filteration.length===0){
        alert("No Rows Found");
      }else{
        setShowContacts(filteration)
      }
    }
  }
  const handleAdancedClear = ()=>{
    setShowContacts(contacts);
    setSearchTerm("")
    childCaller("Contacts")
  }
  
  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 55,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
    },
    {
      field: "edit",
      headerName: "",
      filterable: false,
      width: 200,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      disableExport: true,
      renderCell: (params) => {

        return (
          <>
            {!editBoolean ? (
              <div>
              <button
                style={{
                  border: "none",
                  padding: "0",
                  backgroundColor: "transparent",
                  margin: "10px",
                }}
              >
                <i
                  class="trashIcon fa-sharp fa-solid fa-trash"
                  onClick={() => handleDeleteRowModal(params.row)}
                ></i>
              </button>
              <img
                src={Editicon}
                alt=""
                onClick={() => {
                  handleEdit(params);
                }}
              />
              </div>
            ) : (
              <div>
              <button
                style={{
                  border: "none",
                  padding: "0",
                  backgroundColor: "transparent",
                  margin: "10px",
                }}
              >
                <i
                  className="trashIcon fas fa-times fa-lg"
                  onClick={() => {
                    setEditBoolean(false);
                    setEditableBoolean(false);
                  }}
                ></i>
              </button>
              <img
                src={Doneicon}
                alt=""
                onClick={() => {
                  handleDone(params);
                }}
              />
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "contact",
      headerClassName: "prime_table_header_tab",
      headerName: "Contact Number",
      minWidth: 250,
      editable: editableBoolean,
      flex : 0.3,
      headerAlign: "center",
    },
    {
      field: "name",
      headerClassName: "prime_table_header_tab",
      headerName: "Name",
      editable: editableBoolean,
      minWidth: 250,
      flex : 0.3,
      headerAlign: "center",
    },
    {
      field: "section",
      headerClassName: "prime_table_header_tab",
      headerName: "Section",
      editable: editableBoolean,
      minWidth: 250,
      flex : 0.3,
      headerAlign: "center",
    },
  ];
  return (
    <div className="mainDiv">
    <div className="container-fluid">
      <div className="header">
        <h3 className="fw-bold my-3 page_header_title_h3">Contacts</h3>
        <div className="add-btn">
        <CustomToolTip title="Add contacts">
          <button className="header-btn" onClick={handleOpenContact}>
            Add Contacts
          </button>
          </CustomToolTip>
          {openAddContact && (
            <AddContact parentHandler={handleCloseContact}/>
            )}
        </div>
      </div>
      <div className="searchBar">
        <div class="search-container">
          <div class="search_contacts flex-grow-1">
            <CustomToolTip title="Search Using Params">
              <MDBCol className="AdvancedSearch_Wrapper" size={1}>
                <MDBIcon fas icon="search" size="md" onClick={handleAdanvedSearch}
                  style={{
                    marginRight : "5px",
                    backgroundColor: "var(--application-base-theme-primary-color)",
                    paddingInline : "20px",
                    paddingTop : "10px",
                    paddingBottom : "7px",
                    borderRadius : "4px",
                    width: "-webkit-fill-available",
                    height: "35px",
                    color: "white",
                    display: "flex",
                    justifyContent : "center",
                }}/>
              </MDBCol>
            </CustomToolTip>
            <input
            value={searchTerm}
            onChange={(e)=>setSearchTerm(e.target.value)}
              type="text"
              className="searchTerm text-black"
              placeholder="Search"
            />
          </div>
          <button
          onClick={handleAdancedClear}
            className="refreshBtn"
            style={{ height: "40px", alignSelf: "center" }}
          >
            <MDBIcon
              fas
              icon="sync-alt"
              size="md"
              style={{
                color: "white",
              }}
            />
            <div>Refresh</div>
          </button>
        </div>
      </div>
        <Box
          sx={{ height: "79vh", width: "100%", margin: "auto"}}
        >
          <DataGrid
            rows={showContacts.map((e, index) => {
              return {
                id: index,
                edit: e,
                contact: e.contact,
                name: e.name,
                section: e.section,
              };
            })}
            sx={tableStyle}
            columns={columns}
            slots={{
              toolbar: CustomToolBar,
            }}
            getRowClassName={(params) => {
              return params.indexRelativeToCurrentPage % 2 === 0
                ? "mui_grid_table_even_row"
                : "mui_grid_table_odd_row";
            }}
          />
          {deleteModal && (
            <ConfirmationModal
              parentHandler={handleDeleteRowCloseModal}
              data={deleteData}
            />
          )}
        </Box>
    </div>
    </div>
  );
}
