import React, { useEffect, useState } from 'react'
import "./Dashboard.css"
import { app } from '../FirebaseConfig';
import { useNavigate } from "react-router-dom";
import { getAuth } from 'firebase/auth';
import { useAuthState } from "react-firebase-hooks/auth";
import { Box,Tabs,Tab, tabClasses} from '@mui/material';
import {Chart as ChartJS, ArcElement, Tooltip,Legend,Title} from 'chart.js'

import GuestDash from './DashBoardPages/GuestDash';
import HotelDash from './DashBoardPages/HotelDash';
import LoDash from './DashBoardPages/LoDash';
import VehicleDash from './DashBoardPages/VehicleDash';

import { tabStyle, tabIndicatorStyle } from '../Static/Styles';

ChartJS.register(ArcElement,Tooltip,Legend,Title)

const Dashboard = () => {
  const auth = getAuth(app);
  const [loader,setLoader] = useState(true)
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  const [selection_value,setSelectionValue] = useState(0); 

  useEffect(() => {

    if(user){
        if (user) {
          user
            .getIdTokenResult()
            .then(async (e) => {
              await sessionStorage.setItem("Auth_Token", JSON.stringify(e));
              await sessionStorage.setItem("User_Permission", JSON.stringify(e.claims));
            })
            .catch((e) => console.log(e));
        }
      }
      let uuid = sessionStorage.getItem("UUID");
      if(uuid === null){
        let token = JSON.parse(sessionStorage.getItem("Auth_Token"));
        if(token !== null){
          uuid = token.claims.user_id;
        }
      }

    if (loading) {
      setLoader(true)
      // maybe trigger a loading screen
      return;
    }else{
      setLoader(false)
    }
    if (!user) navigate("/");
    if (error) alert(error);
  }, [user, loading, navigate]);

  const handleTabChange = (event,newValue) =>{
    setSelectionValue(newValue);
  }

  return (
    <div className="container">
        <div className="row g-0">
        <Box sx={{ width: '100%', bgcolor: 'background.paper', fontFamily : "Poppins"  }}>
          <Tabs 
            value={selection_value} 
            onChange={handleTabChange} 
            centered
            variant='fullWidth'
            >
            <Tab 
            label="Guest" value={0} 
            sx={{fontFamily :"Poppins"}}/>
            <Tab label="Hotel" value={1} 
            sx={{fontFamily :"Poppins"}}/>
            <Tab label="Vehicle" value={2} 
            sx={{fontFamily :"Poppins"}}/>
            <Tab label="LO" value={3} 
            sx={{fontFamily :"Poppins"}}/>
          </Tabs>
        </Box>

        {selection_value === 0 && (<GuestDash /> )}

        {selection_value === 1 && (<HotelDash />)}

        {selection_value === 2 && (<VehicleDash />)}

        {selection_value === 3 && (<LoDash />)}

        </div>
    </div>
  )
}

export default Dashboard