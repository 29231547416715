import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./GuestDesc.css";
import "../Dashboard.css";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBBtn,
  MDBModalBody,
} from "mdb-react-ui-kit";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { app } from "../../FirebaseConfig";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const GuestDesc = (props) => {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const [departure, setDeparture] = useState("");
  const [destination, setDestination] = useState("");
  const [transportMode, setTransportMode] = useState("Airways");
  const [departureTime, setDepartureTime] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [file, setFile] = useState('');
  const [fileURls,setURls] = useState([]);
  const [travel, setTravel] = useState([]);
  const [hasTravel, setHasTravel] = useState([]);
  const [loader, setLoader] = useState(true);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [addLoader, setAddLoader] = useState(false);
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [uploadError,setUploadError] = useState('')
  const [refNo,setRefNo] = useState('')

  // NEW
  const [modalStatus, setStatus] = useState(true);
  const navigate = useNavigate();

  console.log(props.guest);

  let userCheck;
  let check;
  try {
      userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
      check =
      ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
      ("TravelAdmin" in userCheck && userCheck.TravelAdmin === true) 
      ? true
      : false;
  } catch (error) {
      navigate("/");
  }

  // DATE FORMATTER
  const dateToFormatedDate = (dateObj) => {
    const yyyy = dateObj.getFullYear();
    let mm = dateObj.getMonth() + 1; // Months start at 0!
    let dd = dateObj.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday =
      dd + "/" + mm + "/" + yyyy + "    " + dateObj.toTimeString().slice(0, 9);
    return formattedToday;
  };

  useEffect(() => {
    const getItenary = async () => {
      await axios
        .get(`${BASE_URL}/travel/getTravel/${props.id}`, {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        })
        .then((res) => {
          setLoader(false);
          if (res.data) {
            setHasTravel(true);
            setTravel(res.data);
          } else {
            setHasTravel(false);
            setTravel([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getItenary();
  }, []);

  const addItenary = async (e) => {
    if(file === undefined || file?.trim().length === 0){
      setUploadError('Please upload the ticket')
      return
    }
    setUploadError('')
    setAddLoader(true);
    await axios
      .post(
        BASE_URL + "/travel/addItenary",
        {
          id: props.id,
          from: departure,
          to: destination,
          mode: transportMode,
          ticket: file,
          timings: [departureTime, arrivalTime],
          hasTravel: hasTravel,
          previousItenary: travel,
          pickUpPoint: pickUp,
          dropOffPoint: dropOff,
          ref_no: refNo
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((res) => {
        // setTravel([...travel,res.data])
        setAddLoader(false);
        setTravel([...travel,res.data]);
      })
      .catch((err) => {
        alert(err);
        setAddLoader(false);
        console.log(err);
      });
  };

  const parentHandler = () => {
    props.parentHandler(false);
    setStatus(false);
  };

  const uploadTicketDoc = () => {
    setUploadLoader(true);
    let storage = getStorage(app);
    const fileList = file;

    // for (let i = 0; i < fileList.length; i++) {
      let ticketMetaData = { contentType: file.type };

      const travelIdDocRef = ref(storage, "Travels/" + file.name);
      const uploadTravelIdDocTask = uploadBytesResumable(
        travelIdDocRef,
        file,
        ticketMetaData
      );
      uploadTravelIdDocTask.on(
        "state_changed",
        (snapshot) => {
          console.log("Uploading Ticket");
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTravelIdDocTask.snapshot.ref).then(
            (downloadURL) => {
              // setURls([...fileURls,downloadURL]);
              setUploadLoader(false);
              setFile(downloadURL)
            }
          );
        }
      );
    // }
    
  };

  const handleDownload = (t) =>{
    props.guest.id_doc.map(async(e,index)=>{
      window.open(e,"_wnd"+index)
    })
  }

  const travelInfoCard = () => {

    // const handleDownload = (t) =>{
    //   t.tickets.map(async(e,index)=>{
    //     await setTimeout(()=>{
    //       window.open(e,"_blank")
          
    //     },2000)
    //     return e;
    //   })
    // }

    if (travel.length === 0) {
      return <h3>No travel Information</h3>;
    }
    return travel.map((t) => {
      return (
        <div className="d-flex travel-info" key={"Travel_Itenary_Item_"+t.mode + t.from + t.to}>
          <div>
            <div className="d-flex justify-content-start">
              <span className="d-flex  input-group-text bg-transparent  border border-0  p-0">
                <i
                  style={{ color: "#7A8FC1" }}
                  className="fa fa-location-arrow  "
                  aria-hidden="true"
                ></i>
              </span>
              <h5 className="my-1 px-2 fw-bold">From</h5>
            </div>
            <div>
              <p className="d-flex mx-4">
                <u>{t.from}</u>
              </p>
            </div>
          </div>
          <div className=" d-flex justify-content-center  align-items-center mx-2 ">
            <span className=" input-group-text bg-transparent border border-0 ">
              <i
                style={{ color: "#7A8FC1" }}
                className="fa fa-long-arrow-right fa-2x "
                aria-hidden="true"
              ></i>
            </span>
          </div>
          <div className="">
            <div className="d-flex justify-content-start ">
              <span className="d-flex  input-group-text bg-transparent  border border-0  p-0">
                <i
                  style={{ color: "#7A8FC1" }}
                  className="fa fa-map-marker"
                  aria-hidden="true"
                ></i>
              </span>
              <h5 className="my-1 px-2 fw-bold">To</h5>
            </div>
            <div className=" mx-3">
              <p>
                {" "}
                <u>{t.to}</u>{" "}
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center mx-2 ">
              <button 
              onClick={()=>{window.open(t.ticket,"_blank")}}
              className="application_base_btn btn_with_logo table_btn_padding">
                <p className="m-0 p-0 ">
                  Ticket
                </p>
                  <i className="fa fa-download" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      );
    });
  };

  return (
    <MDBModal
      show={modalStatus}
      tabIndex="-1"
      onHide={() => {
        parentHandler();
      }}
    >
      <MDBModalDialog size="xl" style={{ width: 'fit-content', maxWidth: '100vw' }}>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>
              <h2 className="fw-bold">TRAVEL ITENARY</h2>
            </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => {
                parentHandler();
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="main-div">
              <div className="sec-div1">
                <div className="guest-div">
                  <div className="guest-details">
                    <div className="img-div">
                      <img
                        className="img"
                        src={
                          typeof props.guest.image !== "undefined"
                            ? props.guest.image
                            : ""
                        }
                        alt=""
                      />
                    </div>
                    <div className="details-div">
                      <h5 className="fw-bold">{props.guest.name}</h5>
                      <div>{props.guest.constituency}</div>
                      <div>{props.guest.designation}</div>
                    </div>
                  </div>
                  <div className="doc-div">
                    <h5 className=" fw-bold my-3">Identification Document</h5>
                    <button
                      style={{ backgroundColor: "#3555A1" }}
                      className="btn btn-outline-info d-flex  justify-content-center align-items-center my-3"
                      onClick={handleDownload}
                    >
                      <p style={{ color: "#FFFFFF" }} className="m-0 p-0 ">
                        Download
                      </p>
                      <span
                        style={{ color: "#FFFFFF" }}
                        className="d-flex justify-content-center align-items-center  input-group-text bg-transparent mx-2  border border-0  p-0"
                      >
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                </div>

                <div className="time-div">
                  <div>
                    <h5 className="fw-bold ea_margin">Expected</h5>
                    <div>
                      <h5 className="">Check-In Time</h5>
                      <div className="d-flex  ">
                        <span className="pe-2 pb-2 bg-white border border-0">
                          <i
                            className="fa-solid fa-calendar-days fs-5"
                            style={{ color: "#7A8FC1" }}
                          ></i>
                        </span>
                        {props.guest.check_in.length > 0 ? (
                          <p className="m-0">
                            {dateToFormatedDate(
                              new Date(
                                props.guest.check_in[0]._seconds * 1000 +
                                  props.guest.check_in[0]._nanoseconds / 1000000
                              )
                            )}
                          </p>
                        ) : (
                          <p>Not Checked In</p>
                        )}
                      </div>
                    </div>
                    <div>
                      <h5 className="">Check-Out Time</h5>
                      <div className="d-flex  ">
                        <span className="pe-2 pb-2 bg-white border border-0">
                          <i
                            className="fa-solid fa-calendar-days fs-5"
                            style={{ color: "#7A8FC1" }}
                          ></i>
                        </span>
                        {props.guest.check_out.length > 0 ? (
                          <p className="m-0">
                            {dateToFormatedDate(
                              new Date(
                                props.guest.check_out[0]._seconds * 1000 +
                                  props.guest.check_out[0]._nanoseconds /
                                    1000000
                              )
                            )}
                          </p>
                        ) : (
                          <p>Not Checked Out</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <h5 className=" fw-bold ea_margin">Actual</h5>
                    <div>
                      <h5 className="">Check-In Time</h5>
                      <div className="d-flex">
                        <span className="pe-2 pb-2 bg-white border border-0">
                          <i
                            className="fa-solid fa-calendar-days fs-5"
                            style={{ color: "#7A8FC1" }}
                          ></i>
                        </span>
                        {props.guest.check_in.length > 1 ? (
                          <p className="m-0">
                            {dateToFormatedDate(
                              new Date(
                                props.guest.check_in[1]._seconds * 1000 +
                                  props.guest.check_in[1]._nanoseconds / 1000000
                              )
                            )}
                          </p>
                        ) : (
                          <p>Not Checked In</p>
                        )}
                      </div>
                    </div>
                    <div>
                      <h5 className="">Check-Out Time</h5>
                      <div className="d-flex  ">
                        <span className="pe-2 pb-2 bg-white border border-0">
                          <i
                            className="fa-solid fa-calendar-days fs-5"
                            style={{ color: "#7A8FC1" }}
                          ></i>
                        </span>
                        {props.guest.check_out.length > 1 ? (
                          <p className="m-0">
                            {dateToFormatedDate(
                              new Date(
                                props.guest.check_out[1]._seconds * 1000 +
                                  props.guest.check_out[1]._nanoseconds /
                                    1000000
                              )
                            )}
                          </p>
                        ) : (
                          <p>Not Checked Out</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sec-div2">
                <div className="add-ticket">
                  <h2>Add Ticket</h2>
                  <div>
                    <div className="add-ticket-div1">
                      <div>
                        <h5 className="fw-bold">From</h5>
                        <input
                          type="text"
                          id="departure"
                          name="departure"
                          className="application_input_field_form_version"
                          value={departure}
                          onChange={(e) => setDeparture(e.target.value)}
                          placeholder="Enter Departure"
                        />
                      </div>
                      <div>
                        <h5 className="fw-bold">To</h5>
                        <input
                          type="text"
                          id="destination"
                          name="destination"
                          value={destination}
                          className="application_input_field_form_version"
                          onChange={(e) => setDestination(e.target.value)}
                          placeholder="Enter Destination"
                        />
                      </div>
                    </div>

                    <div className="terminal-div">
                      <div>
                        <h5 className="fw-bold">Pick up point</h5>
                        <input
                          type="text"
                          id="pickup"
                          name="pickup"
                          value={pickUp}
                          className="application_input_field_form_version"
                          onChange={(e) => setPickUp(e.target.value)}
                          placeholder="Enter Pickup Point"
                        />
                      </div>
                      <div>
                        <h5 className="fw-bold">Drop off point</h5>
                        <input
                          type="text"
                          id="dropoff"
                          name="dropoff"
                          value={dropOff}
                          className="application_input_field_form_version"
                          onChange={(e) => setDropOff(e.target.value)}
                          placeholder="Enter Dropoff Point"
                        />
                      </div>
                    </div>

                    <div className="add-ticket-div2">
                      <div>
                        <h5 className="fw-bold">Transport Mode</h5>
                        <select
                          className="form-select"
                          onChange={(e) =>
                            setTransportMode(
                              e.target.options[e.target.selectedIndex].text
                            )
                          }
                        >
                          <option value="" className="valueColor">
                            Select mode
                          </option>
                          <option value="" className="valueColor">
                            Airway
                          </option>
                          <option value="" className="valueColor">
                            Railway
                          </option>
                          <option value="" className="valueColor">
                            Bus
                          </option>
                        </select>
                      </div>
                      <div>
                        <h5 className="label fw-bold">Select File</h5>

                        <div className="upload-div">
                          <input
                            type="file"
                            className="form-control uploadFileSize select-file-input"
                            onChange={(e) => {
                              setFile(e.target.files[0]);
                            }}
                            multiple
                          />
                          {uploadLoader ? (
                            <div className="loader-div">
                              <CircularProgress className="loader" />
                            </div>
                          ) : (
                            <button
                              className="uploadBtn"
                              onClick={() => {
                                uploadTicketDoc();
                              }}
                            >
                              <i
                                className="fa fa-upload"
                                aria-hidden="true"
                              ></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                      <div>
                        <h5 className="fw-bold mt-4">Reference No</h5>
                        <input
                          type="text"
                          id="ref_no"
                          name="ref_no"
                          className="application_input_field_form_version"
                          value={refNo}
                          onChange={(e) => setRefNo(e.target.value)}
                          placeholder="Enter airline name and flight number"
                        />
                      </div>

                    <div className="itenary-card-div">
                      <div>
                        <h5 className="fw-bold">Departure</h5>
                        <input
                          type="datetime-local"
                          id="departure_time"
                          name="departure_time"
                          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                          className="application_input_field_form_version"
                          onChange={(e) => setDepartureTime(e.target.value)}
                        />
                      </div>
                      <div>
                        <h5 className="fw-bold">Arrival</h5>
                        <input
                          type="datetime-local"
                          id="arrival_time"
                          name="arrival_time"
                          className="application_input_field_form_version"
                          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                          onChange={(e) => setArrivalTime(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="btn-div">
                      <div className="text-danger">
                        {uploadError}
                      </div>
                      {addLoader ? (
                        <div className="add_loader-div">
                          <CircularProgress className="loader" />
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            addItenary();
                          }}
                          className="application_base_btn btn_with_logo table_btn_padding"
                          type="submit"
                          disabled={check? false: true}
                        >
                          <i className="fa fa-add" aria-hidden="true"></i>
                          <p style={{ color: "#FFFFFF" }} className="m-0 p-0">
                            Add Ticket
                          </p>
                          <span
                            style={{ color: "#FFFFFF" }}
                            className="d-flex justify-content-center align-items-center  input-group-text bg-transparent mx-2  border border-0  p-0"
                          ></span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="travel-ticket-info d-flex flex-column">
                  <h2>Travel & Ticket Info</h2>
                  {loader ? (
                    <div className="loader-div">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="tickets-card">{travelInfoCard()}</div>
                  )}
                </div>
              </div>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default GuestDesc;
