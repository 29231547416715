import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput
} from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';

const AddHotels = () => {
  const [centredModal, setCentredModal] = useState(true);
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [InputErr, setInputErr] = useState('');
  const navigate = useNavigate()

  const toggleShow = () => {
    setCentredModal(!centredModal);
  }

  useEffect(()=>{
    if(!centredModal){
      navigate(-1)
    }
  })

  const onAdd = () => {
    if(!name || !contact){
      setInputErr('Some required fields are empty')
      return
    }
    if(contact.toString().length !== 10){
      setInputErr('Invalid contact information')
      return
    }
    setInputErr('')
    window.location.href = '/hotels'
  }

  return (
    <>
      <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add Hotels</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div class="form-helper" style={{color: 'red',marginBottom: '4px'}}>{InputErr}</div>
              <MDBInput wrapperClass='mb-4' label='Name' id='formControlLg' type='name' value={name} onChange={(e) => setName(e.target.value)} size="lg" />
              <MDBInput wrapperClass='mb-4' label='Contact' id='formControlLg' type='number' value={contact} onChange={(e) => setContact(e.target.value)} size="lg" />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn onClick={onAdd}>Add</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default AddHotels