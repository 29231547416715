import React, { useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import axios from 'axios';

export default function SubjectDeleteModal(props) {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];
  const [modalStatus, setStatus] = useState(true);

const parentNoHandler = () => {
  props.parentHandler(false)
  setStatus(false)
}

  return (
    <>
      <MDBModal show={modalStatus} tabIndex='-1' onHide={() => { parentNoHandler() }}>
      <MDBModalDialog className="modal-dialog-centered">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{props.data.data.message}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={parentNoHandler}></MDBBtn>
            </MDBModalHeader>


            <MDBModalFooter>
              <MDBBtn className='btn-danger' onClick={parentNoHandler}>
               No
              </MDBBtn>
              <MDBBtn onClick={()=>{
                props.onConfirm(props.data.data.id)
                setStatus(false)
                }}>Yes</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}


