import React from 'react';
import './LoDesc.css';

const LoSemiDesc = ({ loArray }) => {
    const numberOfLOs = loArray ? loArray.length : 0;

    const assignedLOs = loArray ? loArray.filter(lo => lo.guests !== null).length : 0;
    
    const getLoswithGuests = () => {
        let count = 0;
        loArray.map((lo)=>{
            if(lo.data.guests?.length > 0){
                count++;
            }
        })
        return count;
    }

    return (
        <div className="application_semi_description_bar_wrapper row g-0">
            <div className='col col-12'>Total LOs: {numberOfLOs}</div>
            <div className='col col-12' >LOs with assigned guests: {getLoswithGuests()}</div>
        </div>
    );
};

export default LoSemiDesc;
