import React, { useState } from 'react';
import './guesttable.css';
import axios from 'axios';

const UpdateCheckInCheckOutModal = ({guestId, type}) => {
    const [dateValue, setDateValue] = useState("");
    const BASE_URL = process.env["REACT_APP_BASE_URL"];

    const updateTimings = (e) => {
        e.preventDefault();
        axios.post(BASE_URL+'/guests/updateCheckInOrCheckOutTiming', {
            guestId: guestId,
            timings: dateValue,
            type: type,
        },{
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
        })
        .then((response) => {
            console.log(response);
            alert("Date and Timings Updated");
        })
        .catch((err) => {
            console.log(err);
        })
    }

  return (
    <div className='id-card-container'>
      <form onSubmit={updateTimings} className='id-card-form'>
        <label>Update Date and Time</label>
        <input type="datetime-local" onChange={(e) => setDateValue(e.target.value)} />
        <input className='id-card-submit' type="submit" value="Update" />
      </form>
    </div>
  )
}

export default UpdateCheckInCheckOutModal
