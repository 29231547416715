import React, { useState, useEffect } from 'react'
import axios from 'axios';

const EditSubject = ({ subjectId }) => {

    const BASE_URL = process.env["REACT_APP_BASE_URL"]

    const [subjectTitle, setSubjectTitle] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const editSubject = (e) => {
        e.preventDefault();
        axios.post(`${BASE_URL}/subjects/updateSubject/${subjectId}`,
        {
            title: subjectTitle,
            timings: [startTime, endTime]
        }, {
            headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
        }
        )
        .then((response) => {
            console.log(response.data);
            alert("Subject Edited Successfully");
        })
        .catch((err) => {
            console.log(err);
        })
    }

  return (
    <>
        <form onSubmit={editSubject} className="add-subject-form">
            <label>Title</label>
            <input type="text" placeholder="Title" value={subjectTitle} onChange={(e) => setSubjectTitle(e.target.value)} />
            <label id="timing-label">Timings</label>
            <input type="datetime-local" placeholder="Start Time" onChange={(e) => setStartTime(e.target.value)} />
            <input type="datetime-local" placeholder="End Time" onChange={(e) => setEndTime(e.target.value)} />
            <input id="add-subject-button" type="submit" value="Update Subject" />
        </form>
    </>
  )
}

export default EditSubject;
