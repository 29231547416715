import React, { useEffect, useState, useContext } from 'react'
import "../Dashboard.css"
import { Card,Box,CardContent,Typography} from '@mui/material';
import { Context } from '../../MainPage';
import StarIcon from '@mui/icons-material/Star';
import {Chart as 
  ChartJS, 
  ArcElement, 
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js'
import { Doughnut,Bar } from 'react-chartjs-2';
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from 'chartjs-plugin-zoom';
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  ChartDataLabels,
  zoomPlugin,
  CategoryScale,
  LinearScale,
  BarElement,
)

export default function HotelDash(props) {

    const {hotelapi} = useContext(Context);

    const [hotelInformation,setHotelInfo] = useState({
        total : 0,
        noOfCategory : 0,
        noOfGrading : 0,
        noRoomsCount : 0,
        roomStatus : {
          available: 0,
          reserved : 0,
          occupied : 0,
          locked : 0,
        }
    })

    const [hotelApi,sethotelAPI] = useState(hotelapi);

    useEffect(()=>{
        let hotelObj = hotelApi.reduce((object,ele)=>{

                let tempCount = object.total;
                tempCount += 1;
        
                let temp_noOfCateogry = object.noOfCategory;
                if(!temp_noOfCateogry.hasOwnProperty(ele.category)){
                temp_noOfCateogry[ele.category] = 1;
                }else{
                temp_noOfCateogry[ele.category] += 1;
                }
        
                let temp_noOfGrading = object.noOfGrading
                if(!temp_noOfGrading.hasOwnProperty(ele.rating)){
                temp_noOfGrading[ele.rating] = 1;
                }else{
                temp_noOfGrading[ele.rating] += 1;
                }
        
                let roomCount = object.noRoomsCount;
                roomCount += ele.inventory[0];
        
                return {
                total : tempCount,
                noOfCategory : temp_noOfCateogry,
                noOfGrading : temp_noOfGrading,
                noRoomsCount : roomCount,
                roomStatus : {
                    Available: object.roomStatus.Available + ele.inventory[0],
                    Reserved : object.roomStatus.Reserved  + ele.inventory[1],
                    Occupied : object.roomStatus.Occupied  + ele.inventory[2],
                    Locked : object.roomStatus.Locked + ele.inventory[3],
                }
                }
            },{
                total : 0,
                noOfCategory : {},
                noOfGrading : {},
                noRoomsCount : 0,
                roomStatus : {
                Available: 0,
                Reserved : 0,
                Occupied : 0,
                Locked : 0,
                }
        })
        
        setHotelInfo(hotelObj);
    },[])

    const guestcardStyle = {
        backgroundColor : "var(--application-card-data-holder-green)",
        color : "var(--application-base-theme-secondary-color)",
        height : "40vh",
    }
    
      const guestGraphStyle = {
        backgroundColor : "var(--application-pastel-color-green)",
        color : "var(--application-base-theme-secondary-color)",
        height : "40vh",
        display : "flex",
        alignItems: "center",
        justifyContent : "center",
        flexWrap : "nowrap",
        padding : "10px",
    }

    const HotelCard = () =>{
        return (
          <React.Fragment>
            <CardContent>
              <h5>
                Hotel Information
              </h5>
              <h6>
                Basic Information
              </h6>
              <div className='row g-1'>
              <table className='data_primary_table'>
              <tr>
                <td>Total Number Of Hotel</td>
                <td>{hotelInformation.total}</td>
              </tr>
              <tr>
                <td>Total Number Of Rooms</td>
                <td>{hotelInformation.noRoomsCount}</td>
              </tr>
              <tr>
                <td>Total Number Of Category</td>
                <tr>
                  <table className='data_secondary_table'>
                    <tr>
                    {Object.keys(hotelInformation.noOfCategory).map((key,i)=>{
                          return (
                            <>
                              <td>
                                {key}&nbsp;:
                              </td>
                              <td>
                                {hotelInformation.noOfCategory[key]} 
                              </td>
                            </>
                          )
                        })}
                    </tr>
                  </table>
                </tr>
              </tr>
              <tr>
              <td>Total Number Of Grading</td>
                <tr>
                  <table className='data_secondary_table'>
                    <tr>
                    {Object.keys(hotelInformation.noOfGrading).map((key,i)=>{
                          return (
                            <>
                              <td>
                                {key} <StarIcon 
                                sx={{
                                  width : "1.2rem", 
                                  height:"auto",
                                  paddingBottom : "3px",
                                }} />&nbsp;:
                              </td>
                              <td>
                                {hotelInformation.noOfGrading[key]} &nbsp;&nbsp;&nbsp;
                              </td>
                            </>
                          )
                        })}
                    </tr>
                  </table>
                </tr>
              </tr>
              <tr>
              <td>Total Number Of Status</td>
                <tr>
                  <table className='data_secondary_table'>
                    <tr>
                    {Object.keys(hotelInformation.roomStatus).map((key,i)=>{
                          return (
                            <>
                              <td>
                                {key}&nbsp;:
                              </td>
                              <td>
                                {hotelInformation.roomStatus[key]} 
                              </td>
                            </>
                          )
                        })}
                    </tr>
                  </table>
                </tr>
              </tr>
            </table>
                <div className='col-4'>
                  <div className='graph_information_wrapper_div'>
                  </div>
                </div>
              </div>
            </CardContent>
          </React.Fragment>
        )
    }

    const hotelDougnutdata= {
        labels: ["Available " , "Reserved" , "Occupied" , "Locked"],
        datasets: [
            {
              label: 'Room Distribution',
              data: Object.keys(hotelInformation.roomStatus).map((key,i)=>hotelInformation.roomStatus[key]),
              backgroundColor: [
                // '#66c2a5',
                '#fc8d62',
                '#8da0cb',
                '#e78ac3',
                '#d0b25c',
              ],
              borderWidth: 1,
            }
        ]
    }

    const hotelCategoryBarData = {
        labels: Object.keys(hotelInformation.noOfCategory),
    datasets: [
        {
          label: 'Hotel Category',
          data: Object.keys(hotelInformation.noOfCategory).map((key,i)=>hotelInformation.noOfCategory[key]),
          backgroundColor: [
            // '#66c2a5',
            '#fc8d62',
            '#8da0cb',
            '#e78ac3',
            '#d0b25c',
          ],
          borderWidth: 1,
        }
    ]
    }

    const hotelRatingData = {
        labels: Object.keys(hotelInformation.noOfGrading),
    datasets: [
        {
          label: 'Hotel Rating',
          data: Object.keys(hotelInformation.noOfGrading).map((key,i)=>hotelInformation.noOfGrading[key]),
          backgroundColor: [
            // '#66c2a5',
            '#fc8d62',
            '#8da0cb',
            '#e78ac3',
            '#d0b25c',
          ],
          borderWidth: 1,
        }
    ]
    }

    return (
        <>
        <div className='row g-0'>
            <div className='row g-2'>
                <div className='col-4'>
                <Box width={"100%"}>
                    <Card
                        variant='outlined'
                        sx={guestGraphStyle}> 

                    <Doughnut
                        data={hotelDougnutdata}  
                        options={{
                        plugins: {
                          responsive : true,
                            legend: {
                              labels: {
                                  color: 'var(--application-base-theme-guest-graph-color)',
                                  font : { family : "Poppins"},
                              },
                              align : "center",
                              position :"top",
                              
                            },
                            datalabels: {
                              display: true,
                              color: 'var(--application-base-theme-guest-graph-color)',
                              font : {family : "Poppins"}
                            },
                            title: {
                                display: true,
                                text: "Rooms",
                                align: "center",
                                color : "var(--application-base-theme-guest-graph-color)",
                                font: {
                                size: 24,
                                family : "Poppins"
                                }
                            },
                        },
                        scales: {
                          x : {
                            ticks : { font : { family : "Poppins"}}
                          },
                          y :{
                            ticks : { font : { family : "Poppins"}}
                          },
                        }
                        }
                        }
                    />

                    </Card>
                </Box>
                </div>
                <div className='col-8'>
                <Box width={"100%"}>
                    <Card
                        variant='outlined'
                        sx={guestcardStyle}
                    > 
                    <HotelCard />
                    </Card>
                    </Box>
                </div>
            </div>
            <div className='row g-2'>
                <div className='col-6'>
                <Box width={"100%"}>
                    <Card
                    variant='outlined'
                    sx={guestGraphStyle}
                    > 
                    <Bar data={hotelCategoryBarData}
                    options={{
                    responsive : true,
                    plugins: {
                        legend: {
                        labels: {
                            color: 'var(--application-base-theme-guest-graph-color)',
                            font : {family : "Poppins"}
                        },
                        },
                        datalabels: {
                        display: true,
                        color: 'var(--application-base-theme-guest-graph-color)',
                        anchor : "end",
                        font : {family : "Poppins"},
                        },
                        title: {
                            display: true,
                            text: "Hotel Category",
                            align: "center",
                            color : "var(--application-base-theme-guest-graph-color)",
                            font: {
                            size: 24,
                            family : "Poppins"
                            }
                        },
                        zoom: {
                        pan: {
                            enabled: true,
                            mode: 'x',
                            speed : 5,
                        },
                        zoom: {
                            pinch: {
                                enabled: true       // Enable pinch zooming
                            },
                            wheel: {
                                enabled: true       // Enable wheel zooming
                            },
                            mode: 'x',
                        }
                        },
                    },
                    scales: {
                      x : {
                        ticks : { font : { family : "Poppins"}}
                      },
                      y :{
                        ticks : { font : { family : "Poppins"}}
                      },
                    }
                    }}/>

                    </Card>
                </Box>
                </div>
                <div className='col-6'>
                <Box width={"100%"}>
                    <Card
                    variant='outlined'
                    sx={guestGraphStyle}
                    > 
                <Bar data={hotelRatingData}
                    options={{
                    responsive : true,
                    plugins: {
                        legend: {
                        labels: {
                            color: 'var(--application-base-theme-guest-graph-color)',
                            font : {family : "Poppins"}
                        },
                        },
                        datalabels: {
                        display: true,
                        color: 'var(--application-base-theme-guest-graph-color)',
                        anchor : "end",
                        font : { family : "Poppins"}
                        },
                        title: {
                            display: true,
                            text: "Hotel Rating",
                            align: "center",
                            color : "var(--application-base-theme-guest-graph-color)",
                            font: {
                            size: 24,
                            family : "Poppins"
                            }
                        },
                        zoom: {
                        pan: {
                            enabled: true,
                            mode: 'x',
                            speed : 5,
                        },
                        zoom: {
                            pinch: {
                                enabled: true       // Enable pinch zooming
                            },
                            wheel: {
                                enabled: true       // Enable wheel zooming
                            },
                            mode: 'x',
                        }
                        },
                    },scales: {
                      x : {
                        ticks : { font : { family : "Poppins"}}
                      },
                      y :{
                        ticks : { font : { family : "Poppins"}}
                      },
                    }
                    }}/>
                    </Card>
                </Box>
                </div>
            </div>
            </div>
        </>
    )
}