/* eslint-disable react/style-prop-object */
import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import {
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
} from "mdb-react-ui-kit";
import QRCode from 'qrcode.react';
import html2canvas from "html2canvas";

import NLCLogo from "../../Assets/logo.png";
import NLCID_Background from "../../Assets/idBackground.png";
import { Margin } from "@mui/icons-material";
import { forwardRef } from "react";

export const ID = (props) => {
  const [modalStatus, status] = useState(true);
  const reportTemplateRef = useRef(null);
  const qrCodeRef = useRef(null);
  const [base64QRCode, setBase64QRCode] = useState('');
  const [displayBoolean, setDisplayBoolean] = useState(false);

  const parentNoHandler = () => {
    props.onClose();
  };

  const handleGeneratePdf = () => {
    html2canvas(qrCodeRef.current)
    .then((canvas) => {
      const base64Image = canvas.toDataURL('image/png');
      console.log(base64Image); // Display or use the generated Base64 image
      setBase64QRCode(base64Image);

      const doc = new jsPDF({
        format: "a4",
        unit: "px",
      });
  
      // Adding the fonts.
      doc.setFont("Inter-Regular", "normal");
  
      doc.html(reportTemplateRef.current, {
        async callback(doc) {
          await doc.save("document");
        },
      });
    })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <>
      <MDBModal
        show={modalStatus}
        tabIndex="-1"
        onHide={() => {
          parentNoHandler();
        }}
      >
        <MDBModalDialog className="modal-dialog-centered">
          <MDBModalContent>
            <MDBModalHeader>
              <button
                className="application_base_btn"
                onClick={() => {
                  handleGeneratePdf();
                }}
              >
                DOWNLOAD
              </button>
            </MDBModalHeader>
            <MDBModalBody>
              <div
                ref={reportTemplateRef}
                className="identity_card_div"
                style={{ paddingLeft: "34px" }}
              >
                <div
                  style={{
                    height: "630px",
                    width: "430px",
                    position: "relative",
                    top: "0",
                    left: "0",
                  }}
                >
                  <img
                    src={NLCID_Background}
                    alt="Background"
                    height={"630px"}
                    width={"auto"}
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />

                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  >
                    <img
                      src={NLCLogo}
                      height={"90"}
                      width={"90"}
                      style={{
                        position: "absolute",
                        top: "50px",
                        left: "60px",
                        borderRight: "solid thin ",
                      }}
                      alt="LOGO"
                    ></img>

                    <div
                      style={{
                        position: "relative",
                        top: "50px",
                        left: "160px",
                      }}
                    >
                      NATIONAL LEGISLATORS'
                      <br />
                      CONFERENCE <div></div> 2023
                    </div>

                    <img
                      alt="Profile"
                      src={props.user.image}
                      height={"120px"}
                      width={"120px"}
                      style={{
                        maxWidth: "150px",
                        position: "relative",
                        top: "100px",
                        left: "125px",
                        objectFit: "cover",
                        aspectRatio: "1",
                      }}
                    />

                    <div
                      style={{
                        position: "relative",
                        top: "150px",
                        left: "20px",
                      }}
                    >
                      {props.user.event_id}
                    </div>

                    <div
                      style={{
                        position: "relative",
                        top: "150px",
                        left: "20px",
                      }}
                    >
                      <b>Name</b>
                    </div>

                    <div
                      style={{
                        position: "relative",
                        top: "150px",
                        left: "20px",
                      }}
                    >
                      {props.user.name}
                    </div>

                    <div
                      style={{
                        position: "relative",
                        top: "150px",
                        left: "20px",
                      }}
                    >
                      <b>Liason Officer</b>
                    </div>

                    <div
                      ref={qrCodeRef}
                      style={{
                        position: "relative",
                        top: "170px",
                        left: "20px",
                      }}
                    >
                      {displayBoolean ? (
                        <img src={base64QRCode} alt="img" />
                      ) : (
                        <QRCode
                        size={90}
                        value={`${props.user.id}/${props.user.event_id}`}
                      />
                      ) }
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
