/* eslint-disable react/style-prop-object */
import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import {
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
} from "mdb-react-ui-kit";
import QRCode from 'qrcode.react';
import html2canvas from "html2canvas";

import NLCLogo from "../../Assets/logo.png";
import NLCID_Background from "../../Assets/idBackground.png";
import { Margin } from "@mui/icons-material";
import { forwardRef } from "react";

export const ID = (props) => {
  const [modalStatus, status] = useState(true);
  const reportTemplateRef = useRef(null);
  const qrCodeRef = useRef(null);
  const [base64QRCode, setBase64QRCode] = useState('');
  const [displayBoolean, setDisplayBoolean] = useState(false);

  const parentNoHandler = () => {
    props.onClose();
  };

  const handleGeneratePdf = () => {

    html2canvas(reportTemplateRef.current,{allowTaint:true, useCORS:true}).then((canvas)=>{
      // Few necessary setting options
			const imgWidth = 208;
			const pageHeight = 295;
			const imgHeight = canvas.height * imgWidth / canvas.width;
			const heightLeft = imgHeight;

			const contentDataURL = canvas.toDataURL('image/png');
			const pdf = jsPDF('p', 'px', 'a4'); // A4 size page of PDF
			const position = 0;
			pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
			pdf.save('document.pdf');
    })

  };

  return (
    <>
      <MDBModal
        show={modalStatus}
        tabIndex="-1"
        onHide={() => {
          parentNoHandler();
        }}
      >
        <MDBModalDialog className="modal-dialog-centered" size="lg" style={{width:"fit-content"}}>
          <MDBModalContent>
            <MDBModalHeader>
              <button
                className="application_base_btn"
                onClick={() => {
                  handleGeneratePdf();
                }}
              >
                DOWNLOAD
              </button>
            </MDBModalHeader>
            <MDBModalBody>
              <div
                ref={reportTemplateRef}
                className="identity_card_div"
                style={{ paddingLeft: "34px" }}
              >

                <div
                style={{
                  backgroundImage : `url(${NLCID_Background})`,
                  width:"540px",
                  height:"860px",
                  backgroundSize:"contain",
                  backgroundRepeat:"no-repeat",
                  paddingTop:"60px",
                  display:"flex",
                  flexDirection:"column",
                  alignItems:"center",
                  justifyContent:"flex-start",
                  flexWrap:"nowrap",
                }}>

                  <div
                    style={{
                      display : "flex",
                      alignItems: "center",
                      justifyContent:"center",
                    }}
                  >
                    <div>
                      <img
                        crossorigin="anonymous"
                        src={NLCLogo}
                        height={"90"}
                        width={"90"}
                        alt="LOGO"
                      />
                    </div>
                    <div 
                    className="verticle_divider"
                    style={{
                      marginInline:"10px",
                      width:"1px",
                      backgroundColor:"#3f3f3f",
                      height:"80px"
                    }}
                    />
                    <div
                      style={{
                        fontSize:"22px",
                        fontFamily:"Cinzel",
                      }}
                    >
                    NATIONAL LEGISLATORS'<br />CONFERENCE  2023
                    </div>
                  </div>

                  <img
                      crossorigin="anonymous"
                        alt="Profile"
                        src={props.user.image}
                        height={"175px"}
                        width={"175px"}
                        style={{
                          objectFit: "cover",
                          aspectRatio: "1",
                          borderRadius:"20px",
                          marginTop:"50px"
                        }}
                  />

                  <div
                    style={{
                      marginTop:"20px",
                      fontFamily:"Cinzel",
                      fontSize:"35px",
                      textAlign:"center",
                    }}
                  >
                      {props.user.name}
                  </div>

                  <div 
                    style={{
                      marginTop:"10px",
                      marginInline:"10px",
                      width:"420px",
                      backgroundColor:"#3f3f3f",
                      height:"1px"
                    }}
                    />

                  <div
                    style={{
                      marginTop:"20px",
                      fontFamily:"Cinzel",
                      fontSize:"20px",
                      textAlign:"center",
                    }}
                  >
                      {props.user.designation}
                  </div>

                  <div
                    style={{
                      fontFamily:"Cinzel",
                      fontSize:"15px",
                      textAlign:"center",
                    }}
                  >
                      {props.user.constituency}
                  </div>
                  
                  <div
                    style={{
                      marginTop:"50px",
                      padding:"10px",
                      borderRadius:"7px",
                      backgroundColor:"white",
                      display:"flex",
                      flexDirection:"column",
                      flexWrap:"nowrap",
                      alignItems:"center",
                      justifyContent:"flex-start",
                      fontSize:"10px",
                    }}
                  >
                    <div
                        ref={qrCodeRef}
                        style={{
                        }}
                      >
                        {displayBoolean ? (
                          <img src={base64QRCode} alt="img" />
                        ) : (
                          <QRCode
                          size={90}
                          value={`${props.user.id}/${props.user.event_id}`}
                        />
                        ) }
                    </div>
                    {props.user.event_id}
                  </div>

                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
