import React, { useContext, useState, useEffect } from "react";
import "./GuestDesc.css";
import { Context } from "../../MainPage";

const GuestSemiDesc = () => {
  const { guestapi } = useContext(Context);

  const [guestList, setGuestList] = useState(guestapi);
  const [guestInformation, setGuestInfo] = useState({
    total: 0,
    noOfdesignation: 0,
    noOfCategory: 0,
    noOfParties: 0,
    noOfGuestwithLo: 0,
    noOfGuestwithHotel: 0,
    noOfGuestwithVehicle: 0,
  });

  const GuestCard = () => {
    return (
      <React.Fragment>
        <div className="ms-2 me-2 row g-1 application_semi_description_bar_wrapper">
          <div className="row g-0">
            <div className="col-4">
              Total Number Of Guests : {guestInformation.total}
            </div>
            <div className="col-4">
              Total Number Of Designation :{" "}
              {Object.keys(guestInformation.noOfdesignation).length}
            </div>
            <div className="col-4">
              Total Number Of Parties :{" "}
              {Object.keys(guestInformation.noOfParties).length}
            </div>
          </div>
          <div className="row g-0">
            <div className="col-8">
              Total Number Of Categories :{" "}
              {Object.keys(guestInformation.noOfCategory)
                .sort((a, b) => {
                  if (a.toLowerCase() < b.toLowerCase()) return -1;
                  if (a.toLowerCase() > b.toLowerCase()) return 1;
                  return 0;
                })
                .map((key, i) => {
                  return (
                    <>
                      &nbsp;&nbsp;&nbsp;{key}:&nbsp;
                      {guestInformation.noOfCategory[key]}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    let guestObj = guestList.reduce(
      (object, ele) => {
        let totalCount = object.total;
        totalCount = totalCount + 1;

        let noOfdesignation_temp = object.noOfdesignation;

        if (!noOfdesignation_temp.hasOwnProperty(ele.designation)) {
          noOfdesignation_temp[ele.designation] = 1;
        } else {
          noOfdesignation_temp[ele.designation] += 1;
        }

        let noOfCategory_temp = object.noOfCategory;
        if (!noOfCategory_temp.hasOwnProperty(ele.category)) {
          noOfCategory_temp[ele.category] = 1;
        } else {
          noOfCategory_temp[ele.category] += 1;
        }

        let noOfParties_temp = object.noOfParties;
        if (!noOfParties_temp.hasOwnProperty(ele.party)) {
          noOfParties_temp[ele.party] = 1;
        } else {
          noOfParties_temp[ele.party] += 1;
        }

        let tempLoCount = object.noOfGuestwithLo;
        if (ele.lo !== null) {
          tempLoCount += 1;
        }
        let tempHotelCount = object.noOfGuestwithHotel;
        if (ele.hotel !== null) {
          tempHotelCount += 1;
        }
        let tempVehicleCount = object.noOfGuestwithVehicle;
        if (ele.vehicle !== null) {
          tempVehicleCount += 1;
        }

        return {
          total: totalCount,
          noOfdesignation: noOfdesignation_temp,
          noOfCategory: noOfCategory_temp,
          noOfParties: noOfParties_temp,
          noOfGuestwithLo: tempLoCount,
          noOfGuestwithHotel: tempHotelCount,
          noOfGuestwithVehicle: tempVehicleCount,
        };
      },
      {
        total: 0,
        noOfdesignation: {},
        noOfCategory: {},
        noOfParties: {},
        noOfGuestwithLo: 0,
        noOfGuestwithHotel: 0,
        noOfGuestwithVehicle: 0,
      }
    );
    setGuestInfo(guestObj);
  }, [guestList]);

  useEffect(() => {
    setGuestList(guestapi);
  }, [guestapi]);

  return (
    <>
      <GuestCard />
    </>
  );
};

export default GuestSemiDesc;
