import React, { useEffect, useState, useContext } from "react";
import "../Dashboard.css";
import { Card, Box, CardContent, Typography } from "@mui/material";
import { Context } from "../../MainPage";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  ChartDataLabels,
  zoomPlugin,
  CategoryScale,
  LinearScale,
  BarElement
);

export default function VehicleDash(props) {
  const { vehicleapi } = useContext(Context);

  const [vehicleApi, setvehicleAPI] = useState(vehicleapi);

  const VehicleCard = () => {
    return (
      <React.Fragment>
        <CardContent>
          <h5>Vehicle Information</h5>
          <h6>Basic Information</h6>
          <div className="row g-1">
            <table>
              <tr>
                <td>Total Number Of Vehicle</td>
                <td>{vehicleInformation.total}</td>
              </tr>
              <tr>
                <td>Total Number Of Guests</td>
                <td>{vehicleInformation.totalGuestAssigned}</td>
              </tr>
              <tr>
                <td>Total Number Of Vehicle Type</td>
                <td>{Object.keys(vehicleInformation.totalByType).length}</td>
              </tr>
            </table>
          </div>
        </CardContent>
      </React.Fragment>
    );
  };

  const vehicleGraphStyle = {
    backgroundColor: "var(--application-pastel-color-purple)",
    color: "var(--application-base-theme-secondary-color)",
    height: "40vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    padding: "10px",
  };

  const vehiclecardStyle = {
    backgroundColor: "var(--application-card-data-holder-purple)",
    color: "var(--application-base-theme-secondary-color)",
    height: "40vh",
  };

  const [vehicleInformation, setVehicleInfo] = useState({
    total: 0,
    totalGuestAssigned: 0,
    totalByType: 0,
  });

  const vehicleDougnutdata = {
    labels: ["GuestAssigned", "Free Vehicles"],
    datasets: [
      {
        label: "Total By Assignment",
        data: [
          vehicleInformation.totalGuestAssigned,
          vehicleInformation.total - vehicleInformation.totalGuestAssigned,
        ],
        backgroundColor: [
          "#66c2a5",
          "#fc8d62",
          "#8da0cb",
          // '#e78ac3',
          "#d0b25c",
        ],
        borderWidth: 1,
      },
    ],
  };
  const vehicleCategoryBarData = {
    labels: Object.keys(vehicleInformation.totalByType),
    datasets: [
      {
        label: "Total By Type",
        data: Object.keys(vehicleInformation.totalByType).map(
          (key, i) => vehicleInformation.totalByType[key]
        ),
        backgroundColor: [
          "#66c2a5",
          "#fc8d62",
          "#8da0cb",
          // '#e78ac3',
          "#d0b25c",
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    let vehicleObj = vehicleApi.reduce(
      (object, ele) => {
        let temp_total = object.total;
        temp_total += 1;

        let temp_totalGuestAssigned = object.totalGuestAssigned;
        if (ele.guest !== null) {
          temp_totalGuestAssigned += 1;
        }

        let temp_totalByType = object.totalByType;
        let temp_arr = ele.description.split(" ");
        temp_arr = temp_arr[temp_arr.length - 1];

        if (!temp_totalByType.hasOwnProperty(temp_arr)) {
          temp_totalByType[temp_arr] = 1;
        } else {
          temp_totalByType[temp_arr] += 1;
        }

        return {
          total: temp_total,
          totalGuestAssigned: temp_totalGuestAssigned,
          totalByType: temp_totalByType,
        };
      },
      {
        total: 0,
        totalGuestAssigned: 0,
        totalByType: {},
      }
    );

    setVehicleInfo(vehicleObj);
  }, []);

  return (
    <>
      <div className="row g-0">
        <div className="row g-2">
          <div className="col-4">
            <Box width={"100%"}>
              <Card variant="outlined" sx={vehicleGraphStyle}>
                <Doughnut
                  data={vehicleDougnutdata}
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          color:
                            "var(--application-base-theme-guest-graph-color)",
                          font: { family: "Poppins" },
                        },
                        align : "center",
                        position: "top",
                      },
                      datalabels: {
                        display: true,
                        color:
                          "var(--application-base-theme-guest-graph-color)",
                        font: { family: "Poppins" },
                      },
                      title: {
                        display: true,
                        text: "Vehicles",
                        align: "center",
                        color:
                          "var(--application-base-theme-guest-graph-color)",
                        font: {
                          size: 24,
                          family: "Poppins",
                        },
                      },
                    },
                    scales: {
                      x: {
                        ticks: { font: { family: "Poppins" } },
                      },
                      y: {
                        ticks: { font: { family: "Poppins" } },
                      },
                    },
                  }}
                />
              </Card>
            </Box>
          </div>
          <div className="col-8">
            <Box width={"100%"}>
              <Card variant="outlined" sx={vehiclecardStyle}>
                <VehicleCard />
              </Card>
            </Box>
          </div>
        </div>
        <div className="row g-2">
          <div className="col-12">
            <Box width={"100%"}>
              <Card variant="outlined" sx={vehicleGraphStyle}>
                <Bar
                  data={vehicleCategoryBarData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        labels: {
                          color:
                            "var(--application-base-theme-guest-graph-color)",
                          font : {family : "Poppins"},
                        },
                      },
                      datalabels: {
                        display: true,
                        color:
                          "var(--application-base-theme-guest-graph-color)",
                        anchor: "end",
                        font : { family : "Poppins"},
                      },
                      title: {
                        display: true,
                        text: "Vehicle Types",
                        align: "center",
                        color:
                          "var(--application-base-theme-guest-graph-color)",
                        font: {
                          size: 24,
                          family : "Poppins",
                        },
                      },
                      zoom: {
                        pan: {
                          enabled: true,
                          mode: "x",
                          speed: 5,
                        },
                        zoom: {
                          pinch: {
                            enabled: true, // Enable pinch zooming
                          },
                          wheel: {
                            enabled: true, // Enable wheel zooming
                          },
                          mode: "x",
                        },
                      },
                    },
                    scales: {
                      x : {
                        ticks : { font : { family : "Poppins"}}
                      },
                      y :{
                        ticks : { font : { family : "Poppins"}}
                      },
                    }
                  }}
                />
              </Card>
            </Box>
          </div>
          =
        </div>
      </div>
    </>
  );
}
