import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRowModes,
} from "@mui/x-data-grid";
import { Context } from "../../MainPage";
import Avatar from "@mui/material/Avatar";
import CustomToolBar from "../../Components/MuiToolBar/CustomToolBar";
import LoAssignGuest from "../../Components/UpdatesComponents/LoAssignGuest";
import { MDBIcon, MDBCol } from "mdb-react-ui-kit";
import ConfirmationModal from "../ConfirmationModal";
import axios from "axios";
import Editicon from "../../Assets/edit.svg";
import Doneicon from "../../Assets/done.svg";
import "./LoDesc.css";
import { tableStyle } from "../../Static/Styles";
import ImageModal from "../../Components/UpdatesComponents/ImageModal";
import { ID } from "../../Components/ID_Card/LoIdCard";

export default function LoMui({ lo, setLo }) {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const { guestapi, loapi, childCaller } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [ids, setIDs] = useState({
    display: false,
    data: { loID: { name: null, id: null }, guestId: { name: null, id: null } },
  });

  const [userIDselected,setUserID] = useState({display : false, user : null})
  const pdfGeneration = (params) =>{
    setUserID({display : true, user : params.row});
  }

  //TABLE CRUD STATES AND FUNCTIONS
  const [editedRow, setEditRow] = useState(false);
  const [editModeModel, setEditMode] = useState({});

  const [photoModal,setPhotoModal] = useState(false);
  const [selectedImage,setSelectedImage] = useState(false);
  const navigate = useNavigate();

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEdit = (ID) => {
    setEditMode({ ...editModeModel, [ID]: { mode: GridRowModes.Edit } });
  };
  const handleDone = (ID) => {
    setEditMode({ ...editModeModel, [ID]: { mode: GridRowModes.View } });
  };
  const handleCancelEdit = (ID) => {
    setEditMode({...editModeModel,[ID]: { mode: GridRowModes.View, ignoreModifications: true },});
    setEditRow({});
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setEditMode(newRowModesModel);
  };

  // value formatter function
  const valueFormatterFunction = (params) => {
      if (!params.value) {
        return "No Guest";
      } else {
        return guestName(params.value);
      }
  }

  useEffect(() => {
    setLo(loapi);
  }, [loapi, guestapi]);

  // render cell function
  const renderCellFunction = (params, i) => {
    const item = params.row;
    const guestNo = i === 1 ? 'guests' : `guests${i-1}`
        return (
          <>
            {item[guestNo] ? (
              <React.Fragment>
                <div onClick={() => {
                  handleShowModal(true, item.id, item.name);
                }}>{guestName(item[guestNo])}</div>
              </React.Fragment>
            ) : (
              <button
                onClick={() => {
                  handleShowModal(true, item.id, item.name);
                }}
                className="application_base_btn table_btn_padding"
                disabled={!checkGuestLo}
              >
                {`Add Guest ${i}`}
              </button>
            )}
          </>
        );
  }

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    axios
      .post(
        `${BASE_URL}/los/updateSoloLos`,
        {
          data: {
            name: newRow.name,
            contact: [newRow.contacts, newRow.email],
            id: newRow.id,
          },
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((res) => {
        alert("LO Updated Successfully");
        childCaller("Los");
      })
      .catch((err) => {
        console.log("LO ERROR", err);
      });

    setEditRow(newRow);
    return updatedRow;
  };

  // Delete Button
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState({
    data: { id: null, message: null },
  });

  const handleDeleteRowModal = (editValue) => {
    setDeleteData({
      data: {
        id: editValue,
        message: "Are you sure you want to delete this item?",
      },
    });

    setDeleteModal(true);
  };

  const handleDeleteRowCloseModal = () => {
    setDeleteModal(false);
  };

  const DeleteRowApi = (id) => {
    const guestsList = loapi.find((lo) => lo.id === id).data.guests;
    axios.post(`${BASE_URL}/los/deleteLo`,
        {
          guests: guestsList,
          id: id,
        },
        { headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") } }
      )
      .then((res) => {
        console.log(res.data);
        childCaller("Los");
        alert("LO Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const guestName = (searchID) => {
    const name = guestapi.find((e) => {
      return e.id === searchID;
    });
    return name?.name;
  };

  const handleShowModal = (bool, itemId, name) => {
    setIDs({
      display: true,
      data: { loId: { id: itemId, name: name }, guestId: null },
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleImageModalOpen = (e) =>{setSelectedImage(e);setPhotoModal(true)}
  const handleImageModalClose = () =>{setPhotoModal(false)}

  const [checkDelete, setCheckDelete] = useState(false);
  const [checkGuestLo, setCheckGuestLo] = useState(false);

  useEffect(()=>{
    console.log("Check MUI");
    try {
        let userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
      
        setCheckDelete(
          (("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
          ("LoAdmin" in userCheck && userCheck.Loadmin === true))
            ? true
            : false)
      
        // setCheckGuestLo(
        //   (("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
        //   ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true) ||
        //   ("LoAdmin" in userCheck && userCheck.Loadmin === true))
        //     ? true
        //     : false)
        setCheckDelete((userCheck["SuperAdmin"] || userCheck["LoAdmin"])? true : false);
        setCheckGuestLo((userCheck["LoAdmin"] || userCheck["GuestAdmin"] || userCheck["SuperAdmin"])? true : false);
    } catch (error) {
      navigate("/");
    }
  },[checkDelete,checkGuestLo])

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 55,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
    },
    {
      field: "button",
      headerName: "",
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      hideable: false,
      filterable: false,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      disableExport: true,
      renderCell: (params) => {
        const editCondition =
          editModeModel[params.id]?.mode === GridRowModes.Edit;
        return (
          <>
            {!editCondition ? (
              <button
                style={{
                  border: "none",
                  padding: "0",
                  backgroundColor: "transparent",
                  margin: "10px",
                }}
                disabled={checkDelete ? false : true}
              >
                <i
                  class="trashIcon fa-sharp fa-solid fa-trash"
                  onClick={() => handleDeleteRowModal(params.row.id)}
                ></i>
              </button>
            ) : (
              <button
                style={{
                  border: "none",
                  padding: "0",
                  backgroundColor: "transparent",
                  margin: "10px",
                }}
                disabled={checkDelete ? false : true}
              >
                <i
                  className="trashIcon fas fa-times fa-lg"
                  onClick={() => {
                    handleCancelEdit(params.id);
                  }}
                ></i>
              </button>
            )}
            {!editCondition ? (
              <img
                src={Editicon}
                alt=""
                onClick={() => {
                  if (checkDelete) handleEdit(params.id);
                }}
              />
            ) : (
              <img
                src={Doneicon}
                alt=""
                onClick={() => {
                  handleDone(params.id);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      headerClassName: "prime_table_header_tab",
      flex: 0.1,
      minWidth: 80,
      sortable: false,
      disableExport: true,
      headerAlign: "center",
      filterable: false,
      renderCell: (params) => <Avatar src={params.value} alt="product"  onClick={()=>{handleImageModalOpen(params.row)}} />,
    },
    {
      field: "name",
      headerName: "LO Name",
      headerClassName: "prime_table_header_tab",
      minWidth: 150,
      flex: 0.2,
      hideable: false,
      editable: true,
      headerAlign: "center",
    },
    {
      field: "contacts",
      headerName: "Contact No.",
      headerClassName: "prime_table_header_tab",
      minWidth: 150,
      flex: 0.2,
      editable: true,
      headerAlign: "center",
    },
    {
      field: "event_id",
      headerName: "NLC ID",
      headerClassName: "prime_table_header_tab",
      minWidth: 180,
      flex: 0.2,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email Address",
      headerClassName: "prime_table_header_tab",
      minWidth: 180,
      flex: 0.2,
      editable: true,
      headerAlign: "center",
    },
    {
      field: "requests",
      headerName: "Requests",
      headerClassName: "prime_table_header_tab",
      minWidth: 80,
      headerAlign: "center",
      flex: 0.1,
    },
    {
      field: "guests",
      headerName: "Guest 1",
      headerClassName: "prime_table_header_tab",
      minWidth: 140,
      headerAlign: "center",
      flex: 0.2,
      valueGetter: (params) =>{if(params.row.guests === null){return null }else{return guestName(params.row.guests)}},
      valueFormatter: (params) => valueFormatterFunction(params),
      renderCell: (params) => renderCellFunction(params,1),
    },
    {
      field: "guests1",
      headerName: "Guest 2",
      minWidth: 140,
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) =>{if(params.row.guests1 === null){return null }else{return guestName(params.row.guests1)}},
      valueFormatter: (params) => valueFormatterFunction(params),
      renderCell: (params) => renderCellFunction(params,2),
    },
    {
      field: "guests2",
      headerName: "Guest 3",
      minWidth: 140,
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) =>{if(params.row.guests2 === null){return null }else{return guestName(params.row.guests2)}},
      valueFormatter: (params) => valueFormatterFunction(params),
      renderCell: (params) => renderCellFunction(params,3),
    },
    {
      field: "guests3",
      headerName: "Guest 4",
      minWidth: 140,
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) =>{if(params.row.guests3 === null){return null }else{return guestName(params.row.guests3)}},
      valueFormatter: (params) => valueFormatterFunction(params),
      renderCell: (params) => renderCellFunction(params,4),
    },
    {
      field: "guests4",
      headerName: "Guest 5",
      minWidth: 140,
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) =>{if(params.row.guests4 === null){return null }else{return guestName(params.row.guests4)}},
      valueFormatter: (params) => valueFormatterFunction(params),
      renderCell: (params) => renderCellFunction(params,5),
    },
    {
      field: "guests5",
      headerName: "Guest 6",
      minWidth: 140,
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) =>{if(params.row.guests5 === null){return null }else{return guestName(params.row.guests5)}},
      valueFormatter: (params) => valueFormatterFunction(params),
      renderCell: (params) => renderCellFunction(params,6),
    },
    {
      field: "guests6",
      headerName: "Guest 7",
      minWidth: 140,
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) =>{if(params.row.guests6 === null){return null }else{return guestName(params.row.guests6)}},
      valueFormatter: (params) => valueFormatterFunction(params),
      renderCell: (params) => renderCellFunction(params,7),
    },
    {
      field: "guests7",
      headerName: "Guest 8",
      minWidth: 140,
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) =>{if(params.row.guests7 === null){return null }else{return guestName(params.row.guests7)}},
      valueFormatter: (params) => valueFormatterFunction(params),
      renderCell: (params) => renderCellFunction(params,8),
    },
    {
      field: "guests8",
      headerName: "Guest 9",
      minWidth: 140,
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) =>{if(params.row.guests8 === null){return null }else{return guestName(params.row.guests8)}},
      valueFormatter: (params) => valueFormatterFunction(params),
      renderCell: (params) => renderCellFunction(params,9),
    },
    {
      field: "guests9",
      headerName: "Guest 10",
      minWidth: 140,
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) =>{if(params.row.guests9 === null){return null }else{return guestName(params.row.guests9)}},
      valueFormatter: (params) => valueFormatterFunction(params),
      renderCell: (params) => renderCellFunction(params,10),
    },
    {
      field: "GuestID",
      headerName: "ID Card",
      width: "100",
      editable: false,
      headerAlign: "center",
      filterable: false,
      disableExport: true,
      headerClassName: "prime_table_header_tab",
      renderCell: (params) => {
        return (
          <button
            className="AddGuestbtn"
            onClick={() => {
              pdfGeneration(params)
            }}
            key={"Render_cells_PDF_ID" + params.id}
          >
            ID
          </button>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{ height: 500, width: "79.8vw", margin: "auto" }}
        className="flex-grow-1"
      >
        <DataGrid
          rows={lo.map((lo) => {
            return {
              edit: lo,
              delete: lo.id,
              id: lo.id,
              image : lo.data.image,
              name: lo.data.name,
              contacts: lo.data.contacts[0],
              event_id: lo.data.event_id,
              email: lo.data.contacts[1],
              requests: lo.data.requests ? lo.data.requests.length : 0,
              guests: lo.data.guests !== null && lo.data.guests[0],
              guests1: lo.data.guests !== null && lo.data.guests[1],
              guests2: lo.data.guests !== null && lo.data.guests[2],
              guests3: lo.data.guests !== null && lo.data.guests[3],
              guests4: lo.data.guests !== null && lo.data.guests[4],
              guests5: lo.data.guests !== null && lo.data.guests[5],
              guests6: lo.data.guests !== null && lo.data.guests[6],
              guests7: lo.data.guests !== null && lo.data.guests[7],
              guests8: lo.data.guests !== null && lo.data.guests[8],
              guests9: lo.data.guests !== null && lo.data.guests[9],
            };
          })}
          checkboxSelection
          columns={columns}
          editMode="row"
          onRowModesModelChange={handleRowModesModelChange}
          rowModesModel={editModeModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          getRowClassName={(params) => {
            return params.indexRelativeToCurrentPage % 2 === 0
              ? "mui_grid_table_even_row"
              : "mui_grid_table_odd_row";
          }}
          slots={{
            toolbar: CustomToolBar,
          }}
          sx={tableStyle}
        />
      </Box>

      {showModal && (
        <LoAssignGuest
          parentHandler={handleCloseModal}
          changesRet={() => {
            console.log("");
          }}
          setIDs={ids.data}
        />
      )}
      {deleteModal && (
        <ConfirmationModal
          parentHandler={handleDeleteRowCloseModal}
          data={deleteData}
          onConfirm={DeleteRowApi}
          type="LO"
        />
      )}

      {userIDselected.display && (<ID user={userIDselected.user} onClose={()=>{setUserID({display : false, user: null})}} />)}

      {photoModal && <ImageModal onClose={handleImageModalClose} object={selectedImage} typeImage={"LO"} />}
    </>
  );
}
