import React from "react";
import { useState, useEffect } from "react";
import { MDBInput, MDBCheckbox } from "mdb-react-ui-kit";
import "./NewLogin.css";
import Logo from "../Assets/logo.png";
import Bg from "../Assets/mainBg.jpeg";
import { useNavigate } from "react-router-dom";
import { app } from "../FirebaseConfig";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import ScreenLoader from "./ScreenLoader";

export const NewLogin = () => {
  const auth = getAuth(app);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signErr, setSignErr] = useState("");
  const [authUser, setAuthUser] = useState(true);
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      setAuthUser(true);
    } else if (user) {
      setAuthUser(false);
      navigateToDashboard();
    } else {
      setAuthUser(false);
    }
  }, [user, loading]);

  // State for spinning loader
  const [isLoading, setIsLoading] = useState(false);

  // State for storing error
  const [loginError, setLoginError] = useState("");

  const handleLogin = async () => {
    if (!email) {
      setSignErr("Email address is required");
      return;
    }
    if (!password) {
      setSignErr("Password is required");
      return;
    }
    //
    setIsLoading(true);
    setSignErr("");
    await signInWithEmailAndPassword(auth, email, password)
      .then((e) => {
        //
        sessionStorage.setItem("UUID", e.user.uid);
        setIsLoading(false);
      })
      .catch((err) => {
        //
        setIsLoading(false);
        setSignErr("Incorrect username/password");
        console.log(err);
      });
  };

  const navigateToDashboard = () => {
    navigate("./dashboard");
  };

  return (
    <>
      {authUser ? (
        <div className="min-vw-100 min-vh-100 d-flex justify-content-center align-items-center">
          <ScreenLoader />
        </div>
      ) : (
        <div
          className="main-bg"
          style={{
            background: `url(${Bg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="wrapper">
            <div className="logo-div">
              <img src={Logo} alt="NLC Logo" className="logo" />
            </div>
            <form onSubmit={(e)=>{e.preventDefault(); handleLogin()}}
                style={{width:"40vw"}}
            >
                
            <div className="form-div">
              <div>
                <MDBInput
                  wrapperClass="mb-4"
                  label="Email"
                  id="formControlLg"
                  type="email"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input"
                />
              </div>
              <div>
                <MDBInput
                  wrapperClass="mb-4"
                  label="Password"
                  id="formControlLg"
                  type="password"
                  size="lg"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input"
                />
              </div>
              <div className="error-div">
                <p className="text-danger">{signErr}</p>
              </div>
              <div className="loginBtn-div">
                {isLoading ? (
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <input
                    type="submit"
                    className="application_base_btn loginBtn"
                    name="Login"
                    id="LoginBtn"
                    value={"Login"}
                    style={{width:"fit-content"}}
                  >
                  </input>
                )}
              </div>
              <div className="rkit_logo_div">
                <h6>
                  <b> RootKit.exe</b>
                </h6>
              </div>
            </div>
            
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default NewLogin;
