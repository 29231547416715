import React, { useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import axios from 'axios';

export default function ConfirmationModal(props) {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];
  const [modalStatus, setStatus] = useState(true);

const parentNoHandler = () => {
  props.parentHandler(false)
  setStatus(false)
}
const parentYesHandler = () => {
  if(props.type === "Vehicle" || props.type === "LO" || props.type === "Guest") {
    props.onConfirm(props.data.data.id)
  }
  else {
  axios.post(`${BASE_URL}/misc/deleteContact`, {
    contact: props.data.data.contactDetails,
  }, {
    headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
  })
  .then((response) => {
    alert("Contact Deleted Successfully");
  })
  .catch((err) => {
    alert("Contact could not be Deleted");
  })
  }
  setStatus(false);
}

  return (
    <>
      <MDBModal show={modalStatus} tabIndex='-1' onHide={() => { parentNoHandler() }}>
      <MDBModalDialog className="modal-dialog-centered">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{props.data.data.message}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={parentNoHandler}></MDBBtn>
            </MDBModalHeader>


            <MDBModalFooter>
              <MDBBtn className='btn-danger' onClick={parentNoHandler}>
               No
              </MDBBtn>
              <MDBBtn onClick={parentYesHandler}>Yes</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}


