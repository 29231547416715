import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBBtn,
  MDBModalBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Box, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Context } from "../../MainPage";
import CustomToolBar from "../../Components/MuiToolBar/CustomToolBar";
import "../../Components/UpdatesComponents/RoomAssignGuest.css";
import axios from "axios";
import { loAssignStyle, roomsLoaderStyle, tableStyle } from "../../Static/Styles";

export default function FamilyRoomsModal(props) {
  const BASE_URL = process.env["REACT_APP_BASE_URL"]
  const [modalStatus, setStatus] = useState(true);

  const { guestapi, hotelapi, childCaller } = useContext(Context);

  const [guests, setGuests] = useState(guestapi);
  const [hotels, setHotels] = useState(hotelapi);
  const [rooms, setRooms] = useState([]);

  const [isRoomLoading, setIsRoomLoading] = useState(false);

  const [guestId, setGuestId] = useState("");
  const [hotelId, setHotelID] = useState("");
  const [roomId, setRoomId] = useState("");

  const [hotelName, setHotelName] = useState("");
  const [roomName, setRoomName] = useState("");
  const [guestName, setGuestName] = useState("");

  const [selectionTable1, setSelectionTable1] = useState([])
  const [selectionTable2, setSelectionTable2] = useState([])
  const [selectionTable3, setSelectionTable3] = useState([])

  const navigate = useNavigate();

//   useEffect(() => {
//     if(typeof props.setIDs.hotelId !== 'undefined' &&
//       props.setIDs.hotelId !== null &&
//       typeof props.setIDs.roomId !== 'undefined' &&
//       props.setIDs.roomId  !== null
//     ){
//       setHotelName(hotelapi.find((e)=>e.id === props.setIDs.hotelId).name);
//       getRoomsByHotelId(props.setIDs.roomId)
//       console.log(rooms);
//     }

//     if(typeof props.setIDs.guestId !== 'undefined' && props.setIDs.guestId !== null){
//       setGuestName(guestapi.find((e)=>e.id===props.setIDs.guestId).name);
//     }
//     setGuests(guestapi);
//     setHotels(hotelapi);
//   }, []);

  const parentHandler = () => {
    props.parentHandler(false);
    setStatus(false);
  };

  const handleHotelEntry = (value) => {
    setHotelID(value.hotel_id);
    setIsRoomLoading(true);
    getRoomsByHotelId(value.hotel_id);
    setHotelName(value.hotel_name);
  };

  const handleRoomEntry = (value) => {
    setRoomId(value.room_id);
    setRoomName(value.number);
  };

  const handleGuestEntry = (value) => {
    setGuestId(value.guest_id);
    setGuestName(value.guest_name);
  };

  const assignRoomToGuest = () => {
    setSelectionTable1([]);
    setSelectionTable2([]);
    setSelectionTable3([]);
 
    if(hotelId=="" || guestId=="" || roomId==""){
        alert("Please select the fields");
        return;
    }
    axios.post(`${BASE_URL}/rooms/allocateFamRoom`, {
        hotel_id: hotelId,
        guest_id: guestId,
        room_id: roomId
    }, {
      headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
    })
    .then((res) => {
        alert("Room assigned Successfully.");
        console.log(res.data);
        childCaller("Room_assigned");
    })
    .catch((err) => {
        console.log(err);
    })
  };

  const getRoomsByHotelId = (hotelId) => {
    setHotelID(hotelId);
    axios
      .get(`${BASE_URL}/rooms/getAvailableRooms/${hotelId}`, {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
      })
      .then((room) => {
        setRooms(room.data);
        setIsRoomLoading(false);
        console.log(room);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let userCheck;
  let check;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    check = (("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) || ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true)|| ("HotelAdmin" in userCheck && userCheck.HotelAdmin === true))? true : false;
  } catch (error) {
    navigate("/");
  }
  return (
    <MDBModal
      show={modalStatus}
      tabIndex="-1"
      onHide={() => {
        parentHandler();
      }}
    >
      <MDBModalDialog size="xl" style={{minWidth : "1440px"}}>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>LINK GUEST'S FAMILY, HOTELS AND ROOMS </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => {
                parentHandler();
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody style={{ height: "fit-content" }}>
            <MDBRow className="gx-1">
            <MDBCol size={4} className="px-1">
              <input style={{ border: "1px solid gray", borderRadius: "5px" }} type="text" placeholder="Guest Name" value={guestName} required />
                <Box
                  sx={{...loAssignStyle, marginTop: "15px", height: 455}}
                >
                  <DataGrid
                    columns={[
                      {
                        field : "Options",
                        headerName : "Pair",
                        flex:1,
                        headerAlign : "center",
                        headerClassName : "prime_table_header_tab",
                        minWidth : "120",
                        renderCell : (cellValue)=>{
                          return(<>
                          <button
                            className="application_base_btn  table_btn_padding"
                            onClick={() => handleGuestEntry(cellValue.row)}
                          >
                          Pair
                          </button>
                          </>)
                        }
                      },
                      {
                        field: "guest_name", 
                        headerName: "Guest Name", 
                        flex : 1,
                        minWidth : 150,
                        headerClassName : "prime_table_header_tab",
                        headerAlign : "center",
                        renderCell: (cellValue) => {
                          return (
                            <div className="mt-3" style={{ cursor: "pointer" }} onClick={() => handleGuestEntry(cellValue.row)}><p>{cellValue.row.guest_name}</p></div>
                            )
                          }
                      },
                      {
                        field : "constituency",
                        headerName : "Constituency",
                        headerClassName : "prime_table_header_tab",
                        headerAlign : "center",
                        flex : 1,
                        minWidth : 220,
                      },
                      {
                        field : "event_id",
                        headerName : "Event_ID",
                        headerClassName : "prime_table_header_tab",
                        headerAlign : "center",
                        flex : 1,
                        minWidth : 180,
                      },
                      {
                        field : "party",
                        headerName : "Party",
                        headerClassName : "prime_table_header_tab",
                        headerAlign : "center",
                        flex : 1,
                        minWidth : 180,
                      }
                    ]}   
                    rows={
                      guests?.map((guest) => {
                        return {
                          guest_id: guest.id,
                          guest_name: guest.name,
                          event_id : guest.event_id,
                          constituency : guest.constituency,
                          party : guest.party,
                        }
                      })
                    }
                    getRowClassName={(params) => {
                      return params.indexRelativeToCurrentPage % 2 === 0
                        ? "mui_grid_table_even_row"
                        : "mui_grid_table_odd_row";
                    }}
                    getRowId={(row) => row.guest_id}
                    slots={{ toolbar: CustomToolBar }}
                    sx={tableStyle}
                    rowSelectionModel={selectionTable3}
                    onRowSelectionModelChange={setSelectionTable3}
                  />
                </Box>
              </MDBCol>
              <MDBCol size={4} className="px-2">
                <input style={{ border: "1px solid gray", borderRadius: "5px" }} type="text" placeholder="Hotel Name" value={hotelName} required />
                <Box
                  sx={{
                    height: 450,
                    marginTop: "20px",
                    marginBottom: "20px"
                  }}
                >
                  <DataGrid
                    columns={[
                      {
                        field : "Options",
                        headerName : "RoomList",
                        flex:0.5,
                        headerAlign : "center",
                        headerClassName : "prime_table_header_tab",
                        minWidth : "80",
                        renderCell : (cellValue)=>{
                          return(<>
                          <button
                            className="application_base_btn  table_btn_padding"
                            onClick={() => handleHotelEntry(cellValue.row)}
                          >
                            Open List
                          </button>
                          </>)
                        }
                      },
                      {
                        field: "hotel_name", 
                        headerName: "Hotel Name", 
                        flex:  2, 
                        headerAlign : "center", 
                        headerClassName : "prime_table_header_tab",
                        minWidth : 180,
                        renderCell: (cellValue) => {
                        return (
                          <div className="mt-3" style={{ cursor: "pointer" }} onClick={() => handleHotelEntry(cellValue.row)}>{cellValue.row.hotel_name}</div>
                        )
                      }},
                      {
                        field : "hotel_available",
                        headerName : "Rooms Available",
                        flex : 0.4,
                        minWidth : 80,
                        headerAlign : "center",
                        headerClassName : "prime_table_header_tab",
                      },
                      {
                        field : "hotel_locked",
                        headerName : "Rooms Locked",
                        flex : 0.4,
                        minWidth : 80,
                        headerAlign : "center",
                        headerClassName : "prime_table_header_tab",
                      },
                    ]}

                    rows={
                      hotels?.map((hotel) => {
                        return {
                          hotel_id: hotel.id,
                          hotel_name: hotel.name,
                          hotel_available : hotel.inventory[0],
                          hotel_locked : hotel.inventory[3],
                        }
                      })
                    }

                    sx={tableStyle}
                    getRowId={(row) => row.hotel_id}
                    slots={{ toolbar: CustomToolBar }}
                    getRowClassName={(params) =>{
                      return params.indexRelativeToCurrentPage % 2 === 0 ? "mui_grid_table_even_row" : "mui_grid_table_odd_row";
                    }}
                    rowSelectionModel={selectionTable1}
                    onRowSelectionModelChange={setSelectionTable1}
                  />
                </Box>
              </MDBCol>
              <MDBCol size={4} className="px-2">
              <input style={{ border: "1px solid gray", borderRadius: "5px" }} type="text" placeholder="Room Name" value={roomName} required />
                <Box
                  sx={{
                    height: 450,
                    marginTop: "20px",
                    marginBottom: "20px"
                  }}
                >
                  {isRoomLoading ? (
                    <CircularProgress
                      sx={roomsLoaderStyle}
                    />
                  ) : (
                    <DataGrid
                    columns={[
                      {
                        field : "Options",
                        headerName : "Pair",
                        flex:1,
                        headerAlign : "center",
                        headerClassName : "prime_table_header_tab",
                        minWidth : "80",
                        renderCell : (cellValue)=>{
                          return(<>
                          <button
                            className="application_base_btn  table_btn_padding"
                            onClick={() => handleRoomEntry(cellValue.row)}
                          >
                          Pair
                          </button>
                          </>)
                        }
                      },
                      {
                        field: "number", 
                        headerName: "Room Number", 
                        flex : 1,
                        minWidth : 90, 
                        headerClassName : "prime_table_header_tab",
                        headerAlign : "center", 
                        renderCell: (cellValue) => {
                          return (
                              <div className="mt-3" style={{ cursor: "pointer" }} onClick={() => handleRoomEntry(cellValue.row)}><p>{cellValue.row.number}</p></div>
                        )
                      }},
                      {
                        field: "type", 
                        headerName: "Type", 
                        flex : 1,
                        minWidth : 150,
                        headerClassName : "prime_table_header_tab",
                        headerAlign : "center"
                      },
                      {
                        field: "occupancy", 
                        headerName: "Occupancy", 
                        flex : 0.5,
                        maxWidth : 150, 
                        headerClassName : "prime_table_header_tab",
                        headerAlign : "center"
                      },
                    ]}
                    
                    rows={
                      rooms?.map((room) => {
                        return {
                          room_id: room.id,
                          number: room.no,
                          type: room.type,
                          occupancy : room.occupancy_size,
                        }
                      })
                    }
                    sx={tableStyle}
                    getRowId={(row) => row.room_id}
                    slots={{ toolbar: CustomToolBar }}
                    getRowClassName={(params) =>{
                      return params.indexRelativeToCurrentPage % 2 === 0 ? "mui_grid_table_even_row" : "mui_grid_table_odd_row";
                    }}
                    rowSelectionModel={selectionTable2}
                    onRowSelectionModelChange={setSelectionTable2}
                  />
                  )}
                </Box>
              </MDBCol>
              
            </MDBRow>
            <button
              style={{ float: "right" }}
              onClick={assignRoomToGuest}
              className="application_base_btn update_header_bars_btn"
              disabled={check? false: true}
            >
              Assign
            </button>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
