import React from 'react'
import './VehicleDesc.css'

const VehicleSemiDesc = ({ vehicleArray }) => {
    const totalVehicles = vehicleArray ? vehicleArray.length : 0;
  
    const assignedGuests = vehicleArray ? vehicleArray.filter(vehicle => vehicle.guest !== undefined && vehicle.guest !== null).length : 0;
  
    const driversWithContact = vehicleArray ? vehicleArray.filter(vehicle => vehicle.driver_contact !== undefined && vehicle.driver_contact !== null).length : 0;
  
    return (
      <div className="application_semi_description_bar_wrapper row g-0">
        <div className='col col-6'>Total Vehicles: {totalVehicles}</div>
        <div className='col col-6'>Vehicles with Guests Assigned : {assignedGuests}</div>
        <div className='col col-6'>Drivers with Contact Information: {driversWithContact}</div>
      </div>
    );
}

export default VehicleSemiDesc;
