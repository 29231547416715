import React, { useState } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import axios from "axios";

// Firebase onSnaphsot imports
import { collection, onSnapshot, query, getFirestore } from "@firebase/firestore";

import Dashboard from "./Pages/Dashboard";
import Hotels from "./Pages/HotelPages/Hotels";
import Guests from "./Pages/GuestPages/Guests";
import Vehicle from "./Pages/VehiclePages/Vehicle";
import RequestPage from './Pages/RequestPage/RequestPage';
// import Login from "./Pages/Login";
import NewLogin from "./Pages/NewLogin";
import Lo from "./Pages/LOPages/Lo";
import LoDesc from "./Pages/LOPages/LoDesc";
import AddLos from "./Pages/LOPages/AddLos";
import AddGuests from "./Pages/GuestPages/AddGuests";
import AddHotels from "./Pages/HotelPages/AddHotels";
import AddVehicles from "./Pages/VehiclePages/AddVehicles";
// import ScreenLoader from "../ScreenLoader";

import ContactsPage from "./Pages/ContactsPage/ContactPage"
import Sidebar from "./Components/Sidebar";
import GuestDesc from "./Pages/GuestPages/GuestDesc";
import Media from "./Pages/Media/Media";
import HotelsDesc from "./Pages/HotelPages/HotelsDesc";

import { app } from "./FirebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, createContext } from "react";
import SessionPage from "./Pages/SessionsPage/SessionPage";

import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

// Audio Import 
import audioAlert from './Static/Audio/alert.mp3';

import { Snackbar, Alert } from "@mui/material";
export const Context = createContext();

export default function MainPage(props) {
  
  const BASE_URL = process.env["REACT_APP_BASE_URL"]

  const auth = getAuth(app);
  const [user, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(true);
  const [guestapi, setGuestAPI] = useState([]);
  const [hotelapi, setHotelAPI] = useState([]);
  const [loapi, setLoAPI] = useState([]);
  const [vehicleapi, setVehicleAPI] = useState([]);
  const[contacts, setContacts] = useState([])
  const [adminInfo,setAdminInfo] = useState([]);
  const [requests,setRequests] = useState([]);
  const [pendingCount,setPendingCount] = useState('');
  const [dataLoaderPop,setDataLoaderpop] = useState(false);
  const [emergencySnack,setEmergencySnack] = useState(false);

  const db = getFirestore(app);

  const location = useLocation();
  const navigate = useNavigate();
  const logout = () => {
    signOut(auth);
    navigate("/");
    sessionStorage.clear();
    localStorage.clear();
  };

  const childCaller = (RefreshedCalledFrom) =>{
    console.log("Inside ChildCaller");
    setDataLoaderpop(true)
    if(RefreshedCalledFrom === "Guest"){
      console.log("Guest called from ChildCaller");
      // getRequests();
      getUsers();
    }
    else if(RefreshedCalledFrom === "Hotels") {
      getHotels();
    }
    else if(RefreshedCalledFrom === "Vehicles") {
      getVehicles();
    }
    else if(RefreshedCalledFrom === "Los") {
      getLos();
    }
    else if(RefreshedCalledFrom === "Lo_assigned") {
      getLos();
      getUsers();
    }
    else if(RefreshedCalledFrom === "Room_assigned") {
      getHotels();
      getUsers();
    }
    else if(RefreshedCalledFrom === "Vehicle_assigned") {
      getVehicles();
      getUsers();
    }
    else if(RefreshedCalledFrom === "Contacts"){
      getContacts();
    }
    else if(RefreshedCalledFrom === "All") {
      console.log("Inside All");
      getUsers();
      getRequests();
      getLos();
      getHotels();
      getVehicles();
      getContacts();
    }
  }

  // Get all users
  const getUsers = () => {

        axios
      .get(BASE_URL+"/guests/getGuests", {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
      })
      .then((res) => {
        setLoader(false);
        setGuestAPI(res.data);
        localStorage.setItem("unSortedGuests", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    onSnapshot(query(collection(db, "Guests")), (snapShot) => {
      childCaller("Guest");
    });
    onSnapshot(query(collection(db, "Requests")), (snapShot) => {
      getRequests();
    });
    onSnapshot(query(collection(db, "Hotels")), (snapShot) => {
      childCaller("Hotels");
      childCaller("Room_assigned");
    });
    onSnapshot(query(collection(db, "Vehicles")), (snapShot) => {
      childCaller("Vehicles");
      childCaller("Vehicle_assigned");
    });
    onSnapshot(query(collection(db, "Los")), (snapShot) => {
      childCaller("Los");
      childCaller("Lo_assigned");
    });
    onSnapshot(query(collection(db, "Misc")), (snapShot) => {
      childCaller("Contacts");
    });
  },[])

  const getLos = () => {
    axios
      .get(BASE_URL+"/los/getLos", {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
      })
      .then((res) => {
        setLoAPI(res.data);
        localStorage.setItem("unSortedLOs", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log("LO ERROR",err);
      });
  };
  const getHotels = () => {
    axios
      .get(`${BASE_URL}/hotels/getHotels`, {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
      })
      .then((res) => {
        setHotelAPI(res.data);
        setLoader(false);
        localStorage.setItem("unSortedHotels", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getVehicles = () => {
    axios
      .get(`${BASE_URL}/vehicles/getVehicles`, {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
      })
      .then((res) => {
        setLoader(false);
        setVehicleAPI(res.data);
        localStorage.setItem("unSortedVehicles", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAdminInfo = () =>{
    let uuid = sessionStorage.getItem("UUID");
    axios
      .get(`${BASE_URL}/admin/getAdminInfo/${uuid}` ,{
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token")},
      })
      .then((res) => {
        setAdminInfo(res.data);
        sessionStorage.setItem("adminInfo",JSON.stringify(res.data))
        console.log("MAINPAGE:VALUE ",res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getRequests = () => {
    axios
      .get(BASE_URL+"/requests/getPendingRequests", {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
      })
      .then((res) => {
        let count = 0
        setRequests(res.data)

        res.data.map((req)=>{
          if(req.status === "Pending"){
            if(req.target === "Emergency"){
                setEmergencySnack(true);
                var audio = new Audio(audioAlert);
                audio.play();
            }
            count++;
          }
        })
        setPendingCount(count)
      })
      .catch((e) => {
        console.log("Request Page Axios Error : ", e);
      });
  }

  const getContacts = () => {
    axios
      .get(BASE_URL+"/misc/getContacts", {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
      })
      .then((res) => {
        setContacts(res.data)
      })
      .catch((e) => {
        console.log("Contact Page Axios Error : ", e);
      });
  }

  const handleSnackbar = () =>{setDataLoaderpop(false)}
  const emergencySnackbar = () =>{setEmergencySnack(false)};

  useEffect(() => {
    console.log("Security Check");
    if (user) {
      setDataLoaderpop(true)
      user
        .getIdTokenResult()
        .then((e) => {
          sessionStorage.setItem("Auth_Token", JSON.stringify(e));
          sessionStorage.setItem("User_Permission", JSON.stringify(e.claims));
          if(("Admin" in e.claims && e.claims.Admin) === false){
            logout();
          } 
          getUsers();
          getLos();
          getHotels();
          getVehicles();
          getAdminInfo();
          getRequests();
          getContacts();
        })
        .catch((e) => console.log(e));
    } else {
      navigate("/");
    }
  }, [user]);

  return (
    <div className="dashboard d-flex dashboardmain">
      <Context.Provider value={{ guestapi, hotelapi, loapi, vehicleapi, adminInfo, requests, pendingCount, contacts, childCaller }}>
        <Routes>
          <Route exact path="/" element={<NewLogin />} />
          <Route
            path="/"
            element={
              <div className="d-flex w-100">
                <Sidebar />
                <Outlet />
              </div>
            }
          >
            {/* DashBoard URls */}
            <Route path="dashboard" element={<Dashboard />} />
            {/*PARENT AND ADDITION POPUPS URLs*/}
            <Route path="hotels" element={<Hotels />}>
              <Route exact path="add_hotels" element={<AddHotels />} />
            </Route>
            <Route exact path="guests" element={<Guests />}>
              <Route exact path="add_guests" element={<AddGuests />} />
            </Route>
            <Route exact path="vehicles" element={<Vehicle />}>
              <Route exact path="add_vehicles" element={<AddVehicles />} />
            </Route>
            <Route exact path="los" element={<Lo />}>
              <Route exact path="add_los" element={<AddLos />} />
            </Route>
            {/*ROUTES TO INDIVIDUAL DESCRIPTION URLs  naviagte(url,state:{})*/}
            <Route exact path="loDesc/:id" element={<LoDesc />} />
            <Route exact path="guestDesc/:id" element={<GuestDesc />} />
            <Route exact path="hotelDesc/:id" element={<HotelsDesc />} />
            {/*Travel Itinerary Route  */}
            <Route exact path="travelItinerary" element={<Media />} />
            <Route exact path="/sessions" element={<SessionPage />} />
            <Route exact path="/requests" element={<RequestPage />} />
            <Route exact path="/contacts" element={<ContactsPage />} />
          </Route>
        </Routes>
      </Context.Provider>
      <Snackbar
      open={dataLoaderPop}
      autoHideDuration={4000}
      onClose={handleSnackbar}
      key={"Datamodal"}
      anchorOrigin={{"vertical" : "bottom", "horizontal" : "center"}}
      >
        <Alert severity="info">Data refreshing</Alert>
      </Snackbar>
      <Snackbar
      open={emergencySnack}
      autoHideDuration={3000}
      onClose={emergencySnackbar}
      key={"EmergencyModal"}
      anchorOrigin={{"vertical" : "top", "horizontal" : "left"}}
      >
        <Alert severity="warning">Emergency Request</Alert>
      </Snackbar>
    </div>
  );
}
