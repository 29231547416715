import React, { useEffect, useState, useContext } from "react";
import "../Dashboard.css";
import { Card, Box, CardContent, Typography } from "@mui/material";
import { Context } from "../../MainPage";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  ChartDataLabels,
  zoomPlugin,
  CategoryScale,
  LinearScale,
  BarElement
);

export default function GuestDash(props) {
  const { guestapi } = useContext(Context);

  const [guestList, setGuestList] = useState(guestapi);
  const [guestInformation, setGuestInfo] = useState({
    total: 0,
    noOfdesignation: 0,
    noOfCategory: 0,
    noOfParties: 0,
    noOfGuestwithLo: 0,
    noOfGuestwithHotel: 0,
    noOfGuestwithVehicle: 0,
    noOfGuestVideoByte: 0,
  });

  const guestcardStyle = {
    backgroundColor: "var(--application-card-data-holder-red)",
    color: "var(--application-base-theme-secondary-color)",
    height: "40vh",
  };

  const guestGraphStyle = {
    backgroundColor: "var(--application-pastel-color-red)",
    color: "var(--application-base-theme-secondary-color)",
    height: "40vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    padding: "10px",
  };

  const GuestCard = () => {
    return (
      <React.Fragment>
        <CardContent>
          <h5>Guest Information</h5>
          <h6>Basic Information</h6>
          <div className="row g-1">
            <table className="data_primary_table">
              <tr>
                <td>Total Number Of Guests</td>
                <td>{guestInformation.total}</td>
              </tr>
              <tr>
                <td>Total Number Of Categories</td>
                <tr>
                  <table className="data_secondary_table">
                    <tr>
                      {Object.keys(guestInformation.noOfCategory)
                        .sort((a, b) => {
                          if (a.toLowerCase() < b.toLowerCase()) return -1;
                          if (a.toLowerCase() > b.toLowerCase()) return 1;
                          return 0;
                        })
                        .map((key, i) => {
                          return (
                            <>
                              <td>{key}&nbsp;:</td>
                              <td>{guestInformation.noOfCategory[key]}</td>
                            </>
                          );
                        })}
                    </tr>
                  </table>
                </tr>
              </tr>
              <tr>
                <td>Total Number Of Designation</td>
                <td>{Object.keys(guestInformation.noOfdesignation).length}</td>
              </tr>
              <tr>
                <td>Total Number Of Parties</td>
                <td>{Object.keys(guestInformation.noOfParties).length}</td>
              </tr>
              <tr>
                <td>Total Number Of Guests With Videobyte Completed</td>
                <td>{guestInformation.noOfGuestVideoByte}</td>
              </tr>
            </table>
          </div>
        </CardContent>
      </React.Fragment>
    );
  };

  const guestDougnutdata = {
    labels: Object.keys(guestInformation.noOfCategory).sort((a, b) => {
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
      return 0;
    }),
    datasets: [
      {
        label: "Category Of Guest Distribution",
        data: Object.keys(guestInformation.noOfCategory)
          .sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) return -1;
            if (a.toLowerCase() > b.toLowerCase()) return 1;
            return 0;
          })
          .map((key, i) => guestInformation.noOfCategory[key]),
        backgroundColor: [
          "#66c2a5",
          "#fc8d62",
          "#8da0cb",
          // '#e78ac3',
          "#d0b25c",
        ],
        borderWidth: 1,
      },
    ],
  };

  const guestPartyBarData = {
    labels: Object.keys(guestInformation.noOfParties),
    datasets: [
      {
        label: "Guest Party Distribution",
        data: Object.keys(guestInformation.noOfParties).map(
          (key, i) => guestInformation.noOfParties[key]
        ),
        backgroundColor: [
          "#66c2a5",
          "#fc8d62",
          "#8da0cb",
          // '#e78ac3',
          "#d0b25c",
        ],
        borderWidth: 1,
      },
    ],
  };

  const guestCategoryData = {
    labels: Object.keys(guestInformation.noOfdesignation),
    datasets: [
      {
        label: "Guest Designation Distribution",
        data: Object.keys(guestInformation.noOfdesignation).map(
          (key, i) => guestInformation.noOfdesignation[key]
        ),
        backgroundColor: [
          "#66c2a5",
          "#fc8d62",
          "#8da0cb",
          // '#e78ac3',
          "#d0b25c",
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    let guestObj = guestList.reduce(
      (object, ele) => {
        let totalCount = object.total;
        totalCount = totalCount + 1;

        let noOfdesignation_temp = object.noOfdesignation;

        if (!noOfdesignation_temp.hasOwnProperty(ele.designation)) {
          noOfdesignation_temp[ele.designation] = 1;
        } else {
          noOfdesignation_temp[ele.designation] += 1;
        }

        let noOfCategory_temp = object.noOfCategory;
        if (!noOfCategory_temp.hasOwnProperty(ele.category)) {
          noOfCategory_temp[ele.category] = 1;
        } else {
          noOfCategory_temp[ele.category] += 1;
        }

        let noOfParties_temp = object.noOfParties;
        if (!noOfParties_temp.hasOwnProperty(ele.party)) {
          noOfParties_temp[ele.party] = 1;
        } else {
          noOfParties_temp[ele.party] += 1;
        }

        let tempLoCount = object.noOfGuestwithLo;
        if (ele.lo !== null) {
          tempLoCount += 1;
        }
        let tempHotelCount = object.noOfGuestwithHotel;
        if (ele.hotel !== null) {
          tempHotelCount += 1;
        }
        let tempVehicleCount = object.noOfGuestwithVehicle;
        if (ele.vehicle !== null) {
          tempVehicleCount += 1;
        }

        let tempVideobyteCounter = object.noOfGuestVideoByte;
        if(ele.videobyte === true){
          console.log(ele.videobyte,object.noOfGuestVideoByte, tempVideobyteCounter);
          tempVideobyteCounter += 1;
          console.log(tempVideobyteCounter)
        }

        return {
          total: totalCount,
          noOfdesignation: noOfdesignation_temp,
          noOfCategory: noOfCategory_temp,
          noOfParties: noOfParties_temp,
          noOfGuestwithLo: tempLoCount,
          noOfGuestwithHotel: tempHotelCount,
          noOfGuestwithVehicle: tempVehicleCount,
          noOfGuestVideoByte: tempVideobyteCounter,
        };
      },
      {
        total: 0,
        noOfdesignation: {},
        noOfCategory: {},
        noOfParties: {},
        noOfGuestwithLo: 0,
        noOfGuestwithHotel: 0,
        noOfGuestwithVehicle: 0,
        noOfGuestVideoByte: 0,
      }
    );
    setGuestInfo(guestObj);
  }, [guestList]);

  useEffect(() => {
    setGuestList(guestapi);
  }, [guestapi]);

  return (
    <>
      <div className="row g-0">
        <div className="row g-2">
          <div className="col-4">
            <Box width={"100%"}>
              <Card variant="outlined" sx={guestGraphStyle}>
                <Doughnut
                  data={guestDougnutdata}
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          color:
                            "var(--application-base-theme-guest-graph-color)",
                          font: "Poppins",
                        },
                        position: "right",
                      },
                      datalabels: {
                        display: true,
                        color:
                          "var(--application-base-theme-guest-graph-color)",
                        font: {
                          family: "Poppins",
                        },
                      },
                      title: {
                        display: true,
                        text: "Guest Category",
                        align: "center",
                        color:
                          "var(--application-base-theme-guest-graph-color)",
                        font: {
                          size: 24,
                          family: "Poppins",
                        },
                      },
                    },
                    scales: {
                      x: {
                        ticks: { font: { family: "Poppins" } },
                      },
                      y: {
                        ticks: { font: { family: "Poppins" } },
                      },
                    },
                  }}
                />
              </Card>
            </Box>
          </div>
          <div className="col-8">
            <Box width={"100%"}>
              <Card variant="outlined" sx={guestcardStyle}>
                <GuestCard />
              </Card>
            </Box>
          </div>
        </div>
        <div className="row g-2">
          <div className="col-6">
            <Box width={"100%"}>
              <Card variant="outlined" sx={guestGraphStyle}>
                <Bar
                  data={guestPartyBarData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        labels: {
                          color:
                            "var(--application-base-theme-guest-graph-color)",
                          font: {
                            family: "Poppins",
                          },
                        },
                      },
                      datalabels: {
                        display: true,
                        color:
                          "var(--application-base-theme-guest-graph-color)",
                        anchor: "end",
                        font: {
                          family: "Poppins",
                        },
                      },
                      title: {
                        display: true,
                        text: "Guest Party",
                        align: "center",
                        color:
                          "var(--application-base-theme-guest-graph-color)",
                        font: {
                          size: 24,
                          family: "Poppins",
                        },
                      },
                      zoom: {
                        pan: {
                          enabled: true,
                          mode: "x",
                          speed: 5,
                        },
                        zoom: {
                          pinch: {
                            enabled: true, // Enable pinch zooming
                          },
                          wheel: {
                            enabled: true, // Enable wheel zooming
                          },
                          mode: "x",
                        },
                      },
                    },
                    scales: {
                      x: {
                        ticks: { font: { family: "Poppins" } },
                      },
                      y: {
                        ticks: { font: { family: "Poppins" } },
                      },
                    },
                  }}
                />
              </Card>
            </Box>
          </div>
          <div className="col-6">
            <Box width={"100%"}>
              <Card variant="outlined" sx={guestGraphStyle}>
                <Bar
                  data={guestCategoryData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        labels: {
                          color:
                            "var(--application-base-theme-guest-graph-color)",
                          font: {
                            family: "Poppins",
                          },
                        },
                      },
                      datalabels: {
                        display: true,
                        color:
                          "var(--application-base-theme-guest-graph-color)",
                        anchor: "end",
                        font: {
                          family: "Poppins",
                        },
                      },
                      title: {
                        display: true,
                        text: "Guest Designation",
                        align: "center",
                        color:
                          "var(--application-base-theme-guest-graph-color)",
                        font: {
                          size: 24,
                          family: "Poppins",
                        },
                      },
                      zoom: {
                        pan: {
                          enabled: true,
                          mode: "x",
                          speed: 5,
                        },
                        zoom: {
                          pinch: {
                            enabled: true, // Enable pinch zooming
                          },
                          wheel: {
                            enabled: true, // Enable wheel zooming
                          },
                          mode: "x",
                        },
                      },
                    },
                    scales: {
                      x: {
                        ticks: { font: { family: "Poppins" } },
                      },
                      y: {
                        ticks: { font: { family: "Poppins" } },
                      },
                    },
                  }}
                />
              </Card>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}
