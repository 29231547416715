import React, { useState, useContext, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { Context } from "../../MainPage";
import Box from "@mui/material/Box";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import CustomToolBar from "../../Components/MuiToolBar/CustomToolBar";
import { tableStyle } from "../../Static/Styles";
import axios from "axios";

export default function DisplayGuestModal(props) {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];
  const [modalStatus, setStatus] = useState(true);
  const [flipTables, setFlipTable] = useState(false);
  const [guestList, setGuestList] = useState([]);
  const [unregisteredList, setUnregisteredList] = useState([]);
  const [assignGuestList, setAssignGuestList] = useState([]);
  const [removeGuestList, setRemoveGuestList] = useState([]);
  const { guestapi } = useContext(Context);

  const parentHandler = () => {
    props.parentHandler(false);
    setStatus(false);
  };

  useEffect(() => {
    let temp = guestapi.filter((e) => {
      if (props.guest.includes(e.id)) {
        return e;
      }
    });

    let temp2 = guestapi.filter((e)=>{
      let flag = false
      e.sessions.forEach((element)=>{
        if(element.includes(props.subjectID)){
          console.log(element);
          flag = true;
        }
      })
      if(flag === false){
        return e;
      }
    })
    setUnregisteredList(temp2);
    setGuestList(temp);

  }, [guestapi]);

  const assignGuesttoSession = () =>{
    axios.post(BASE_URL + "/sessions/assignGuests",
    {
      guestList : assignGuestList,
      sessionID : props.sessionID,
      subjectID : props.subjectID,
    },
    {
      headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
    }).then(()=>{
      alert("Guest Assigned Successfully");
      props.parentHandler();
    }).catch((err)=>{
      console.error(err);
    })
  }

  const removeGuestSession = () =>{
    axios.post(BASE_URL + "/sessions/removeGuests",
    {
      guestList : removeGuestList,
      sessionID : props.sessionID,
      subjectID : props.subjectID,
    },
    {
      headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
    }).then(()=>{
      alert("Guest Removed Successfully");
      props.parentHandler();
    }).catch((err)=>{
      console.error(err);
    })
  }

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 55,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
    },
    {
      field: "name",
      headerClassName: "prime_table_header_tab",
      headerName: "Name",
      hideable: false,
      editable: true,
      minWidth: 150,
      flex: 0.5,
      headerAlign: "center",
    },
    {
      field: "category",
      headerClassName: "prime_table_header_tab",
      headerName: "Category",
      hideable: false,
      editable: true,
      minWidth: 50,
      flex: 0.5,
      headerAlign: "center",
    },
    {
      field: "designation",
      headerClassName: "prime_table_header_tab",
      headerName: "Designation",
      hideable: false,
      editable: true,
      minWidth: 150,
      flex: 0.5,
      headerAlign: "center",
    },
    {
      field: "event_id",
      headerClassName: "prime_table_header_tab",
      headerName: "Event_id",
      hideable: false,
      editable: true,
      minWidth: 150,
      flex: 0.5,
      headerAlign: "center",
    },
    {
      field: "party",
      headerClassName: "prime_table_header_tab",
      headerName: "Party",
      hideable: false,
      editable: true,
      minWidth: 150,
      flex: 0.5,
      headerAlign: "center",
    },
    {
      field: "constituency",
      headerClassName: "prime_table_header_tab",
      headerName: "Constituency",
      hideable: false,
      editable: true,
      minWidth: 150,
      flex: 0.5,
      headerAlign: "center",
    },
  ];

  return (
    <>
      <MDBModal
        show={modalStatus}
        tabIndex="-1"
        onHide={() => {
          parentHandler();
        }}
      >
        <MDBModalDialog scrollable size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <div className="MDB_Modal_Header_Wrapper">
                <MDBModalTitle>Session List</MDBModalTitle>

                {flipTables && 
                <div> 
                  Guests Selected : {assignGuestList.length}  
                </div>}

                {!flipTables && 
                <div> 
                  Guests Selected : {removeGuestList.length}  
                </div>
                }

                <div>

                {flipTables && 
                  <button className="application_base_btn update_header_bars_btn"
                    onClick={()=>{assignGuesttoSession()}}
                  >Assign Guest List</button>
                }

                {!flipTables &&
                  <button className="application_base_btn update_header_bars_btn"
                  onClick={()=>{removeGuestSession()}}
                  >Remove Guest List</button>
                }

                <button className="application_base_btn update_header_bars_btn"
                  onClick={()=>{setFlipTable(!flipTables)}}>
                  {flipTables ? "Guest List" : "Add Guests"}
                </button>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  style={{ margin: 0 }}
                  onClick={() => {
                    parentHandler();
                  }}
                ></MDBBtn>
                </div>
              </div>
            </MDBModalHeader>
            <MDBModalBody>
              <Box sx={{ height: 600, width: "100%" }} className="flex-grow-1">

                {flipTables? 
                <>
                <DataGrid
                    rows={unregisteredList.map((e) => {
                      return {
                        id: e.id,
                        category: e.category,
                        constituency: e.constituency,
                        designation: e.designation,
                        event_id: e.event_id,
                        name: e.name,
                        party: e.party,
                      };
                    })}
                    columns={columns}
                    slots={{ toolbar: CustomToolBar }}
                    editMode="row"
                    getRowClassName={(params) => {
                      return params.indexRelativeToCurrentPage % 2 === 0
                        ? "mui_grid_table_even_row"
                        : "mui_grid_table_odd_row";
                    }}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      if(newRowSelectionModel.length <= props.totalSeats){
                        setAssignGuestList(newRowSelectionModel);
                      }
                    }}
                    getRowId={ele => ele.id}
                    rowSelectionModel={assignGuestList}
                    sx={tableStyle}
                    checkboxSelection
                  />
                </> 
                : 
                <>
                  <DataGrid
                    rows={guestList.map((e) => {
                      return {
                        id: e.id,
                        category: e.category,
                        constituency: e.constituency,
                        designation: e.designation,
                        event_id: e.event_id,
                        name: e.name,
                        party: e.party,
                      };
                    })}
                    columns={columns}
                    slots={{ toolbar: CustomToolBar }}
                    editMode="row"
                    rowSelectionModel={removeGuestList}
                    getRowId={ele => ele.id}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRemoveGuestList(newRowSelectionModel);
                    }}
                    getRowClassName={(params) => {
                      return params.indexRelativeToCurrentPage % 2 === 0
                        ? "mui_grid_table_even_row"
                        : "mui_grid_table_odd_row";
                    }}
                    sx={tableStyle}
                    checkboxSelection
                  />
                </>}
              </Box>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
