import { initializeApp } from "firebase/app";
import { initializeAppCheck,ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
    apiKey: process.env["REACT_APP_APIKEY"],
    authDomain: process.env["REACT_APP_AUTHDOMAIN"],
    projectId: process.env["REACT_APP_PROJECTID"],
    storageBucket: process.env["REACT_APP_STORAGE_BUCKET"],
    messagingSenderId: process.env["REACT_APP_MESSAGEING_SENDER_ID"],
    appId: process.env["REACT_APP_APPLICATION_ID"],
    measurementId: process.env["REACT_APP_MEASUREMENT_ID"]
  };
  
const checkapp = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(checkapp, {
    provider: new ReCaptchaV3Provider(process.env["REACT_APP_APP_CHECK_KEY"]),
  
    isTokenAutoRefreshEnabled: true
});

const app = appCheck.app
export { app }
