import React from "react";
import { useState, useEffect, useContext } from "react";
import { app } from "../../FirebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Box,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Context } from "../../MainPage";
import "./Media.css";

// Firebase Imports for storage
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import MediaForGuests from "./MediaForGuests";
import MediaForBroadcastMessage from "./MediaForBroadcastMessage";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import axios from "axios";
import MediaForSessions from "./MediaForSessions";
import { mediaStatesStyle } from "../../Static/Styles";
import MediaForPressDocs from "./MediaForPressDocs";

const Media = () => {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const auth = getAuth(app);
  const [user] = useAuthState(auth);

  const [mediaFile, setMediaFile] = useState("");
  const [url, setUrl] = useState("");
  const [sendToValue, setSendToValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [mediaFileUploadComplete, setMediaFileUploadComplete] = useState(false);

  // Boolean state variables corresponding to the Send to values
  const [guestBoolean, setGuestBoolean] = useState(false);
  const [broadcastBoolean, setBrodacastBoolean] = useState(false);
  const [stateBoolean, setStateBoolean] = useState(false);
  const [sessionsBoolean, setSessionsBoolean] = useState(false);
  const [pressDocsBoolean, setPressDocsBoolean] = useState(false);

  const { guestapi } = useContext(Context);
  const [guests, setGuests] = useState(guestapi);
  const [statesObject, setStatesObject] = useState({});

  const [statesMediaArray, setStatesMediaArray] = useState([]);
  const [file, setFile] = useState("");

  const getStates = () => {
    let states_objects = {};
    guests?.forEach((guest) => {
      let state = guest.constituency.split(",");
      let states = state.map((ele) => {
        return ele.trim();
      });
      states_objects[states[states.length - 1]] = states[states.length - 1];
    });

    setStatesObject(states_objects);
  };

  const addMediaForStates = () => {
    if (mediaFile !== "") {
      setFile("file");
    }

    axios
      .post(
        `${BASE_URL}/media/uploadMedia`,
        {
          uuid: [],
          url: url,
          state: statesMediaArray,
          sessionId: "",
          subjectID: "",
          type: "state",
          file_type: mediaFile.type !== "video/mp4" ? "file" : mediaFile.type,
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((response) => {
        console.log(response);
        alert("Media Uploaded Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadMediaFile = () => {
    if (mediaFile !== "") {
      let storage = getStorage(app);
      const medialFileMetadata = {
        contentType: mediaFile.type,
      };

      const mediaFileRef = ref(storage, "Media/" + mediaFile.name);
      const uploadMediaFileTask = uploadBytesResumable(
        mediaFileRef,
        mediaFile,
        medialFileMetadata
      );

      uploadMediaFileTask.on(
        "state_changed",
        (snapshot) => {
          setIsLoading(true);
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadMediaFileTask.snapshot.ref).then(
            (downloadURL) => {
              setIsLoading(false);
              console.log(downloadURL);
              setUrl(downloadURL);
            }
          );
        }
      );
    } else {
      alert("Please choose a file before proceeding");
    }
    setMediaFileUploadComplete(true);
  };

  const addMedia = () => {
    if (sendToValue === "Guests") {
      setGuestBoolean(true);
      setBrodacastBoolean(false);
      setStateBoolean(false);
      setSessionsBoolean(false);
      setPressDocsBoolean(false)
    }

    if (sendToValue === "Broadcast Message") {
      setBrodacastBoolean(true);
      setGuestBoolean(false);
      setStateBoolean(false);
      setSessionsBoolean(false);
      setPressDocsBoolean(false)
    }

    if (sendToValue === "State") {
      getStates();
      console.log(statesObject);
      setStateBoolean(true);
      setPressDocsBoolean(false)
      setBrodacastBoolean(false);
      setGuestBoolean(false);
      setSessionsBoolean(false);
    }

    if (sendToValue === "Sessions") {
      setSessionsBoolean(true);
      setStateBoolean(false);
      setBrodacastBoolean(false);
      setGuestBoolean(false);
      setPressDocsBoolean(false)
    }

    if (sendToValue === "Press Docs") {
      setSessionsBoolean(false);
      setStateBoolean(false);
      setBrodacastBoolean(false);
      setGuestBoolean(false);
      setPressDocsBoolean(true)
    }
  };

  useEffect(() => {
    if (user) {
      if (user) {
        user
          .getIdTokenResult()
          .then(async (e) => {
            await sessionStorage.setItem("Auth_Token", JSON.stringify(e));
            await sessionStorage.setItem(
              "User_Permission",
              JSON.stringify(e.claims)
            );
          })
          .catch((e) => console.log(e));
      }
    }

    let uuid = sessionStorage.getItem("UUID");
    if (uuid === null) {
      let token = JSON.parse(sessionStorage.getItem("Auth_Token"));
      if (token !== null) {
        uuid = token.claims.user_id;
      }
    }
  }, []);

  useEffect(() => {
    setGuests(guestapi);
  }, [guestapi]);

  return (
    <>
      <div className="media_page_wrapper">
        <div className="container-fluid">
          <h3 className="fw-bold my-3 page_header_title_h3">Media</h3>
        </div>
        <Box display="flex">
          <div className="main-media-flex">
            <h5>Upload Media</h5>
            <div className="file-uploader">
              <div className="file-uploader-flex">
                <input
                  type="file"
                  onChange={(e) => {
                    setMediaFileUploadComplete(false)
                    setMediaFile(e.target.files[0])
                    setUrl('')
                    }
                  }
                />
                {isLoading ? (
                  <div className="loader-div">
                    <CircularProgress className="loader" />
                  </div>
                ) : (
                  <>
                    {mediaFileUploadComplete ? (
                      <i class="far fa-circle-check"></i>
                    ) : (
                      <button
                        className="application_base_btn max_media_width"
                        onClick={uploadMediaFile}
                      >
                        Upload File
                      </button>
                    )}
                  </>
                )}
              </div>
              <p>Or</p>
              <input
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                type="text"
                placeholder="Enter Url"
              />
            </div>
            <Divider
              sx={{ marginTop: "10px", width: "75vw", marginLeft: "10px" }}
            />
            <FormControl
              sx={{
                maxWidth: "20rem",
                margin: "auto",
                marginLeft: "20px",
                marginTop: "40px",
              }}
            >
              <FormLabel
                sx={{
                  fontWeight: "600",
                  marginBottom: "20px",
                  fontSize: "22px",
                }}
                id="demo-radio-buttons-group-label"
              >
                Send To
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={sendToValue}
                onChange={(e) => setSendToValue(e.target.value)}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Guests"
                  control={<Radio />}
                  label="Guests"
                />
                <FormControlLabel
                  value="Broadcast Message"
                  control={<Radio />}
                  label="Broadcast Message"
                  disabled = {mediaFile?.type === 'application/pdf' ? true : false}
                />
                <FormControlLabel
                  value="State"
                  control={<Radio />}
                  label="State"
                  disabled = {mediaFile?.type === 'application/pdf' ? true : false}
                />
                <FormControlLabel
                  value="Sessions"
                  control={<Radio />}
                  label="Sessions"
                  disabled = {mediaFile?.type === 'application/pdf' ? true : false}
                />
                {
                  mediaFile?.type === 'application/pdf' || url.trim().includes('.pdf') ? 
                  <FormControlLabel
                    value="Press Docs"
                    control={<Radio />}
                    label="Press Docs"
                  />
                  : null
                }
                
              </RadioGroup>
            </FormControl>
            <button
              style={{
                maxWidth: "100px",
                paddingBottom: "10px",
                margin: "auto",
                marginLeft: "17px",
                marginTop: "70px",
              }}
              className="btn btn-dark"
              disabled={url.startsWith("https") ? false : true}
              onClick={addMedia}
            >
              Proceed
            </button>
          </div>
          {/* <Divider style={{width:'40%', rotate: "90deg", position: "absolute", top: "50%", left: "35%"}} /> */}
        </Box>
        <div>
          {/* Set of all Right Handside Tables */}
          {guestBoolean === false &&
            broadcastBoolean === false &&
            stateBoolean === false &&
            sessionsBoolean === false &&
            pressDocsBoolean === false && (
              <p className="no-selection">Please select a type to send to</p>
            )}
          {guestBoolean && (
            <div className="guest-media">
              <MediaForGuests
                sendToValue={sendToValue}
                url={url}
                mediaFile={mediaFile}
              />
            </div>
          )}
          {broadcastBoolean && (
            <div className="broadcast-media">
              <MediaForBroadcastMessage url={url} mediaFile={mediaFile} />
            </div>
          )}
          {pressDocsBoolean && (
            <div className="broadcast-media">
              <MediaForPressDocs url={url} mediaFile={mediaFile} />
            </div>
          )}
          {stateBoolean && (
            <div
              style={{
                position: "absolute",
                top: "35%",
                left: "57%",
                width: "38%",
              }}
            >
              <h5>States</h5>
              <Box
                sx={{
                  height: 300,
                  width: 500,
                  border: "1px solid gray",
                  borderRadius: "5px",
                }}
              >
                <DataGrid
                  checkboxSelection={true}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setStatesMediaArray(newRowSelectionModel);
                  }}
                  rowSelectionModel={statesMediaArray}
                  columns={[
                    {
                      ...GRID_CHECKBOX_SELECTION_COL_DEF,
                      width: 55,
                      headerClassName: "prime_table_header_tab",
                      headerAlign: "center",
                    },
                    {
                      field: "state",
                      headerClassName: "super-app-theme--header",
                      headerName: "State",
                      headerAlign: "left",
                      width: 500,
                    },
                  ]}
                  rows={Object.keys(statesObject)?.map((key, index) => {
                    return {
                      id: index,
                      state: statesObject[key],
                    };
                  })}
                  getRowClassName={(params) => {
                    return params.indexRelativeToCurrentPage % 2 === 0
                      ? "mui_grid_table_even_row"
                      : "mui_grid_table_odd_row";
                  }}
                  sx={mediaStatesStyle}
                />
              </Box>
              <div>
                <div style={{ float: "left", marginTop: "10px" }}>
                  <p>Selected {statesMediaArray.length} states</p>
                </div>
              </div>
              <button
                style={{
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  marginTop: "100px",
                  float: "right",
                }}
                className="btn btn-dark"
                onClick={addMediaForStates}
              >
                Export
              </button>
            </div>
          )}
          {sessionsBoolean && (
            <div style={{ position: "absolute", top: "35%", left: "58%" }}>
              <MediaForSessions url={url} mediaFile={mediaFile} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Media;
