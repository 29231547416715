import React, { useEffect, useState, useContext } from "react";
import "../Dashboard.css";
import { app } from "../../FirebaseConfig";
import { Outlet, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import "firebase/firestore";
import { Context } from "../../MainPage";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import LoSemiDesc from "./LoSemiDesc";
import LoMui from "./LoMui";
import { MDBIcon } from "mdb-react-ui-kit";
const Lo = () => {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const auth = getAuth(app);
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  // Lo specific states
  const [loName, setLoName] = useState("");
  const [loader, setLoader] = useState(false);

  const { loapi, childCaller } = useContext(Context);
  const [Lo, setLo] = useState(loapi);
  const [searchTerm, setSearchTerm] = useState("");

  const [lo, setLoData] = useState(loapi);

  const handleAdanvedSearch = (e) => {
    e.preventDefault()
    if (searchTerm.trim().length === 0) {
      setLoData(loapi);
    } else {
      let tempList = loapi;
      let filteration = tempList.filter((e) => {
        return (
          e.data.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          e.data.contacts[0].toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setLoData(filteration);
    }
  };

  const handleAdancedClear = () => {
    setLoData(loapi);
    childCaller("Los");
  };

  const [pendingRequest, setPendingRequest] = useState([]);

  // State for LoDesc Display
  const [LoDescDisplay, setLoDescDisplay] = useState(false);
  const [documents, setDocuments] = useState([]);
  // Display setting function for LoDesc Display
  const DisplayLocDesc = (lo_name) => {
    setLoName(lo_name);

    setLoDescDisplay(true);
  };

  // Get all Los
  const getLos = () => {
    // TODO : Localhost To Deployed
    axios
      .get(`${BASE_URL}/requests/getPendingRequests`, {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
      })
      .then((res) => {
        setPendingRequest(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Search functionality
  const filterList = (searchParam) => {
    if (searchParam.trim().length === 0) {
      setLo(loapi);
    } else {
      let tempList = loapi;
      let filteration = tempList.filter((e) => {
        return (
          e.data.name.toLowerCase().includes(searchParam.toLowerCase()) ||
          e.data.contact[0].toLowerCase().includes(searchParam.toLowerCase())
        );
      });
      setLo(filteration);
    }
  };

  const onAdd = () => {
    const urlArr = window.location.pathname.split("/");
    const category = urlArr[urlArr.length - 1];
    navigate(`add_${category}`);
  };

  useEffect(() => {
    if (user) {
      if (user) {
        user
          .getIdTokenResult()
          .then(async (e) => {
            await sessionStorage.setItem("Auth_Token", JSON.stringify(e));
            await sessionStorage.setItem(
              "User_Permission",
              JSON.stringify(e.claims)
            );
          })
          .catch((e) => console.log(e));
      }
    }

    let uuid = sessionStorage.getItem("UUID");
    if (uuid === null) {
      let token = JSON.parse(sessionStorage.getItem("Auth_Token"));
      if (token !== null) {
        uuid = token.claims.user_id;
      }
    }

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) navigate("/");
    if (error) alert(error);
    getLos();
    setLo(loapi);
  }, [user, loading, navigate, loapi, Lo]);

  const tableChilderClick = (tabName) => {
    DisplayLocDesc(tabName);
  };

  let userCheck;
  let check;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    check =
      ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
      ("LoAdmin" in userCheck && userCheck.LoAdmin === true)
        ? true
        : false;
  } catch (error) {
    navigate("/");
  }

  return (
    <>
      <Outlet />
      <>
        {Lo.length === 0 ? (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div
              style={{
                flex: "1 1 auto",
                display: "flex",
                flexFlow: "column",
                height: "100vh",
                overflowY: "hidden",
              }}
            >
              <div className="container-fluid">
                <div className="w-100 ">
                  <div className="d-flex justify-content-between">
                    <h3 className="fw-bold my-3 page_header_title_h3">
                      Liaison Officers
                    </h3>
                    <button
                      className="mx-2"
                      style={{
                        border: "none",
                        background: "white",
                        color: "#222222",
                      }}
                      disabled={check ? false : true}
                      onClick={onAdd}
                    >
                      <i class="fas fa-plus-circle fa-2x"></i>
                    </button>
                  </div>
                  <LoSemiDesc loArray={Lo} PendingRequests={pendingRequest} />
                </div>
              </div>
              <form onSubmit={handleAdanvedSearch}>
              <div className="application_page_search_bar_wrapper ps-3 pe-3">
                <button
                type="submit"
                  onClick={handleAdanvedSearch}
                  className="application_base_btn btn_with_logo application_refresh_icon"
                >
                  <MDBIcon
                    fas
                    icon="search"
                    size="md"
                  />
                  <div>Search</div>
                </button>

                <input
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  type="text"
                  className="application_page_search_bar"
                  placeholder="Search"
                />

                <button
                type="button"
                  onClick={handleAdancedClear}
                  className="application_base_btn btn_with_logo application_refresh_icon"
                >
                  <MDBIcon
                    fas
                    icon="sync-alt"
                    size="md"
                    style={{
                      color: "white",
                    }}
                  />
                  <div>Refresh</div>
                </button>
              </div>
              </form>
              <div style={{ height: "100%" }}>
                <div
                  style={{
                    height: "calc(100%)",
                    overflowY: "scroll",
                    display: "flex",
                  }}
                >
                  <div className="ms-3 me-3 d-flex flex-column flex-grow-1">
                    <LoMui lo={lo} setLo={setLoData} PendingRequests={pendingRequest} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default Lo;
