import React from 'react';
import "./PairDisplay.css";

export default function PairDisplay(props){
    
        
      return (
        <>
            <div className='pair_display_holder'>
                <div>
                    Link {props.index}
                </div>
                <div className='pair_display_content_holder'>
                    <div className= {`pair_item_content_holder color_pallet_pair_${props.index}`}>
                        {props.pairOne}
                    </div>
                    <div>
                    --
                    </div>
                    <div className={`pair_item_content_holder color_pallet_pair_${props.index}`}>
                        {props.pairTwo}
                    </div>
                </div>
            </div>
        </>
    )
}