import React, { useState, useContext, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import "./guesttable.css";
import {
  DataGrid,
  GridRowModes,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import Editicon from "../../Assets/edit.svg";
import Doneicon from "../../Assets/done.svg";
import Avatar from "@mui/material/Avatar";
import { Context } from "../../MainPage";
import VehicleAssignGuest from "../UpdatesComponents/VehicleAssignGuest";
import LoAssignGuest from "../UpdatesComponents/LoAssignGuest";
import RoomAssignGuest from "../UpdatesComponents/RoomAssignGuest";
import GuestItenary from "../../Pages/GuestPages/GuestItenary";
import { ID } from "../ID_Card/ID";
import { useNavigate } from "react-router-dom";
import { MDBIcon } from "mdb-react-ui-kit";
import CustomToolBar from "../MuiToolBar/CustomToolBar";
import axios from "axios";
import ConfirmationModal from "../../Pages/ConfirmationModal";
import { tableStyle } from "../../Static/Styles";
import ImageModal from "../UpdatesComponents/ImageModal";
import DeallocationConfirmation from "./DeallocationConfirmation";
import { Modal } from "@mui/material";
import IDDocModal from "../UpdatesComponents/IDDocModal";
import UploadOrGetIdCardModal from "./UploadOrGetIdCardModal";
import UpdateCheckInCheckOutModal from "./UpdateCheckInCheckOutModal";

export default function GuestTable({ guestData }) {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const { guestapi, hotelapi, vehicleapi, loapi, childCaller } = useContext(
    Context
  );
  // const [vehicle, setVehicle] = useState(vehicleapi);
  const [data, setData] = useState(guestapi);
  const [deallocationBoolean, setDeallocationBoolean] = useState(false);
  const [vehicleDeallocationBoolean, setVehicleDeallocationBoolean] = useState(
    false
  );
  const [roomDeallocationBoolean, setRoomDeallicationBoolean] = useState(false);

  const [gId, setGid] = useState("");
  const [lId, setLid] = useState("");
  const [hId, setHid] = useState("");
  const [rId, setRid] = useState("");

  const handleCloseRoomDeallocationModal = (id) => {
    setRoomDeallicationBoolean(false);
    handleShowModalGtH(true, id);
  };

  const handleCloseDeallocationModal = (id, name) => {
    console.log(id);
    setDeallocationBoolean(false);
    handleShowModalGtL(true, id, name);
  };

  const handleCloseVehicleDeallocationModal = (id, name) => {
    setVehicleDeallocationBoolean(false);
    handleShowModalGtV(true, id, name);
  };

  let AdancedSearchParams = {
    name: null,
    constituency: null,
    designation: null,
    party: null,
  };

  const navigate = useNavigate();

  let nameInputAdvRef = useRef(null);
  let constituencyInputAdvRef = useRef(null);
  let designationInputAdvRef = useRef(null);
  let partyInputAdvRef = useRef(null);

  const [ids, setIDs] = useState({
    display: false,
    data: { vehicleID: null, guestId: null },
  });

  const [gtlIds, setgtlIds] = useState({
    display: false,
    data: { loId: null, guestId: null },
  });

  const [gthIds, setgthIds] = useState({
    display: false,
    data: {
      hotelId: null,
      guestId: null,
      roomId: null,
    },
  });

  const [travelDisplay, setTIdisplay] = useState(false);
  const [guestTravel, settravel] = useState({});

  //TABLE CRUD STATES AND FUNCTIONS
  const [editedRow, setEditRow] = useState(false);
  const [editModeModel, setEditMode] = useState({});

  // Modal For Update Photo
  const [photoModal, setPhotoModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);
  const [IdDocModal, setIdDocModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(false);
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // Modal for NLC ID Card Upload
  const [open, setOpen] = useState(false);
  const [nlcIdCard, setNlcIdCard] = useState("");
  const [guestId, setGuestId] = useState("");
  const handleOpen = (nlc_id_card, guest_id) => {
    console.log("Handle Open : ",nlc_id_card, guest_id);
    setGuestId(guest_id);
    setNlcIdCard(nlc_id_card);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [openTimings, setOpenTimings] = useState(false);
  const [checkInCheckOutType, setCheckInCheckOutType] = useState("");

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEdit = (params) => {
    setEditMode({ ...editModeModel, [params.id]: { mode: GridRowModes.Edit } });
  };
  const handleDone = (params) => {
    setEditMode({ ...editModeModel, [params.id]: { mode: GridRowModes.View } });
  };

  const handleCancelEdit = (ID) => {
    setEditMode({
      ...editModeModel,
      [ID]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    setEditRow({});
  };
  // Delete Button
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState({
    data: { id: null, message: null },
  });

  const handleDeleteRowModal = (editValue) => {
    console.log(editValue);
    setDeleteData({
      data: {
        id: editValue,
        message: "Are you sure you want to delete this item?",
      },
    });

    setDeleteModal(true);
  };

  const handleDeleteRowCloseModal = () => {
    setDeleteModal(false);
  };

  const DeleteRowApi = (id) => {
    console.log(id);
    const guestdoc = guestapi.find((guest) => guest.id === id);
    const guestLo = guestdoc.lo;
    const guestVehicle = guestdoc.vehicle;
    const guestHotel = guestdoc.hotel;
    const guestRoom = guestdoc.room;
    let date = Date.now();
    const [seconds, nanoseconds] = [Math.floor(date / 1000), 0];
    const session = guestdoc.sessions;

    if (
      guestHotel !== null &&
      guestHotel !== "" &&
      guestRoom !== null &&
      guestRoom !== ""
    ) {
      axios
        .post(
          `${BASE_URL}/rooms/userHotelRemove`,
          {
            guest_id: id,
            hotel_id: guestHotel,
            room_id: guestRoom,
            time_stamp: seconds.toString() + "," + nanoseconds.toString(),
          },
          { headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") } }
        )
        .then((res) => {
          console.log(res.data);
          alert("User checkout successfull");
        })
        .catch((err) => {
          console.log(err);
        });
    }

    axios
      .post(
        `${BASE_URL}/guests/deleteGuest/${id}`,
        {
          lo: guestLo,
          vehicle: guestVehicle,
          sessions: session,
        },
        { headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") } }
      )
      .then((res) => {
        console.log(res.data);
        alert("Guest deleted successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAdanvedSearch = () => {
    let tempGuest = guestapi;
    if (AdancedSearchParams.name != null) {
      tempGuest = tempGuest.filter((e) => {
        return e.name
          .trim()
          .toLowerCase()
          .includes(AdancedSearchParams.name.trim().toLowerCase());
      });
    }
    if (AdancedSearchParams.constituency != null) {
      tempGuest = tempGuest.filter((e) => {
        return e.constituency
          .trim()
          .toLowerCase()
          .includes(AdancedSearchParams.constituency.trim().toLowerCase());
      });
    }
    if (AdancedSearchParams.designation != null) {
      tempGuest = tempGuest.filter((e) => {
        return e.designation
          .trim()
          .toLowerCase()
          .includes(AdancedSearchParams.designation.trim().toLowerCase());
      });
    }
    if (AdancedSearchParams.party != null) {
      tempGuest = tempGuest.filter((e) => {
        return e.party
          .trim()
          .toLowerCase()
          .includes(AdancedSearchParams.party.trim().toLowerCase());
      });
    }
    setData(tempGuest);
  };

  const handleAdancedClear = () => {
    // Parent Caller
    childCaller("Guest");
    // Parent Caller

    AdancedSearchParams.name = null;
    nameInputAdvRef.current.value = "";
    AdancedSearchParams.constituency = null;
    constituencyInputAdvRef.current.value = "";
    AdancedSearchParams.party = null;
    partyInputAdvRef.current.value = "";
    AdancedSearchParams.designation = null;
    designationInputAdvRef.current.value = "";
    setData(guestapi);
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setEditMode(newRowModesModel);
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    axios
      .post(
        `${BASE_URL}/guests/updateSoloGuest`,
        {
          data: {
            id: newRow.id,
            name: newRow.name,
            contact: newRow.contacts,
            category: newRow.category,
            designation: newRow.designation,
            constituency: newRow.constituency,
            party: newRow.party,
            remarks: newRow.remarks,
            videobyte: newRow.videobyte,
          },
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((res) => {
        // window.location.reload();
        // childCaller("Guest");
        alert("Guest updated successfully");
      })
      .catch((err) => {
        console.log("GUEST ERROR", err);
      });
    setEditRow(newRow);
    return updatedRow;
  };

  const handleShowModalGtV = (bool, itemId, itemName) => {
    setIDs({
      display: true,
      data: {
        vehicleID: null,
        guestId: {
          id: itemId,
          name: itemName,
        },
      },
    });
  };

  const handleShowModalGtL = (bool, itemId, itemName) => {
    setgtlIds({
      display: true,
      data: {
        loId: null,
        guestId: {
          name: itemName,
          id: itemId,
        },
      },
    });
  };
  const handleShowModalGtH = (bool, itemId) => {
    setgthIds({
      display: true,
      data: { hotelId: null, guestId: itemId, roomId: null },
    });
  };

  const handleCloseModalGtV = () => {
    setIDs({
      display: false,
      data: {
        vehicleID: null,
        guestId: null,
      },
    });
  };

  const handleCloseModalGtL = () => {
    setgtlIds({ display: false, data: { loId: null, guestId: null } });
  };

  const handleCloseModalGtH = () => {
    setgthIds({
      display: false,
      data: { hotelId: null, guestId: null, roomId: null },
    });
  };

  const handleTravelModal = (rowdata) => {
    settravel({ id: rowdata.id, data: rowdata });
  };

  useEffect(() => {
    if (typeof guestTravel.id === "undefined") {
    } else {
      setTIdisplay(true);
    }
  }, [guestTravel]);

  useEffect(() => {
    setData(guestapi);
  }, [guestapi]);

  const loName = (searchID) => {
    const name = loapi.find((e) => {
      return e.id === searchID;
    });
    return name?.data.name;
  };

  const vehicleName = (searchID) => {
    const name = vehicleapi.find((e) => {
      return e.id === searchID;
    });
    return name?.name;
  };

  const hotelName = (searchID) => {
    const name = hotelapi.find((e) => {
      return e.id === searchID;
    });
    return name?.name;
  };

  const dateToFormatedDate = (dateObj) => {
    const yyyy = dateObj.getFullYear();
    let mm = dateObj.getMonth() + 1; // Months start at 0!
    let dd = dateObj.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday =
      dd + "/" + mm + "/" + yyyy + "  " + dateObj.toTimeString().slice(0, 9);
    return formattedToday;
  };

  const googleDateFormat = (dateObj) => {
    const yyyy = dateObj.getFullYear();
    let mm = dateObj.getMonth() + 1; // Months start at 0!
    let dd = dateObj.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday =
      mm + "/" + dd + "/" + yyyy + "  " + dateObj.toTimeString().slice(0, 9);
    return formattedToday;
  };

  let userCheck;
  let checkDelete;
  let checkGuestHotel;
  let checkGuestLo;
  let checkGuestVehicle;
  let checkGuestTravel;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    // O(n) -> O(1)
    checkDelete =
      ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
      ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true)
        ? true
        : false;

    checkGuestLo =
      ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
      ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true) ||
      ("LoAdmin" in userCheck && userCheck.LoAdmin === true)
        ? true
        : false;

    checkGuestHotel =
      ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
      ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true) ||
      ("HotelAdmin" in userCheck && userCheck.HotelAdmin === true)
        ? true
        : false;

    checkGuestVehicle =
      ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
      ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true) ||
      ("VehicleAdmin" in userCheck && userCheck.VehicleAdmin === true)
        ? true
        : false;

    checkGuestTravel =
      ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
      ("GuestAdmin" in userCheck && userCheck.GuestAdmin === true) ||
      ("TravelAdmin" in userCheck && userCheck.TravelAdmin === true)
        ? true
        : false;
  } catch (error) {
    navigate("/");
  }

  const handleImageModalOpen = (e) => {
    setSelectedImage(e);
    setPhotoModal(true);
  };
  const handleImageModalClose = () => {
    setPhotoModal(false);
  };

  // const [userIDselected, setUserID] = useState({ display: false, user: null });
  // const pdfGeneration = (params) => {
  //   setUserID({ display: true, user: params.row });
  // };

  const handleIdDocModalOpen = (e) => {
    setSelectedDoc(e);
    setIdDocModal(true);
  };
  const handleIdDocModalClose = () => {
    setIdDocModal(false);
  };

  const [userIDselected, setUserID] = useState({ display: false, user: null });
  const pdfGeneration = (params) => {
    setUserID({ display: true, user: params.row });
  };

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 55,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
    },
    {
      field: "button",
      headerName: "",
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      hideable: false,
      filterable: false,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      disableExport: true,
      renderCell: (params) => {
        const editCondition =
          editModeModel[params.id]?.mode === GridRowModes.Edit;
        return (
          <>
            {!editCondition ? (
              <button
                style={{
                  border: "none",
                  padding: "0",
                  backgroundColor: "transparent",
                  margin: "10px",
                }}
                disabled={checkDelete ? false : true}
              >
                <i
                  class="trashIcon fa-sharp fa-solid fa-trash"
                  onClick={() => {
                    handleDeleteRowModal(params.id);
                  }}
                ></i>
              </button>
            ) : (
              <button
                style={{
                  border: "none",
                  padding: "0",
                  backgroundColor: "transparent",
                  margin: "10px",
                }}
                disabled={checkDelete ? false : true}
              >
                <i
                  className="trashIcon fas fa-times fa-lg"
                  onClick={() => {
                    handleCancelEdit(params.id);
                  }}
                ></i>
              </button>
            )}
            {!editCondition ? (
              <img
                src={Editicon}
                alt=""
                onClick={() => {
                  if (checkDelete) handleEdit(params);
                }}
              />
            ) : (
              <img
                src={Doneicon}
                alt=""
                onClick={() => {
                  handleDone(params);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      sortable: false,
      headerAlign: "center",
      disableExport: true,
      headerClassName: "prime_table_header_tab",
      filterable: false,
      renderCell: (params) => (
        <Avatar
          src={params.value}
          alt="product"
          onClick={() => {
            handleImageModalOpen(params.row);
          }}
        />
      ),
    },
    {
      field: "id_doc",
      headerName: "ID Doc",
      sortable: false,
      headerAlign: "center",
      disableExport: true,
      headerClassName: "prime_table_header_tab",
      filterable: false,
      renderCell: (params) => {
        return (
          <button
            className="application_base_btn table_btn_padding"
            onClick={() => {
              handleIdDocModalOpen(params.row);
            }}
          >
            ID Doc
          </button>
        );
      },
    },
    {
      field: "videobyte",
      headerName: "Video Byte",
      sortable: true,
      headerAlign: "center",
      editable: true,
      headerClassName: "prime_table_header_tab",
      filterable: true,
      type: "boolean",
    },
    {
      field: "name",
      headerClassName: "prime_table_header_tab",
      headerName: "Name",
      hideable: false,
      editable: true,
      width: "150",
      headerAlign: "center",
    },
    {
      field: "designation",
      headerClassName: "prime_table_header_tab",
      headerName: "Designation",
      editable: true,
      headerAlign: "center",
      width: "250",
    },
    {
      field: "party",
      headerClassName: "prime_table_header_tab",
      headerName: "Pol. Party",
      editable: true,
      headerAlign: "center",
      width: "200",
    },
    {
      field: "category",
      headerClassName: "prime_table_header_tab",
      headerName: "Category",
      headerAlign: "center",
      editable: true,
    },
    {
      field: "constituency",
      headerClassName: "prime_table_header_tab",
      headerName: "Constituency",
      headerAlign: "center",
      editable: true,
      width: "400",
    },
    {
      field: "lo",
      headerName: "LO",
      width: "150",
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) => {
        if(params.row.lo === null){
          return null;
        }else{
          return loName(params.row.lo);
        }
      },
      valueFormatter: (params) => {
        if (params.value === null) {
          return "No Lo";
        } else {
          return loName(params.value);
        }
      },
      renderCell: (params) => {
        const item = params.row;
        return (
          <span key={"Render_cells_lo_" + params.id}>
            {item.lo ? (
              <>
                {deallocationBoolean ? (
                  <Modal
                    sx={{
                      ".MuiModal-backdrop": {
                        backgroundColor: "rgba(0, 0, 0, 0.10)",
                      },
                    }}
                    open={deallocationBoolean}
                    onClose={() =>
                      handleCloseDeallocationModal(item.id, item.name)
                    }
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <DeallocationConfirmation
                      type="lo"
                      handleClose={handleCloseDeallocationModal}
                      id={gId}
                      name={item.name}
                      lo={lId}
                      setDeallocationBoolean={setDeallocationBoolean}
                    />
                  </Modal>
                ) : (
                  <div
                    onClick={() => {
                      setDeallocationBoolean(true);
                      setGid(item.id);
                      setLid(item.lo);
                    }}
                  >
                    {loName(item.lo)}
                  </div>
                )}
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    handleShowModalGtL(true, item.id, item.name);
                  }}
                  className="application_base_btn table_btn_padding"
                  key={"AssignLoBtnInGuestTable_" + params.id}
                  disabled={checkGuestLo ? false : true}
                >
                  Assign LO
                </button>
              </>
            )}
          </span>
        );
      },
    },
    {
      field: "vehicle",
      headerName: "Vehicle",
      width: "150",
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      valueGetter: (params) =>{
        if(params.row.vehicle === null){
          return null
        }else{
          return vehicleName(params.row.vehicle)
        }
      },
      valueFormatter: (params) => {
        if (params.value === null) {
          return "No Vehicle";
        } else {
          return vehicleName(params.value);
        }
      },
      renderCell: (params) => {
        const item = params.row;
        return (
          <span key={"Render_cells_vehicle_" + params.id}>
            {item.vehicle ? (
              <>
                {vehicleDeallocationBoolean ? (
                  <Modal
                    sx={{
                      ".MuiModal-backdrop": {
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                      },
                    }}
                    open={vehicleDeallocationBoolean}
                    onClose={() =>
                      handleCloseVehicleDeallocationModal(item.id, item.name)
                    }
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <DeallocationConfirmation
                      type="vehicle"
                      handleClose={handleCloseVehicleDeallocationModal}
                      id={item.id}
                      name={item.name}
                      vehicle={item.vehicle}
                      setVehicleDeallocationBoolean={
                        setVehicleDeallocationBoolean
                      }
                    />
                  </Modal>
                ) : (
                  <div
                    onClick={() => {
                      setVehicleDeallocationBoolean(true);
                    }}
                  >
                    {vehicleName(item.vehicle)}
                  </div>
                )}
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    handleShowModalGtV(true, item.id, item.name);
                  }}
                  className="application_base_btn table_btn_padding"
                  key={"AssignVehicleInGuestTable_" + params.id}
                  disabled={checkGuestVehicle ? false : true}
                >
                  Assign Vehicle
                </button>
              </>
            )}
          </span>
        );
      },
    },
    {
      field: "hotel",
      headerName: "Hotel",
      width: "150",
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      valueGetter: (params) =>{
        if(params.row.hotel===null){
          return null;
        }else{
          return hotelName(params.row.hotel);
        }
      },
      valueFormatter: (params) => {
        if (params.value === null) {
          return "No Hotel";
        } else {
          return hotelName(params.value);
        }
      },
      renderCell: (params) => {
        const item = params.row;
        return (
          <span key={"Render_cells_hotel_" + params.id}>
            {item.hotel ? (
              <React.Fragment>
                {roomDeallocationBoolean ? (
                  <Modal
                    sx={{
                      ".MuiModal-backdrop": {
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                      },
                    }}
                    open={roomDeallocationBoolean}
                    onClose={() =>
                      handleCloseRoomDeallocationModal(item.hotel)
                    }
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <DeallocationConfirmation
                      type="room"
                      handleClose={handleCloseRoomDeallocationModal}
                      id={gId}
                      name={item.name}
                      hotel={hId}
                      room={rId}
                      setRoomDeallocationBoolean={
                        setRoomDeallicationBoolean
                      }
                    />
                  </Modal>
                ) : (
                  <div
                    onClick={() => {
                      setRoomDeallicationBoolean(true);
                      setHid(item.hotel);
                      setRid(item.room);
                      setGid(item.id);
                    }}
                  >
                    {hotelName(item.hotel)}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <>
                <button
                  onClick={() => {
                    handleShowModalGtH(true, item.id);
                  }}
                  className="application_base_btn table_btn_padding"
                  key={"AssignHotelInGuestTable_" + params.id}
                  disabled={checkGuestHotel ? false : true}
                >
                  Assign Hotel
                </button>
              </>
            )}
          </span>
        );
      },
    },
    {
      field: "travelItenary",
      headerName: "Travel Itenary",
      width: "150",
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      disableExport: true,
      renderCell: (param) => {
        return (
          <>
            <button
              className="application_base_btn table_btn_padding"
              onClick={() => {
                handleTravelModal(param.row);
              }}
              disabled={checkGuestTravel ? false : true}
              key={"Render_cells_travel_itenary_" + param.id}
            >
              Travel Itenary
            </button>
          </>
        );
      },
    },
    {
      field: "event_id",
      headerName: "NLC ID",
      editable: false,
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      width: 200,
    },
    {
      field: "check_in[0]",
      headerName: "Expected CheckIn",
      headerClassName: "prime_table_header_tab",
      headerAlign: "center",
      width: "150",
      valueGetter: (params) => {
        return params.row.check_in[0];
      },
      valueFormatter: (params) => {
        // console.log( "Check In Expected : ",params.value);
        let item = params.value;
        return googleDateFormat(
          new Date(item._seconds * 1000 + item._nanoseconds / 1000000)
        );
      },
      renderCell: (params) => {
        const item = params.row;
        return (
          <span
            onClick={() => {
              setOpenTimings(true);
              setGuestId(params.id);
              setCheckInCheckOutType("CheckIn");
            }}
            key={"Render_cells_check_in[0]_" + params.id}
          >
            {item.check_in.length >= 1 && item.check_in[0]
              ? dateToFormatedDate(
                  new Date(
                    item.check_in[0]._seconds * 1000 +
                      item.check_in[0]._nanoseconds / 1000000
                  )
                )
              : "Not checked in"}
          </span>
        );
      },
    },
    {
      field: "check_in[1]",
      headerAlign: "center",
      headerName: "Actual CheckIn",
      width: "150",
      editable: false,
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) => {
        return params.row.check_in[1];
      },
      valueFormatter: (params) => {
        let item = params.value;
        if (item === null) {
          return "NotEntered";
        } else {
          if (item) {
            return googleDateFormat(
              new Date(item._seconds * 1000 + item._nanoseconds / 1000000)
            );
          }
        }
      },
      renderCell: (params) => {
        const item = params.row;
        return (
          <span key={"Render_cells_check_in[1]_" + params.id}>
            {item.check_in.length >= 1 && item.check_in[1]
              ? dateToFormatedDate(
                  new Date(
                    item.check_in[1]._seconds * 1000 +
                      item.check_in[1]._nanoseconds / 1000000
                  )
                )
              : "Not checked in"}
          </span>
        );
      },
    },
    {
      field: "check_out[0]",
      headerAlign: "center",
      headerName: "Expected Checkout",
      width: "150",
      editable: false,
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) => {
        return params.row.check_out[0];
      },
      valueFormatter: (params) => {
        // console.log( "Check Out Expected : ",params.value);
        let item = params.value;
        if (item === null) {
          return "NotEntered";
        } else {
          if (item) {
            return googleDateFormat(
              new Date(item._seconds * 1000 + item._nanoseconds / 1000000)
            );
          }
        }
      },
      renderCell: (params) => {
        const item = params.row;
        return (
          <span
            onClick={() => {
              setOpenTimings(true);
              setGuestId(params.id);
              setCheckInCheckOutType("CheckOut");
            }}
            key={"Render_cells_check_out_[0]_" + params.id}
          >
            {item.check_out.length >= 1 && item.check_out[0]
              ? dateToFormatedDate(
                  new Date(
                    item.check_out[0]._seconds * 1000 +
                      item.check_out[0]._nanoseconds / 1000000
                  )
                )
              : "Not checked out"}
          </span>
        );
      },
    },
    {
      field: "check_out[1]",
      headerName: "Actual Checkout",
      headerAlign: "center",
      width: "150",
      editable: false,
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) => {
        return params.row.check_out[1];
      },
      valueFormatter: (params) => {
        let item = params.value;
        if (item === null) {
          return "NotEntered";
        } else {
          if (item) {
            return googleDateFormat(
              new Date(item._seconds * 1000 + item._nanoseconds / 1000000)
            );
          }
        }
      },
      renderCell: (params) => {
        const item = params.row;
        return (
          <span key={"Render_cells_check_out[1]" + params.id}>
            {item.check_out.length >= 1 && item.check_out[1]
              ? dateToFormatedDate(
                  new Date(
                    item.check_out[1]._seconds * 1000 +
                      item.check_out[1]._nanoseconds / 1000000
                  )
                )
              : "Not checked out"}
          </span>
        );
      },
    },
    {
      field: "contacts[0]",
      headerName: "Contact",
      width: "150",
      editable: true,
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) => {
        return params.row.contacts[0];
      },
      valueFormatter: (params) => {
        return params.value.toString();
      },
      valueSetter: (params) => {
        const temp = params.row;
        temp.contacts[0] = params.value;
        return temp;
      },
      renderCell: (params) => {
        const item = params.row;
        return (
          <span key={"Render_cells_contact[0]" + params.id}>
            {item.contacts[0]}
          </span>
        );
      },
    },
    {
      field: "contacts[1]",
      headerName: "Email Address",
      width: "250",
      editable: true,
      headerAlign: "center",
      headerClassName: "prime_table_header_tab",
      valueGetter: (params) => {
        return params.row.contacts[1];
      },
      valueFormatter: (params) => {
        return params.value === undefined
          ? "not given"
          : params.value.toString();
      },
      valueSetter: (params) => {
        const temp = params.row;
        temp.contacts[1] = params.value;
        return temp;
      },
      renderCell: (params) => {
        const item = params.row;
        return (
          <span key={"Render_cells_contact[1]" + params.id}>
            {typeof item.contacts[1] === "undefined"
              ? "Not Given"
              : item.contacts[1]}
          </span>
        );
      },
    },
    {
      field: "GuestID",
      headerName: "ID Card",
      width: "100",
      editable: false,
      headerAlign: "center",
      filterable: false,
      disableExport: true,
      headerClassName: "prime_table_header_tab",
      renderCell: (params) => {
        return (
          <button
            className="AddGuestbtn"
            onClick={() =>{console.log(params) ;handleOpen(params.row.nlc_id_image, params.id)}}
            key={"Render_cells_PDF_ID" + params.id}
          >
            ID
          </button>
        );
      },
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width : 250,
      editable: true,
      headerAlign: "center",
      filterable: false,
      headerClassName: "prime_table_header_tab",
    },
    {
      field: "GuestDescription",
      headerName: "Description",
      width: "100",
      editable: false,
      headerAlign: "center",
      filterable: false,
      disableExport: true,
      headerClassName: "prime_table_header_tab",
      renderCell: (params) => {
        return (
          <button
            className="AddGuestbtn"
            onClick={() => {
              navigate("/guestDesc/" + params.id);
            }}
            key={"Render_cells_Description" + params.id}
          >
            <MDBIcon fas icon="chevron-right" />
          </button>
        );
      },
    },
  ];

  return (
    <>
      <div className="application_page_search_bar_wrapper">
        <button
          onClick={handleAdanvedSearch}
          className="application_base_btn btn_with_logo application_refresh_icon"
        >
          <MDBIcon fas icon="search" size="md"/>
          <div>Search</div>
        </button>

        <input
          type="text"
          placeholder="Search By Name"
          className="application_guest_search_bar_elements"
          onChange={(e) => {
            if (e.target.value.trim().length !== 0)
              AdancedSearchParams.name = e.target.value;
          }}
          ref={nameInputAdvRef}
        />

        <input
          type="text"
          placeholder="Search By Constituency"
          className="application_guest_search_bar_elements"
          onChange={(e) => {
            if (e.target.value.trim().length !== 0)
              AdancedSearchParams.constituency = e.target.value;
          }}
          ref={constituencyInputAdvRef}
        />

        <input
          type="text"
          placeholder="Search By Designation"
          className="application_guest_search_bar_elements"
          onChange={(e) => {
            if (e.target.value.trim().length !== 0)
              AdancedSearchParams.designation = e.target.value;
          }}
          ref={designationInputAdvRef}
        />

        <input
          type="text"
          placeholder="Search By Party"
          className="application_guest_search_bar_elements"
          onChange={(e) => {
            if (e.target.value.trim().length !== 0)
              AdancedSearchParams.party = e.target.value;
          }}
          ref={partyInputAdvRef}
        />

        <button
          onClick={handleAdancedClear}
          className="application_base_btn btn_with_logo application_refresh_icon"
        >
          <MDBIcon
            fas
            icon="sync-alt"
            size="md"
            style={{
              color: "white",
            }}
          />
          <div>Refresh</div>
        </button>
      </div>

      <Box 
        sx={{
          height: 400, 
          width: "100%",
          '.MuiDataGrid-root .MuiDataGrid-booleanCell[data-value="true"]': {
            color: "green",
          },
          '.MuiDataGrid-root .MuiDataGrid-booleanCell[data-value="false"]': {
            color: "red",
          } 
        }} 
        className="flex-grow-1">

        <DataGrid
          rows={data}
          columns={columns}
          slots={{ toolbar: CustomToolBar }}
          editMode="row"
          onRowModesModelChange={handleRowModesModelChange}
          rowModesModel={editModeModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          getRowClassName={(params) => {
            return params.indexRelativeToCurrentPage % 2 === 0
              ? "mui_grid_table_even_row"
              : "mui_grid_table_odd_row";
          }}
          sx={tableStyle}
          checkboxSelection
        />

        {ids.display && (
          <VehicleAssignGuest
            parentHandler={handleCloseModalGtV}
            changesRet={() => {
              console.log("");
            }}
            setIDs={ids.data}
          />
        )}

        {gtlIds.display && (
          <LoAssignGuest
            parentHandler={handleCloseModalGtL}
            changesRet={() => {
              console.log("");
            }}
            setIDs={gtlIds.data}
          />
        )}

        {gthIds.display && (
          <RoomAssignGuest
            parentHandler={handleCloseModalGtH}
            changesRet={() => {
              console.log("");
            }}
            setIDs={gthIds.data}
          />
        )}

        {travelDisplay && (
          <GuestItenary
            id={guestTravel.id}
            guest={guestTravel.data}
            parentHandler={() => {
              setTIdisplay(false);
            }}
          />
        )}
        {deleteModal && (
          <ConfirmationModal
            parentHandler={handleDeleteRowCloseModal}
            data={deleteData}
            onConfirm={DeleteRowApi}
            type="Guest"
          />
        )}

        {userIDselected.display && (
          <ID
            user={userIDselected.user}
            onClose={() => {
              setUserID({ display: false, user: null });
            }}
          />
        )}
      </Box>

      {photoModal && (
        <ImageModal
          onClose={handleImageModalClose}
          object={selectedImage}
          typeImage={"Guest"}
        />
      )}
      {IdDocModal && (
        <IDDocModal onClose={handleIdDocModalClose} object={selectedDoc} />
      )}
      <Modal open={open} onClose={handleClose}>
        <UploadOrGetIdCardModal nlcIdCard={nlcIdCard} guestId={guestId} handleClose = {handleClose} />
      </Modal>
      <Modal
        open={openTimings}
        onClose={() => {
          setOpenTimings(false);
        }}
      >
        <UpdateCheckInCheckOutModal
          guestId={guestId}
          type={checkInCheckOutType}
        />
      </Modal>
    </>
  );
}
