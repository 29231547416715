import React from "react";
import "./guesttable.css";
import { Box } from "@mui/material";
import axios from "axios";

const DeallocationConfirmation = (
  { 
    type, handleClose, 
    id, name, lo, vehicle, hotel, room,
    setDeallocationBoolean, setVehicleDeallocationBoolean, setRoomDeallocationBoolean
  }) => {
  
  const BASE_URL = process.env["REACT_APP_BASE_URL"]
  
  const deallocate = () => {
    if(type === "lo") {
    axios.post(`${BASE_URL}/los/removeLo`, {
      guestId: id,
      loId: lo,
    },
    {
      headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
    })
    .then((response) => {
      alert("LO deallocated");
    })
    .catch((err) => {
      console.log(err);
    })
    setDeallocationBoolean(false);
    }
    else if(type === "vehicle") {
      axios.post(`${BASE_URL}/vehicles/removeVehicle`, {
        guestId: id,
        vehicleId: vehicle,
      },
      {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
      })
      .then((response) => {
        alert("Vehicle deallocated");
      })
      .catch((err) => {
        console.log(err);
      })
      setVehicleDeallocationBoolean(false);
    }
    else if(type === "room"){
      axios.post(`${BASE_URL}/rooms/removeRoom`,
      {
        hotel_id:hotel,
        guest_id:id,
        room_id:room,
      },
      {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
      })
      .then((response) => {
        alert("Room Deallocated");
      })
      .catch((err)=>{
        console.log(err);
      })
      setRoomDeallocationBoolean(false);
    }
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: "25%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: '#fff',
        borderRadius: '10px',
        p: 4,
        outline: 0,
      }}
    >
      <h5>Are you sure, you want to deallocate?</h5>
      <div className="confirmation-buttons">
        <button className="btn btn-danger" onClick={deallocate}>Yes</button>
        <button className="btn btn-success" onClick={() => handleClose(id, name)}>No, move to reallocation</button>
      </div>
    </Box>
  );
};

export default DeallocationConfirmation;
