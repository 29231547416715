import React, { useEffect, useState, useContext } from "react";
import { Context } from "../../MainPage";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import BulkUpload from "../BulkUpload";
import axios from "axios";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { app } from "../../FirebaseConfig";
import {
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import { statesList } from "./ListOfStates";

import "./AddLos.css";

const AddLos = () => {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const [centredModal, setCentredModal] = useState(true);
  const { childCaller } = useContext(Context);

  // Single LO attributes
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [file, setFile] = useState("");
  const [uploadLoader, setUploadLoader] = useState(false);

  const [InputErr, setInputErr] = useState("");
  const [singleUpload, setSingleUpload] = useState(true);
  const navigate = useNavigate();

  const [uploadLoImgComplete, setUploadLoImgComplete] = useState(false);

  const toggleShow = () => {
    setCentredModal(!centredModal);
  };

  useEffect(() => {
    if (!centredModal) {
      navigate(-1);
    }
  });

  const uploadTicketDoc = () => {
    if (file !== "") {
      setUploadLoader(true);
      let storage = getStorage(app);
      const ticketMetaData = {
        contentType: file.type,
      };

      // Used For Loop because map does not work on FileList type
      const travelIdDocRef = ref(storage, "LOs/" + name + file.name);
      const uploadTravelIdDocTask = uploadBytesResumable(
        travelIdDocRef,
        file,
        ticketMetaData
      );
      uploadTravelIdDocTask.on(
        "state_changed",
        (snapshot) => {
          console.log("Uploading Identification State...");
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTravelIdDocTask.snapshot.ref).then(
            (downloadURL) => {
              setUploadLoader(false);
              setFile(downloadURL);
            }
          );
        }
      );
      setUploadLoImgComplete(true);
    } else {
      alert("Please choose a file before uploading");
    }
  };

  const onAdd = (e) => {
    e.preventDefault();
    if (!name || !contact || !email) {
      setInputErr("Some required fields are empty");
      return;
    } else if (contact.toString().length !== 10) {
      setInputErr("Invalid contact information");
      return;
    } else {
      axios
        .post(
          `${BASE_URL}/los/addLo`,
          {
            name: name,
            contacts: ["+91" + contact, email],
            image: file,
          },
          {
            headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
          }
        )
        .then((res) => {
          alert("LO Added Successfully");
          childCaller("Los");
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setInputErr("");
  };

  let userCheck;
  let check;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    check =
      [
        ("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) ||
          ("LoAdmin" in userCheck && userCheck.LoAdmin === true),
      ] &&
      name !== "" &&
        email !== "" &&
        contact !== "+91" && contact !== "" &&
        file !== undefined
        ? true
        : false;
  } catch (error) {
    navigate("/");
  }
  
  // console.log(check);

  return (
    <>
      <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add Los</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody style={{ height: "70vh", overflowY: "auto" }}>
              {/* <form onSubmit={onAdd}> */}
                <div className="d-flex">
                  <div
                    className={`w-50 text-center p-2`}
                    style={{
                      backgroundColor: `${singleUpload ? "#3555A1" : ""}`,
                    }}
                  >
                    <button
                      className="text-center border-0 bg-transparent"
                      onClick={() => setSingleUpload(true)}
                    >
                      <h4
                        className="m-0"
                        style={{ color: `${singleUpload ? "white" : ""}` }}
                      >
                        Single Upload
                      </h4>
                    </button>
                  </div>
                  <div
                    className="w-50 text-center p-2"
                    style={{
                      backgroundColor: `${!singleUpload ? "#3555A1" : ""}`,
                    }}
                  >
                    <button
                      className="text-center border-0 bg-transparent"
                      onClick={() => setSingleUpload(false)}
                    >
                      <h4
                        className="m-0"
                        style={{ color: `${!singleUpload ? "white" : ""}` }}
                      >
                        Bulk Upload
                      </h4>
                    </button>
                  </div>
                </div>
                {singleUpload ? (
                  <div>
                    <div
                      className="form-helper pt-2 pb-2"
                      style={{ color: "red", marginBottom: "4px" }}
                    >
                      {InputErr}
                    </div>

                    <div className="row gx-2">
                      <div className="col col-6">
                        <MDBInput
                          wrapperClass="mb-4"
                          label="Name"
                          className="lo-name"
                          id="formControlLg"
                          type="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          size="lg"
                          required
                        />
                      </div>
                      <div className="col col-6">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={statesList.map((state) => {
                            return state;
                          })}
                          value={state}
                          onChange={(e, newValue) => setState(newValue)}
                          sx={{ width: 550 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="States"
                              size="small"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row gx-2">
                      <div className="col col-6">
                        <MDBInput
                          wrapperClass="mb-4"
                          label="Email"
                          id="formControlLg"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          size="lg"
                          required
                        />
                      </div>
                      <div className="col col-6" styles={{display:"flex"}}>
                      <div className="row g-0">
                        <div className="col col-2">
                        <MDBInput
                      styles={{maxWidth:"40px"}}
                          wrapperClass="mb-2"
                          value="+91"
                          size="lg"
                          readOnly
                        />
                        </div>
                        <div className="col col-10">
                        <MDBInput
                          wrapperClass="mb-4"
                          label="Contact"
                          id="formControlLg"
                          type="text"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                          size="lg"
                          required
                        />
                        </div>
                      </div>
                      
                        
                      </div>
                    </div>
                    <div className="row gx-2">
                      <div>
                        <label className="label">Image</label>

                        <div className="upload-div">
                          <input
                            type="file"
                            className="form-control uploadFileSize select-file-input"
                            onChange={(e) => {
                              setFile(e.target.files[0]);
                            }}
                            required
                          />
                          {uploadLoader ? (
                            <div className="loader-div">
                              <CircularProgress className="loader" />
                            </div>
                          ) : (
                            <>
                              {uploadLoImgComplete ? (
                                <i class="far fa-circle-check"></i>
                              ) : (
                                <button
                                  className="uploadBtn"
                                  onClick={uploadTicketDoc}
                                >
                                  <i
                                    className="fa fa-upload"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  <div class="buttonPlace">
                    <button
                    
                className="application_base_btn max_media_width Addbtn"
                disabled={check ? false : true}
                onClick={onAdd}
              >
                Add
              </button>
              </div>
                  </div>
                  
                ) : (
                  <BulkUpload api={"los"} onClose={toggleShow}/>
                )}
              {/* </form> */}
            </MDBModalBody>
            <MDBModalFooter>
              
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default AddLos;
