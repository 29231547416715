const tableStyle = {
  border: 2,
  borderColor: "#22222277",
  "& .MuiDataGrid-cell": {
    textAlign: "center",
    justifyContent: "center",
    border: "solid thin",
    borderBottom: "none",
    borderTop: "none",
    borderLeft: "none",
    borderColor: "##2c679d4a",
  },
  ".MuiTablePagination-displayedRows": {
    "margin-top": "1em",
    "margin-bottom": "1em",
  },
  ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
    "margin-top": "1em",
    "margin-bottom": "1em",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "var(--application-base-theme-table-row-hover-color)",
  },
};

const mediaGuestsStyle = {
  ...tableStyle,
  "& .super-app-theme--header": {
    backgroundColor: "var(--application-base-theme-primary-color)",
    color: "#fff",
  },
};

const mediaStatesStyle = {
  ...mediaGuestsStyle,
  "& .MuiDataGrid-cell": {
    textAlign: "left",
  },
};

const loAssignStyle = {
  width: "100%",
  height: "60vh",
  padding: "0",
  paddingTop: "5px",
  "& .super-app-theme--closed": {
    backgroundColor: "#323232a1",
    color: "#1a3e72",
    fontWeight: "600",
  },
  "& .super-app-theme--default": {
    backgroundColor: "#f8f8f8",
    color: "#222",
    fontWeight: "600",
  },
  "& .super-app-theme--crowded": {
    backgroundColor: "#ae383882",
    color: "#222",
    fontWeight: "600",
  },
  "& .super-app-theme--free": {
    backgroundColor: "#47f16b47",
    color: "#222",
    fontWeight: "600",
  },
};

const vehicleAssignStyle = {
  width: "100%",
  height: "70vh",
  padding: "0",
  paddingTop: "5px",
  "& .super-app-theme--default": {
    backgroundColor: "#f8f8f8",
    color: "#222",
    fontWeight: "600",
  },
  "& .super-app-theme--closed": {
    backgroundColor: "#06060647",
    color: "#222",
    fontWeight: "600",
  },
};

const roomsLoaderStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
};

const tabStyle = {
  backgroundColor : "var(--application-base-theme-secondary-color)"
}

const tabIndicatorStyle = {
  backgroundColor : "var(--application-base-theme-black-color)"
}

export {
  tableStyle,
  mediaGuestsStyle,
  mediaStatesStyle,
  loAssignStyle,
  vehicleAssignStyle,
  roomsLoaderStyle,
  tabStyle,
  tabIndicatorStyle,
};
