import React, { useEffect, useState } from "react";
// import Sidebar from '../../Components/Sidebar'
import "../Dashboard.css";
import { app } from "../../FirebaseConfig";
import { Outlet, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
// import RightSidebar from '../../Components/RightSidebar';
import { CircularProgress } from "@mui/material";
import VehicleSemiDesc from "./VehicleSemiDesc";
import { useContext } from "react";
import { Context } from "../../MainPage";
import { MDBIcon, MDBCol } from "mdb-react-ui-kit";
import VehicleMui from "./VehicleMui";
const Vehicle = () => {
  const auth = getAuth(app);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  const { vehicleapi, childCaller } = useContext(Context);
  const [Vehicle, setVehicle] = useState(vehicleapi);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {

    if(user){
      if (user) {
        user
          .getIdTokenResult()
          .then(async (e) => {
            await sessionStorage.setItem("Auth_Token", JSON.stringify(e));
            await sessionStorage.setItem("User_Permission", JSON.stringify(e.claims));
          })
          .catch((e) => console.log(e));
      }
    }

    let uuid = sessionStorage.getItem("UUID");
    if(uuid === null){
      let token = JSON.parse(sessionStorage.getItem("Auth_Token"));
      if(token !== null){
        uuid = token.claims.user_id;
      }
    }
    
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) navigate("/");
    if (error) alert(error);
    setVehicle(vehicleapi);
  }, [user, loading, navigate, vehicleapi]);

  // Search functionality
  const handleAdanvedSearch = (e) => {
    e.preventDefault()
    if (searchTerm.trim().length === 0) {
      setVehicle(vehicleapi);
    } else {
      let tempList = vehicleapi;
      let filteration = tempList.filter((e) => {
        return (
          e.name.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          e.no.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          e.driver_name.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          e.driver_contacts[0].trim().toLowerCase().includes(searchTerm.trim().toLowerCase())
        );
      });
      if (filteration.length === 0) {
        alert("No Rows Found");
      } else {
        setVehicle(filteration);
      }
    }
  };

  const handleAdancedClear = () => {
    setSearchTerm("");
    childCaller("Vehicles");
    setVehicle(vehicleapi);
  };

  const onAdd = () => {
    const urlArr = window.location.pathname.split("/");
    const category = urlArr[urlArr.length - 1];
    navigate(`add_${category}`);
  };

  let userCheck;
  let check;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    check = (("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) || ("VehicleAdmin" in userCheck && userCheck.VehicleAdmin === true))? true : false;
  } catch (error) {
    navigate("/");
  }
  return (
    <>
      <Outlet />
      {Vehicle.length === 0 ? (
        <div style={{ position: "absolute" ,top: "50%", left: "50%" }}>
        <CircularProgress />
        </div>
      ) : (
        <>
          <div
            style={{
              flex: "1 1 auto",
              display: "flex",
              flexFlow: "column",
              height: "100vh",
              overflowY: "auto",
            }}
          >
            <div className="container-fluid">
              <div className="w-100 ">
                <div className="d-flex justify-content-between">
                  <h3
                    className="fw-bold my-3 page_header_title_h3"
                  >
                    Vehicles
                  </h3>
                  <button
                    className="mx-2"
                    style={{
                      border: "none",
                      background: "white",
                      color: "#222222",
                    }}
                    onClick={onAdd}
                    disabled={check? false: true}
                  >
                    <i class="fas fa-plus-circle fa-2x"></i>
                  </button>
                </div>
                <VehicleSemiDesc vehicleArray={Vehicle} />
              </div>
            </div>
            <form onSubmit={handleAdanvedSearch}>
            <div className="application_page_search_bar_wrapper ms-3 pe-4">
        <button
        type="submit"
          onClick={handleAdanvedSearch}
          className="application_base_btn btn_with_logo application_refresh_icon"
        >
          <MDBIcon fas icon="search" size="md" />
          <div>Search</div>
        </button>

        <input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          type="text"
          className="application_page_search_bar"
          placeholder="Search"
        />

        <button
        type="button"
          onClick={handleAdancedClear}
          className="application_base_btn btn_with_logo application_refresh_icon"
        >
          <MDBIcon
            fas
            icon="sync-alt"
            size="md"
            style={{
              color: "white",
            }}
          />
          <div>Refresh</div>
        </button>
      </div>
      </form>
            <div style={{ height: "100%" }}>
              <div style={{ height: "calc(100%)", overflowY: "scroll", display: "flex" }}>
                <div className="ms-3 me-3 d-flex flex-grow-1 flex-column">
                  <VehicleMui vehicles={Vehicle} setVehicle={setVehicle} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* </div> */}
    </>
  );
};

export default Vehicle;
