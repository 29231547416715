import axios from "axios";
import { React, useState, useRef } from "react";
import { CSVDownload, CSVLink} from "react-csv";
import JSONtoCSV from "react-json-to-csv";
const papaParser = require("papaparse");
const BulkUpload = ({ api, onClose }) => {
  var commonConfig = { delimiter: "," };

  const BASE_URL = process.env["REACT_APP_BASE_URL"];
  const [fileCsv, setFileCsv] = useState();
  const [filename, setFilename] = useState("");
  const [errorCsv, setErrorCsv] = useState(false);
  const [errorData, setErrorData] = useState();
  
  const [success, setSuc] = useState(false);
  const [detail,setdetials] = useState([]);
  const succRef = useRef(null);

  const changeHandler = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    papaParser.parse(file, {
      ...commonConfig,
      header: true,
      complete: (result) => {
        result.data.pop();
        setFileCsv(result.data);
      }
    })
  };

  const handleSubmission = () => {  
      // TODO : Localhost To Deployed

      axios
        .post(`${BASE_URL}/${api}/bulkUpload`, 
        {
          data: fileCsv
        }
        ,{
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        })
        .then((response) => {
          if(response.status === 200){
            if(typeof response.data.detail !== "undefined"){

              setdetials(response.data.detial)
              succRef.current.click();
            }
          }
          if(Object.keys(response.data).length === 0){
            setErrorCsv(false);
          }else{
            setErrorData(response.data);
            setErrorCsv(true);
          }
          onClose();
          alert("Bulk Upload Successfull");
        })
        .catch((error) => {
          console.log(error.response);
          onClose();
        });
  };
  let csvData = "";

  if (api === "guests") {

    //TO be used

    csvData = [
      [
        "name",
        "email",
        "contact_number_1",
        "contact_number_2",
        "contact_number_3",
        "expected_check_in(YYYY-MM-DDTHH:MM:SS)",
        "expected_check_out(YYYY-MM_DDTHH:MM:SS)",
        "constituency",
        "district",
        "state",
        "category",
        "designation",
        "party",
      ],
    ];

    
  } else if (api === "los") {

    //To be used

    csvData = [
      [ 
        "name",
        "contact_no_1",
        "contacts_no_2",
        "email",
        "state"
      ],
    ];


    // we are using 
    // csvData = [
    //   [ 
    //     "name",
    //     "contacts",
    //     "state",
    //   ],
    // ];
  } else if (api === "vehicles") {
    csvData = [
      [
        "vehicle_number",
        "vehicle_name",
        "driver_name",
        "email",
        "driver_contact1",
        "driver_contact2",
        "car_type",
        "vendor",
      ],
    ];
  }

  return (
    <div className=" ">
      <div className="">
        <div className=" ">
          <p>CSV for input format</p>
          <CSVLink
            filename={api + ".csv"}
            data={csvData}
            style={{ backgroundColor: "#3555A1", width: "100%" }}
            className=" buttonWidth btn btn-outline-info d-flex  justify-content-center align-items-center "
          >
            <span className="d-flex justify-content-center align-items-center  input-group-text bg-transparent   border border-0  ">
              <i className="fa fa-download text-white" aria-hidden="true"></i>
            </span>
          </CSVLink>
        </div>
        <div className=" ">
          <p className=" my-4">Upload CSV</p>
          <div
            className=" p-5 d-flex border border-2 justify-content-center align-items-center m-auto"
            style={{ width: "290px", height: "140px" }}
          >
            <div
              className=" form-group d-flex justify-content-center align-items-center"
              style={{
                width: "270px",
                height: "120px",
                borderStyle: "dashed",
                borderColor: "#A9A9A9",
                backgroundColor: "#E0E0E0",
              }}
            >
              <input
                type="file"
                name="file"
                class="form-control m-2"
                style={{ width: "250px" }}
                onChange={changeHandler}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className=" my-4">Upload Data </p>
        <button
          style={{ backgroundColor: "#3555A1", width: "100%" }}
          className=" buttonWidth btn btn-outline-info d-flex  justify-content-center align-items-center "
          onClick={handleSubmission}
        >
          <span className="d-flex justify-content-center align-items-center  input-group-text bg-transparent   border border-0  ">
            <i class="fa fa-upload text-white" aria-hidden="true"></i>
          </span>
        </button>
      </div>

      {errorCsv && <CSVDownload data={errorData} target="_blank"/>}
      {success && 
      <JSONtoCSV data={detail} filename="return.csv" ref={succRef}></JSONtoCSV>}

    </div>
    
  );
};

export default BulkUpload;