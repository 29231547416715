import React, { useState, useContext, useEffect } from "react";
import "../Dashboard.css";
import { app } from "../../FirebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { MDBBtn } from "mdb-react-ui-kit";
import Rating from "@mui/material/Rating";
import { Box, CircularProgress } from "@mui/material";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF,GridRowModes } from "@mui/x-data-grid";
import CustomToolBar from "../../Components/MuiToolBar/CustomToolBar";
import { Context } from "../../MainPage";
import RoomAssignGuest from "../../Components/UpdatesComponents/RoomAssignGuest";
import Editicon from "../../Assets/edit.svg";
import Doneicon from "../../Assets/done.svg";
import axios from "axios";

import "./HotelsDesc.css";
import { tableStyle } from "../../Static/Styles";

const HotelsDesc = ({
  hotelName,
  rating,
  address,
  category,
  contact,
  email,
  assignedGuests,
  setHotelDescDisplay,
}) => {
  const BASE_URL = process.env["REACT_APP_BASE_URL"];

  const auth = getAuth(app);
  let navigate = useNavigate();
  let location = useLocation();
  let hotelState = location.state;
  const [user, loading, error] = useAuthState(auth);
  const [rooms, setRooms] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [idCon, setCon] = useState({
    hotelId: hotelState.id,
    guestId: null,
    roomId: null,
  });
  const [guestId, setGuestId] = useState("");
  const [editStates, setEditStates] = useState([]);
  const [unlockLoader, setUnlockLoader] = useState(false);
  const [modalData, setModalData] = useState({
    hotelId: null,
    roomId: null,
    guestId: null,
  });

  // Set initial edit state for each row to false
  useEffect(() => {
    setEditStates(rooms.map(() => false));
  }, [rooms]);

  // Toggle edit state for a specific row
  const toggleEditState = (index) => {
    const newEditStates = [...editStates];
    newEditStates[index] = !newEditStates[index];
    setEditStates(newEditStates);
  };

  const { guestapi, childCaller } = useContext(Context);
  const [guests, setGuests] = useState(guestapi);
  const [roomsLoader,setRoomsLoader] = useState(false)

  const [editBoolean, setEditBoolean] = useState(false);
  const [roomId, setRoomId] = useState("");

  const handleDone = (params) => {
    setEditBoolean(false);
    axios.post(`${BASE_URL}/rooms/updateSoloRoom`, {
      hotelID: hotelState.id,
      roomID: params.id,
      data: {
        no: params.row.number,
        occupancy_size: params.row.occupancy,
        type: params.row.type,
      },
    }, {
      headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") }
    })
    .then((response) => {
      alert("Room Updated Successfully");
    })
    .catch((err) => {
      alert("Room could not be Updated");
    });
  }

  const handleShowModal = (e) => {
    setModalData({
      hotelId: hotelState.id,
      roomId: e.room_id,
      guestId: null,
    });
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setGuestId("");
    setEditStates(rooms.map(() => false));
  };

  // Get all rooms of this hotel
  const getAllRooms = () => {
    setRoomsLoader(true)
    axios
      .get(`${BASE_URL}/rooms/allRooms/${hotelState.id}`, {
        headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
      })
      .then((res) => {
        setRoomsLoader(false)
        setRooms(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const unlockLockedRooms = (roomId) => {
    let roomsArr = [];
    rooms?.map((room) => {
      if (room.availability_status === "Locked") {
        roomsArr.push(room.id);
      }
    });

    axios
      .post(
        `${BASE_URL}/rooms/unlockRooms/${hotelState.id}`,
        {
          rooms_ids: roomsArr,
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((response) => {
        console.log(response.data);
        childCaller("Hotels");
        alert("Rooms Unlocked");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const unlockSingleRoom = (room_id) => {
    axios
      .post(
        `${BASE_URL}/hotels/releaseLockedRoom`,
        {
          hotelId: hotelState.id,
          id: room_id,
          inventory: hotelState.inventory,
        },
        {
          headers: { FirebaseToken: sessionStorage.getItem("Auth_Token") },
        }
      )
      .then((response) => {
        console.log(response.data);
        childCaller("Hotels");
        alert("Room unlocked successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GoBackToHotel = () => {
    navigate("/hotels", { state: "Hotels" });
  };

  const nameStyle = {
    color: "var(--application-base-theme-black-color)",
    marginTop: "5px",
  };
  const infoStyle = {
    color: "var(--application-base-theme-black-color)",
    marginTop: "-20px",
  };
  const infoStyle2 = {
    color: "var(--application-base-theme-black-color)",
    fontWeight : "400",
  };

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) navigate("/");
    if (error) alert(error);
    getAllRooms();
  }, [user, loading, navigate]);

  let check;
  let userCheck;
  try {
    userCheck = JSON.parse(sessionStorage.getItem("User_Permission"));
    check = (("SuperAdmin" in userCheck && userCheck.SuperAdmin === true) || ("HotelAdmin" in userCheck && userCheck.HotelAdmin === true))? true : false;
  } catch (error) {
    navigate("/")
  }

  return (
    <div
      style={{
        flex: "1 1 auto",
        display: "flex",
        flexFlow: "column",
        height: "100vh",
        overflowY: "hidden",
      }}
    >
      {/* <NavBar register={false} doco_logo={false} ridex={true} ridex_logo={true} /> */}
      <div style={{ height: "100%", marginTop: "10px" }}>
        <div style={{ height: "calc(100%)", overflowY: "scroll" }}>
          <div className="d-flex px-2">
            <div className="px-2 w-100">
              <div className="d-flex justify-content-between py-2">
                <h3 style={nameStyle}>
                  <b>{hotelState.name}</b>
                </h3>
                <br />
                <Rating name="read-only" value={hotelState.rating} readOnly />
              </div>
              <div className="d-flex justify-content-between py-2">
                <h4 style={infoStyle}>
                  {hotelState.address}
                </h4>
                <h4 style={infoStyle}>
                  <b>Category {hotelState.category}</b>
                </h4>
              </div>
            </div>
          </div>

          <div className="mx-3">
            <hr style={{ margin: 2 }} />
            <div className="row" style={{ justifyContent: "space-between" }}>
              <div className="col-md-6">
                <h5 style={nameStyle}>Contact Information</h5>
                <h7 style={infoStyle2}>
                  {hotelState.contacts[0]} | {hotelState.contacts[1]}
                </h7>
              </div>
              <div className="col-md-6">
                <h5 style={nameStyle}>Inventory</h5>
                <h7 style={infoStyle2}>
                  {hotelState.inventory[0] +
                    hotelState.inventory[1] +
                    hotelState.inventory[2] +
                    hotelState.inventory[3]}{" "}
                  Total | {hotelState.inventory[0]} Available |{" "}
                  {hotelState.inventory[1]} Reserved | {hotelState.inventory[2]}{" "}
                  Occupied | {hotelState.inventory[3]} Locked
                </h7>
              </div>
            </div>
            <hr style={{ marginTop: 3 }} />
          </div>

          <div className="mx-3">

            <div className="hotel_desc_button_wrapper" 
            style={{marginBottom:"5px"}}>

              <button
                className="application_base_btn"
                style={{padding:"5px 15px"}}
                onClick={()=>{getAllRooms()}}>
                Refresh
              </button>

              <button
                className="application_base_btn"
                style={{padding:"5px 15px"}}
                onClick={unlockLockedRooms}
                disabled={(hotelState.inventory[3] <= 0) && !check? true : false}
              >
                Open Locked Rooms
              </button>

              <div className="">
                <MDBBtn
                  className="text-white"
                  color="warning"
                  onClick={GoBackToHotel}
                >
                  Go Back
                </MDBBtn>
              </div>
            </div>
            <Box
              sx={{
                height: "65vh",
                ".MuiTablePagination-displayedRows": {
                  "margin-top": "1em",
                  "margin-bottom": "1em",
                },
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                  "margin-top": "1em",
                  "margin-bottom": "1em",
                },
              }}
            >
              {
                roomsLoader ? 
                  <div className="loader-div">
                    <CircularProgress />
                  </div>
                : 
                  <DataGrid
                  editMode="row"
                    columns={[
                      {
                        ...GRID_CHECKBOX_SELECTION_COL_DEF,
                        width: 55,
                        headerClassName: "prime_table_header_tab",
                        headerAlign: "center",
                      },
                      {
                        field: 'edit',
                        headerName: '',
                        headerClassName: "prime_table_header_tab",
                        width: 150,
                        headerAlign: 'center',
                        renderCell: (params) => {

                          return (
                            <>
                              {editBoolean && roomId === params.id ? (
                                <div>
                                <button
                                  style={{
                                    border: "none",
                                    padding: "0",
                                    backgroundColor: "transparent",
                                    margin: "10px",
                                  }}
                                >
                                  <i
                                    className="trashIcon fas fa-times fa-lg"
                                    onClick={() => {
                                      setEditBoolean(false);
                                      params.api.stopRowEditMode({ id: params.id });
                                    }}
                                  ></i>
                                </button>
                                <img
                                  src={Doneicon}
                                  alt=""
                                  onClick={() => {
                                    if (check === true)
                                        handleDone(params);
                                        params.api.stopRowEditMode({ id: params.id });
                                  }}
                                />
                                </div>
                              ) : (
                                <div>
                                <img
                                  src={Editicon}
                                  alt=""
                                  onClick={() => {
                                    setRoomId(params.id);
                                    setEditBoolean(true);
                                    params.api.startRowEditMode({ id: params.id });
                                  }}
                                />
                                </div>
                              )}
                            </>
                          );
                        },
                      },
                      {
                        field: "number",
                        headerName: "Room Number",
                        width: 150,
                        headerAlign: "center",
                        flex: 0.5,
                        headerClassName: "prime_table_header_tab",
                        editable: true,
                      },
                      {
                        field: "type",
                        headerName: "Type",
                        width: 150,
                        flex: 0.5,
                        headerAlign: "center",
                        headerClassName: "prime_table_header_tab",
                        editable: true,
                      },
                      {
                        field: "occupancy",
                        headerName: "Occupancy",
                        width: 150,
                        flex: 0.5,
                        headerAlign: "center",
                        headerClassName: "prime_table_header_tab",
                        editable: true,
                      },
                      {
                        field: "status",
                        headerName: "Status",
                        width: 150,
                        flex: 0.5,
                        headerAlign: "center",
                        headerClassName: "prime_table_header_tab",
                        editable: false,
                      },
                      {
                        field: "guest",
                        headerName: "Guest",
                        headerClassName: "prime_table_header_tab",
                        width: 150,
                        flex: 0.5,
                        editable: false,
                        headerAlign: "center",
                        valueGetter: (cellValues) =>{
                          if(cellValues.row.guest !== null){
                            return guests?.map((guest) =>{
                              if(guest.id === cellValues.row.guest){
                                return guest.name;
                              }
                            })
                          }
                        },
                        renderCell: (cellValues) => {
                          if (cellValues.row.guest !== null) {
                            return guests?.map((guest) => {
                              if (guest.id === cellValues.row.guest) {
                                return <div className="me-3">{guest.name}</div>;
                              }
                            });
                          }
                          return (
                            <button
                              className="application_base_btn table_btn_padding"
                              onClick={() => {
                                handleShowModal(cellValues.row);
                              }}
                            >
                              Assign Guest
                            </button>
                          );
                        },
                      },
                      {
                        field: "unlock",
                        headerName: "Unlock Rooms",
                        width: 150,
                        headerAlign: "center",
                        headerClassName: "prime_table_header_tab",
                        flex: 0.5,
                        editable: false,
                        disableExport: true,
                        renderCell: (cellValues) => {
                          if (cellValues.row.status === "Locked") {
                            return (
                              <>
                                {unlockLoader ? (
                                  <CircularProgress />
                                ) : (
                                  <div className="d-flex justify-content-center">
                                    <button
                                      style={{
                                        border: "none",
                                        borderRadius: "5px",
                                        backgroundColor: "rgb(66, 83, 151)",
                                        color: "#fff",
                                        padding: "7px",
                                      }}
                                      onClick={() =>
                                        unlockSingleRoom(cellValues.row.room_id)
                                      }
                                    >
                                      Unlock Room
                                    </button>
                                  </div>
                                )}
                              </>
                            );
                          } else {
                            return null;
                          }
                        },
                      },
                    ]}
                    rows={rooms?.map((room) => {
                      return {
                        room_id: room.id,
                        number: room.no,
                        type: room.type,
                        occupancy: room.occupancy_size,
                        status: room.availability_status,
                        guest: room.guest,
                      };
                    })}
                    getRowClassName={(params) => {
                      return params.indexRelativeToCurrentPage % 2 === 0
                        ? "mui_grid_table_even_row"
                        : "mui_grid_table_odd_row";
                    }}
                    getRowId={(row) => row.room_id}
                    slots={{ toolbar: CustomToolBar }}
                    sx={tableStyle}
                  />

              }
            </Box>
          </div>
        </div>
      </div>
      {showModal && (
        <RoomAssignGuest
          key={"RoomAssignModalViewGhost"}
          parentHandler={setShowModal}
          changesRet={setModalData}
          setIDs={modalData}
        />
      )}
    </div>
  );
};

export default HotelsDesc;
